import React, { FunctionComponent, useState } from 'react';
import { DataTableGroup, DataTableGroupDefinition, exportDataTablesToCSV } from '../../common/DataTableGroup';
import { Box, SpaceBetween, Container, FormField, Spinner, Select, SelectProps, Flashbar, NonCancelableCustomEvent, Button, Grid } from '@amzn/awsui-components-react-v3';
import { LimestoneExperiment } from '../../interfaces/LimestoneExperiment';
import { downloadFile, FileFormat } from '../../utils/file-utils';
import _ from 'lodash';

export interface MetricsPageDisplayProps {
    id: string;
    experiment: LimestoneExperiment;
    tableGroups: DataTableGroupDefinition[];
    tableKey: string;
    dataNotReadyWarning: string;
    addMetricElement?: JSX.Element;
    selectedDay: string;
    availableDays: string[];
    showSpinner: boolean;
    onDateChangeFunction: (event: NonCancelableCustomEvent<SelectProps.ChangeDetail>) => any;
}

export const MetricsPageDisplay: FunctionComponent<MetricsPageDisplayProps> = (props: MetricsPageDisplayProps) => {
    const [buttonLoadingState, updateButtonLoadingState] = useState(false);

    const tables = props.tableGroups.map((dataTableGroup: DataTableGroupDefinition) => (
        <div style={{ padding: 5 }} key={dataTableGroup.groupName ? dataTableGroup.groupName : props.tableKey}>
            <DataTableGroup
                dataTableGroupDefinition={dataTableGroup}
                expandableSection={true}
                firstColumnHeader={true}
            />
        </div>
    ));

    const downloadMetrics = () => {
        updateButtonLoadingState(true);

        const fileRows = exportDataTablesToCSV(props.tableGroups);
        downloadFile(fileRows, `${props.experiment.experimentId}-${props.id}`, FileFormat.CSV);

        updateButtonLoadingState(false);
    };

    let headerContent: JSX.Element;
    if (props.showSpinner) {
        headerContent = (<Spinner data-testid={'metrics-loading-spinner'} size='large' />);
    } else if (props.availableDays.length === 0) {
        headerContent = (
            <Flashbar data-testid={`${props.id}-no-dates-flashbar`} items={[{
                header: props.dataNotReadyWarning,
                content: 'Please select a different date. Metrics are generated every Sunday after a request is uploaded.',
                type: 'warning'
            }]} />
        );
    } else {
        headerContent = (
            <>
                <Container>
                    <SpaceBetween size="xl">
                        <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                            <Box variant="h4" padding="n">Select a date from the drop-down</Box>
                            {!_.isEmpty(props.tableGroups) &&
                                <Button
                                    data-testid={`${props.id}-download-button`}
                                    loading={buttonLoadingState}
                                    onClick={downloadMetrics}
                                    variant='primary'
                                    iconName='download'
                                >
                                    {`Download ${props.tableKey}`}
                                </Button>
                            }
                        </Grid>

                        <FormField stretch={false}>
                            <Select
                                data-testid={'date-change-dropdown'}
                                options={props.availableDays.map((day) => { return { value: day };})}
                                selectedOption={{ value: props.selectedDay }}
                                onChange={props.onDateChangeFunction}
                                key='dse-date-change-dropdown'
                            />
                        </FormField>
                    </SpaceBetween>
                </Container>
            </>

        );
    }


    return (
        <Container>
            {props.addMetricElement}
            {headerContent}
            {tables}
        </Container>
    );
};
