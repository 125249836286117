import { EndpointMap } from './endpoint-map';

export const RABL_BASE_URLS: EndpointMap = new EndpointMap({
    beta_na: 'https://dora.corp.amazon.com/baselayer',
    gamma_na: 'https://dora-iad.iad.proxy.amazon.com/baselayer',
    prod_na: 'https://dora-iad.iad.proxy.amazon.com/baselayer',
    beta_eu: 'https://dora.corp.amazon.com/baselayer',
    prod_eu: 'https://dora-iad.iad.proxy.amazon.com/baselayer',
    beta_fe: 'https://dora.corp.amazon.com/baselayer',
    prod_fe: 'https://dora-iad.iad.proxy.amazon.com/baselayer',
    dev_na: 'https://dora.corp.amazon.com/baselayer',
    dev_eu: 'https://dora.corp.amazon.com/baselayer',
    dev_fe: 'https://dora.corp.amazon.com/baselayer',
});

export const EMBED_REGION_MAP = '/embed/map/region/';

export const EMBED_BOUNDARIES_MAP = '/embed/map/boundaries';
export const INCLUDES = 'includes';
export const EXCLUDES = 'excludes';

