export enum UserAccessLevel {
    ADMIN = 'ADMIN',
    CREATOR = 'CREATOR',
    PRIMARY_OWNER = 'PRIMARY_OWNER',
    SECONDARY_OWNER = 'SECONDARY_OWNER',
    OBSERVER = 'OBSERVER',
    APPROVER = 'APPROVER',
    DEFAULT = 'DEFAULT'
}

export enum ActionType {
    READ = 'READ',
    WRITE = 'WRITE',
    SAVE = 'SAVE',
    ELEVATED_READ = 'ELEVATED_READ',
    ADMIN_ACTION = 'ADMIN_ACTION'
}

export type PermissionsMap = {
    [level in UserAccessLevel]: ActionType[];
};