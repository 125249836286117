import React from 'react';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import { Box } from '@amzn/awsui-components-react-v3';

export enum ExperimentStatusType {
    SETUP_IN_PROGRESS = 'SETUP_IN_PROGRESS',
    RUNNING_SIMULATION = 'RUNNING_SIMULATION',
    AWAITING_USER_INPUT = 'AWAITING_USER_INPUT',
    AWAITING_USER_APPROVAL = 'AWAITING_USER_APPROVAL',
    REGION_SETUP_IN_PROGRESS = 'REGION_SETUP_IN_PROGRESS',
    AWAITING_APPROVALS = 'AWAITING_APPROVALS',
    REJECTED = 'REJECTED',
    APPROVED = 'APPROVED',
    VALIDATION_COMPLETE = 'VALIDATION_COMPLETE',
    VALIDATION_IN_PROGRESS = 'VALIDATION_IN_PROGRESS',
    RUNNING = 'RUNNING',
    STARTING_EXPERIMENT = 'STARTING_EXPERIMENT',
    ENDING_EXPERIMENT = 'ENDING_EXPERIMENT',
    ANALYSIS_IN_PROGRESS = 'ANALYSIS_IN_PROGRESS',
    AWAITING_CUSTOMER_DECISION = 'AWAITING_CUSTOMER_DECISION',
    COMPLETE = 'COMPLETE',
}

type StatusTypeMap = {
    [name in ExperimentStatusType]: JSX.Element;
};

export const ExperimentStatus: StatusTypeMap = {
    RUNNING_SIMULATION: <Box variant={'span'} color="text-status-info" fontWeight={'bold'}><HourglassEmptyIcon fontSize='large'/>Running Simulation</Box>,
    AWAITING_APPROVALS: <Box variant={'span'} color="text-status-info" fontWeight={'bold'}><HourglassEmptyIcon fontSize='large'/>Awaiting approvals</Box>,
    AWAITING_USER_INPUT: <Box variant={'span'} color="text-status-info" fontWeight={'bold'}><HourglassEmptyIcon fontSize='large'/>Awaiting User Input</Box>,
    AWAITING_USER_APPROVAL: <Box variant={'span'} color="text-status-info" fontWeight={'bold'}><HourglassEmptyIcon fontSize='large'/>Awaiting User Approval</Box>,
    REGION_SETUP_IN_PROGRESS: <Box variant={'span'} color="text-status-info" fontWeight={'bold'}><DoubleArrowIcon fontSize='large'/> Region Setup In Progress</Box>,
    REJECTED: <Box variant={'span'} color="text-status-error" fontWeight={'bold'}><WarningIcon fontSize='large'/> Rejected</Box>,
    APPROVED: <Box variant={'span'} color="text-status-success" fontWeight={'bold'}><CheckCircleIcon fontSize='large'/> Approved</Box>,
    SETUP_IN_PROGRESS: <Box variant={'span'} color="text-status-info" fontWeight={'bold'}><DoubleArrowIcon fontSize='large'/> Setup In Progress</Box>,
    VALIDATION_COMPLETE: <Box variant={'span'} color="text-status-success" fontWeight={'bold'}><CheckCircleIcon fontSize='large'/> Validation Complete</Box>,
    VALIDATION_IN_PROGRESS: <Box variant={'span'} color="text-status-info" fontWeight={'bold'}><DoubleArrowIcon fontSize='large'/> Validation In Progress</Box>,
    STARTING_EXPERIMENT: <Box variant={'span'} color="text-status-info" fontWeight={'bold'}><DoubleArrowIcon fontSize='large'/> Starting Experiment</Box>,
    ENDING_EXPERIMENT: <Box variant={'span'} color="text-status-info" fontWeight={'bold'}><DoubleArrowIcon fontSize='large'/> Ending Experiment</Box>,
    RUNNING: <Box variant={'span'} color="text-status-success" fontWeight={'bold'}><AccessTimeIcon fontSize='large'/> Running</Box>,
    ANALYSIS_IN_PROGRESS: <Box variant={'span'} color="text-status-info" fontWeight={'bold'}><DoubleArrowIcon fontSize='large'/> Analysis In Progress</Box>,
    AWAITING_CUSTOMER_DECISION: <Box variant={'span'} color="text-status-info" fontWeight={'bold'}><HourglassEmptyIcon fontSize='large'/>Awaiting Customer Decision</Box>,
    COMPLETE: <Box variant={'span'} color="text-status-success" fontWeight={'bold'}><CheckCircleIcon fontSize='large'/> Complete</Box>,
};

/**
 * Statuses in which the experiment selection treatment has been completed.
 */
export const EXPERIMENT_TREATMENT_COMPLETE_STATUSES = [
    ExperimentStatusType.ANALYSIS_IN_PROGRESS,
    ExperimentStatusType.AWAITING_CUSTOMER_DECISION,
    ExperimentStatusType.COMPLETE
];
