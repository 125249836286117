import React from 'react';
import { Icon } from '@amzn/awsui-components-react-v3';
import { ProductSelectionAttribute } from '../../enums/CommonTypes';
import * as FileUtils from '../../utils/file-utils';
import * as StringUtils from '../../utils/array-utils';
import { FileField, FileFieldConfig } from '../fields/FileField';
import { ExperimentAttributeProps } from '../ExperimentAttribute';
import { DisplayMode } from '../../interfaces/FormAttribute';
import { AttributeLabels } from '../../constants/display/string-constants';
import { FileValidationRules } from '../../validation/validation-rules';
import { ExperimentStatusType } from '../../enums/ExperimentStatus';

export enum SelectionFileType {
    EXPERIMENT_SELECTION,
    CUSTOM_EVALUATION_SELECTION
}

export interface ProductSelectionFileProps extends ExperimentAttributeProps {
    selectionFileType: SelectionFileType;
}

export class ProductSelectionFile extends FileField<ProductSelectionFileProps> {
    protected displayConfig: FileFieldConfig;
    protected validationRules: FileValidationRules;

    constructor(props: ProductSelectionFileProps) {
        super(props);

        this.validationRules = {
            required: true,
            allowedOptions: ['text/csv'],
            maxSizeInBytes: 4000000000,
            duplicateCheck: props.selectionFileType === SelectionFileType.EXPERIMENT_SELECTION,
            emptyCheck: props.selectionFileType === SelectionFileType.EXPERIMENT_SELECTION,
        };

        const editable = this.props.experimentStatus === ExperimentStatusType.VALIDATION_COMPLETE || this.props.experimentStatus === ExperimentStatusType.REJECTED;

        this.displayConfig = {
            label: AttributeLabels.OFFERS_FILE,
            editable,
            touched: false,
            value: []
        };

        this.fileConfig = {
            id: 'custom-file',
            custom: true,
            label: props.initialFileName?.length ? props.initialFileName : 'Upload Selection',
            onChange: (event: CustomEvent) => this.onChangeEvent(event, ProductSelectionAttribute.OFFERS_FILE)
        };

        this.summaryDisplay = null;

        this.state = {
            displayValue: '',
            displayMode: props.displayMode ? props.displayMode : DisplayMode.CREATE,
            editInProgress: false,
            validity: false
        };
    }

    componentDidMount = async() => {
        if (this.props.initialValue) {
            this.setValueFromPayload(this.props.initialValue);
        }

        this.forceUpdate();
    }

    setValue = async(newValue: File) => {
        let fileContent = null;
        let uniqueValues = null;

        if (this.props.selectionFileType === SelectionFileType.EXPERIMENT_SELECTION) {
            fileContent = await this.extractContentFromFile(newValue);
            uniqueValues = StringUtils.getUniqueValues(fileContent);
            this.displayConfig.value = uniqueValues;
        } else {
            this.displayConfig.value = newValue;
        }
        const { isValid, errorText } = this.validateFile(newValue, fileContent, this.validationRules);

        const fileName = newValue.name;

        this.displayConfig = {
            ...this.displayConfig,
            touched: true,
            errorText
        };

        this.fileConfig = {
            ...this.fileConfig,
            label: fileName,
            isInvalid: !isValid
        };

        this.summaryDisplay = fileName && isValid ? (
            <div style={{ marginTop: '5px' }}>
                <span className="awsui-util-status-positive">
                    <Icon name="status-positive" /> {`File ${fileName} is now available`}
                    <div style={{ marginTop: '5px' }}>{`Size: ${newValue.size}B`}</div>
                    {uniqueValues && <div>{`ASIN Count: ${uniqueValues.length}`}</div>}
                </span>
            </div>
        ) : null;

        await new Promise((resolve) => this.setState({ displayValue: fileName, validity: isValid }, () => resolve(newValue)));
    }

    setValueFromPayload = async(newContent: string[] | File) => {
        if (newContent instanceof File) {
            this.setValue(newContent);
        } else {
            const fileName = this.getDisplayValue() ? this.getDisplayValue() : 'selection_file.csv';
            const file = new File(newContent.map((line) => line + '\n'), fileName, { type: 'text/csv' });
            this.setValue(file);
        }
    }

    validateFile = (value: File, fileContent: any, validationRules: FileValidationRules) => {
        let isValid: boolean = true;
        const errors: string[] = [];

        if (validationRules) {
            if (validationRules.required && !value) {
                isValid = false;
                errors.push('Field is required');
            }

            if (validationRules.allowedOptions && value && !validationRules.allowedOptions.includes(value.type)) {
                isValid = false;
                errors.push('This is not a valid file type');
            }

            if (validationRules.maxSizeInBytes && value && value.size > validationRules.maxSizeInBytes) {
                isValid = false;
                errors.push('Max File Upload Size is ' + validationRules.maxSizeInBytes / 1000000 + ' MB');
            }

            if (validationRules.duplicateCheck && fileContent) {
                const duplicateValues = StringUtils.getDuplicates(fileContent);
                if (duplicateValues.size > 0) {
                    isValid = false;
                    errors.push(`${duplicateValues.size} duplicate ASIN(s) present in the file`);
                }
            }
        }

        const errorText = errors.length !== 0 ? errors.join(' and ') : undefined;

        return { isValid, errorText };
    }

    extractContentFromFile = async(file: File): Promise<string[]> => {
        const asinsArr = await FileUtils.convertCSVToJSON(file);
        return asinsArr.map((asin: string[]) => asin[0].trim()).filter((asin: string) => asin && asin !== '');
    };

    getPayloadValue = () => this.displayConfig.value;

    renderViewMode = () => <></>;
}
