import React, { FunctionComponent } from 'react';
import { Box, Button, ColumnLayout, Container, Header } from '@amzn/awsui-components-react-v3';
import { ExperimentStep } from '../enums/CommonTypes';

export interface SummarySectionProps {
    header: string;
    handler?: (_event: CustomEvent) => void;
    editEnabled?: boolean;
    columns: 1 | 2 | 3 | 4 | null | undefined;
    items: ExperimentStep;
}

export const SummarySection: FunctionComponent<SummarySectionProps> = (props: SummarySectionProps) => {
    const isEditButtonDisabled = props.editEnabled ? !props.editEnabled : false;

    const displayArray = Object.keys(props.items)
        .filter((key) => props.items[key].displayValue !== '')
        .map((key) => ({ name: props.items[key].displayLabel, value: props.items[key].displayValue }));

    return (
        <Container>
            <Header actions={props.handler && <Button onClick={props.handler} disabled={isEditButtonDisabled}>Edit</Button>}>
                {props.header}
            </Header>
            <ColumnLayout columns={props.columns!} variant='text-grid'>
                {displayArray.map((item: any) => {
                    return item.value && (
                        <div key={item.name}>
                            <Box variant="awsui-key-label"><Box variant="strong"><u>{item.name}</u></Box></Box>
                            <div>{item.value}</div>
                        </div>
                    );
                })}
            </ColumnLayout>
        </Container>
    );
};
