import { Realm } from './api-constants';
import * as UrlUtils from '../utils/url-utils';

const API_MAP_DELIMITER = '_';

export const getStageFromUrl = () => {
    const url: URL = new URL(window.location.href);
    return UrlUtils.parseStageFromUrl(url);
};

export class EndpointMap {
    endpoints: {[name: string]: string};

    constructor(endpoints: {[name: string]: string}) {
        this.endpoints = endpoints;
    }

    getEndpoint = (realm: Realm) => {
        return this.endpoints[getStageFromUrl() + API_MAP_DELIMITER + realm];
    }
}
