import { Tooltip } from '@material-ui/core';
import { Box } from '@amzn/awsui-components-react-v3';
import { InfoOutlined } from '@material-ui/icons';
import React from 'react';

interface LabelWithToolTipProps {
    label: JSX.Element;
    toolTipText?: string;
}

export const LabelWithTooltip = (props: LabelWithToolTipProps) => {
    return props.toolTipText
        ? <Tooltip
            title={
                <Box variant="h5">{props.toolTipText}</Box>}
            arrow={true}>
            <div>
                <span>{props.label}</span>
                <span><InfoOutlined style={{ marginLeft: '5px', marginRight: '5px' }}/></span>
            </div>
        </Tooltip>
        : <>{props.label}</>;
};
