import { URL } from 'url';
import { Realm, Stage } from '../api/api-constants';

export const PROD_LIMESTONE_PORTAL_URL = 'limestone.strx.amazon.dev';

export interface QueryParameters {
    stepId: number;
    experimentId: string;
    experimentIntegerId: number;
    date: string;
    realm: Realm;
    marketplaceId: number;
}

/**
 * Parses the stage from the given url.
 * @param url url object.
 * @returns stage of the experiment portal (Ex. beta, prod).
 */
export const parseStageFromUrl = (url: URL): Stage => {
    const hostname = url.hostname;

    if (hostname === PROD_LIMESTONE_PORTAL_URL) {
        return Stage.PROD;
    }

    const stage = hostname?.split('.')[0];
    switch (stage) {
        case Stage.GAMMA: return Stage.GAMMA;
        case Stage.BETA: return Stage.BETA;
        default: return Stage.DEV;
    }
};

/**
 * Parses the query parameters from the given url.
 * @param url url object. 
 * @returns query params.
 */
export const parseQueryParametersFromUrl = (url: URL): QueryParameters => {
    const urlSearchParams = url.searchParams;

    return {
        stepId: Number(urlSearchParams.get('stepId')),
        experimentId: urlSearchParams.get('experimentId')!,
        experimentIntegerId: Number(urlSearchParams.get('experimentIntegerId')),
        date: urlSearchParams.get('date')!,
        realm: urlSearchParams.get('realm')! as Realm,
        marketplaceId: Number(urlSearchParams.get('marketplaceId')),
    };
};
