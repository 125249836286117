import { TextAreaField, TextAreaFieldConfig } from '../fields/TextAreaField';
import { LemsApiHandler } from '../../api/experiment-service/handler/lems-api-handler';
import ApiHandler from '../../api/experiment-service/handler/lems-api-handler-impl';
import { Realm } from '../../api/api-constants';
import { ExperimentAttributeProps } from '../ExperimentAttribute';
import { DisplayMode } from '../../interfaces/FormAttribute';
import { AttributeLabels } from '../../constants/display/string-constants';

/**
 * Customer decision for an experiment.
 */
export class CustomerDecision extends TextAreaField {
    protected displayConfig: TextAreaFieldConfig;
    private experimentServiceAPI: LemsApiHandler;

    constructor(props: ExperimentAttributeProps) {
        super(props);

        const { initialValue, displayMode } = props;
        const realm = props.realm ? props.realm : Realm.NA;
        this.experimentServiceAPI = new ApiHandler(realm);

        this.validationRules = { required: true, maxLength: 2048 };
        const { isValid, errorText } = this.validate(initialValue, this.validationRules);

        const createViewLabel = 'Please share your decision about this experiment including if the experiment will be launched'
            + ' or not and how the experiment result impact your launch decision. ';
        this.displayConfig = {
            label: AttributeLabels.CUSTOMER_DECISION,
            editable: true,
            rows: 2,
            placeholder: createViewLabel + '2048 characters limit.',
            touched: false,
            hintText: '',
            errorText,
            onChange: (event) => this.onChangeEvent(event as CustomEvent, ''),
            value: props.initialValue
        };

        this.state = {
            displayValue: '',
            displayMode: displayMode ? displayMode : DisplayMode.CREATE,
            validity: isValid,
            editInProgress: false
        };
    }

    componentDidMount() {
        if (this.props.initialValue) {
            this.setValueFromPayload(this.props.initialValue ? this.props.initialValue : ' ');
        }

        this.forceUpdate();
    }

    editSubmitButtonClicked = async(): Promise<boolean> => {
        this.setState({ editInProgress: true });
        const response = await this.experimentServiceAPI.updateCustomerDecision(this.props.experimentId!, this.getPayloadValue())
            .finally(() => { this.setState({ editInProgress: false, displayMode: DisplayMode.VIEW }); });

        return response;
    }
}
