import React, { Component } from 'react';
import { Container, Spinner, Tabs, TabsProps } from '@amzn/awsui-components-react-v3';
import * as UrlUtils from '../../utils/url-utils';
import * as FormUtils from '../../utils/form-utils';
import LemsApiHandlerImpl from '../../api/experiment-service/handler/lems-api-handler-impl';
import { handleErrorResponse } from '../../utils/error-handler-utils';
import * as NOTIFICATION_MESSAGES from '../../constants/display/flashbar-messages';
import { LimestoneExperiment } from '../../interfaces/LimestoneExperiment';
import { PageProps } from '../../interfaces/IProps';
import { PermissionControlledView } from '../../permissions/PermissionControlledView';
import { addUserAccessLevelsFromExperiment, isActionAuthorizedForUser } from '../../utils/auth-utils';
import { transactionalMetricsPage } from '..';
import { ExperimentNavigationBar } from '../ExperimentDetails/ExperimentNavigationBar';
import { ActionType } from '../../enums/PermissionsModel';
import { DownstreamMetricsSection } from './DownstreamMetricsSection';
import { TransactionalMetricsSection } from './TransactionalMetricsSection';
import { CustomEvaluationMetricsSection } from './CustomEvaluationMetricsSection';
import { CustomMetricsSection } from './CustomMetricsSection';

export interface TransactionalMetricsState {
    experiment: LimestoneExperiment;
    showSpinner: boolean;
    activeTabId: string;
}

class TransactionalMetrics extends Component<PageProps, TransactionalMetricsState> {
    public experimentServiceAPI: LemsApiHandlerImpl;

    constructor(props: PageProps) {
        super(props);
        this.state = {
            experiment: FormUtils.createEmptyLimestoneExperiment(),
            showSpinner: false,
            activeTabId: '0',
        };

        this.experimentServiceAPI = new LemsApiHandlerImpl(props.realm);
    }

    componentDidMount = async() => {
        const { experimentId, experimentIntegerId } = UrlUtils.parseQueryParametersFromUrl(new URL(window.location.href));
        this.setState({ showSpinner: true });

        await this.experimentServiceAPI.readExperiment(experimentId, experimentIntegerId)
            .then((experiment: LimestoneExperiment) => {
                this.setState({ experiment });
            })
            .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.readExperiment.FAIL!))
            .finally(() => this.setState({ showSpinner: false }));
    }

    onTabChange = (detail: TabsProps.ChangeDetail) => {
        this.setState({ activeTabId: detail.activeTabId });
    }

    render() {
        const userAccessLevels = addUserAccessLevelsFromExperiment(this.state.experiment.metadata, this.props.userAttributes?.username!, this.props.userAccessLevels);
        const isWriteActionEnabledForUser = isActionAuthorizedForUser(userAccessLevels, ActionType.WRITE, this.props.permissionsMap);
        const actionStripe = (
            <>
                <ExperimentNavigationBar
                    experiment={this.state.experiment}
                    realm={this.props.realm}
                    userAccessLevels={this.props.userAccessLevels}
                    currentPage={transactionalMetricsPage}
                />
            </>
        );

        const tabs: Array<TabsProps.Tab> | null = [
            {
                label: 'Core Transactional Metrics',
                id: '0',
                content: <TransactionalMetricsSection
                    realm={this.props.realm}
                    experiment={this.state.experiment}
                    setNotification={this.props.setNotification!}
                    userAttributes={this.props.userAttributes!}
                />,
            },
            {
                label: 'Downstream Metrics',
                id: '1',
                content: <DownstreamMetricsSection
                    realm={this.props.realm}
                    experiment={this.state.experiment}
                    setNotification={this.props.setNotification!}
                />,
            },
            {
                label: 'Custom Evaluation Requests',
                id: '2',
                content: <CustomEvaluationMetricsSection
                    realm={this.props.realm}
                    experiment={this.state.experiment}
                    setNotification={this.props.setNotification!}
                    userAttributes={this.props.userAttributes!}
                />,
            },
            {
                label: 'Additional Non Core Metrics',
                id: '3',
                content: <CustomMetricsSection
                    realm={this.props.realm}
                    experiment={this.state.experiment}
                    setNotification={this.props.setNotification!}
                    isWriteActionEnabledForUser={isWriteActionEnabledForUser}
                    userAttributes={this.props.userAttributes!}
                />,
            },
        ];

        return (
            <PermissionControlledView
                userAccessLevels={userAccessLevels}
                pagePermissionsMap={this.props.permissionsMap}
            >
                <div style={{ padding: 5 }}>
                    <Container>
                        {this.state.showSpinner ? <Spinner data-testid={'metrics-loading-spinner'} size='large' /> :
                            <>
                                {actionStripe}
                                <Tabs
                                    data-testid='metrics-page-tabs'
                                    tabs={tabs}
                                    activeTabId={this.state.activeTabId.toString()}
                                    onChange={({ detail }) => this.onTabChange(detail)}
                                />
                                <div style={{ margin: '10px 0' }}/>
                            </>
                        }
                    </Container>
                </div>
            </PermissionControlledView>
        );
    }
}

export default TransactionalMetrics;
