import { LimestoneExperiment } from '../interfaces/LimestoneExperiment';

/**
 * Converts Limestone Experiments to table experiment items.
 * @param experiments list of limestone experiment entities.
 * @returns list of table experiment entities.
 */
export const mapExperimentsToExperimentsTableItems = (experiments: LimestoneExperiment[]) => {
    return experiments.map((experiment) => {
        let metadata: { [attribute: string]: string } = {};
        for (const attribute of Object.keys(experiment.metadata)) {
            metadata[attribute] = experiment.metadata[attribute].displayValue;
        }
        return {
            ...experiment,
            ...metadata,
            currentStatusToDisplay: experiment.currentStatus.currentStatus.payloadValue,
        };
    });
};
