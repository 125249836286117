import React, { FunctionComponent } from 'react';
import Typography from '@material-ui/core/Typography';
import { PageSection } from '../../layout/PageSection';
import { MessageBox, MessageType } from '../../common/MessageBox';
import { ExternalLinks } from '../../constants/display/external-links';

const UnauthorizedPage: FunctionComponent = () => (
    <PageSection testId={'unauthorized'}>
        <MessageBox testId={'unauthorizedpage'} type={MessageType.ERROR}>
            <Typography variant='h5'>Hello!</Typography>
            <Typography variant='h6'>You are not Authorized to view this page</Typography>
            <Typography variant='h6'>To get permissions, send us an experiment intake request at {ExternalLinks.EXPERIMENT_INTAKE.href}</Typography>
            <Typography variant='h6'>To know more about our onboarding process, visit {ExternalLinks.GUIDE.href}</Typography>
            <Typography variant='body2'>Error 403</Typography>
        </MessageBox>
    </PageSection>
);

export default UnauthorizedPage;
