import React, { FunctionComponent } from 'react';
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { IProps } from '../interfaces/IProps';

interface Props extends IProps {
  className?: string;
  type: MessageType;
}

export enum MessageType {
    WARNING = 'warning',
    ERROR = 'error'
}

export const useStyles = makeStyles({
    default: {
        padding: '15px'
    },
    error: {
        backgroundColor: '#f2dede',
        color: '#a94442'
    },
    warning: {
        backgroundColor: '#fcf8e3',
        color: '#8a6d3b'
    }
});

export const getStylesForType = (classes: ReturnType<typeof useStyles>, type: MessageType): string|undefined => {
    switch (type) {
        case MessageType.ERROR:
            return classes.error;
        case MessageType.WARNING:
            return classes.warning;
    }
};

export const MessageBox: FunctionComponent<Props> = ({ children, className, type, testId }) => {
    const classes = useStyles();

    return <Paper data-testid={`${testId}-${type}-box`} className={clsx(classes.default, getStylesForType(classes, type), className)}>{children}</Paper>;
};
