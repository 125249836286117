import * as LambdaModel from '../lambda-model-types';
import { decryptCustomerId } from '@amzn/amazon-id';
import { INVERTED_MARKETPLACE_MAP } from '../../../constants/experiment/marketplace-map';
import { LimestoneExperiment } from '../../../interfaces/LimestoneExperiment';
import { ExperimentStatus } from '../../../enums/ExperimentStatus';
import { RegionCreationStatus } from '../../../enums/RablRegionCreationStatus';
import { DisplayAttribute } from '../../../interfaces/DisplayAttribute';
import { AttributeLabels } from '../../../constants/display/string-constants';
import { convertLifecycleTypeToExperimentType } from './experiment-type-adaptor';
import { LifecycleType } from '../../api-constants';
import { EXPERIMENT_TYPE_TO_DISPLAY } from '../../../enums/ExperimentType';

export const convertReadResponseToExperiment = (experimentOutput: LambdaModel.ExperimentOutput): LimestoneExperiment => {
    const primaryBusinessGroup: LambdaModel.BusinessGroupDto = experimentOutput.businessGroupsDto.filter((businessGroupDto) => businessGroupDto.primary === true)[0];
    const primaryBusinessGroupDisplay = primaryBusinessGroup ? primaryBusinessGroup.businessGroup : '';

    const secondaryBusinessGroups: LambdaModel.BusinessGroupDto[] = experimentOutput.businessGroupsDto.filter((businessGroupDto) => businessGroupDto.primary !== true);
    const secondaryBusinessGroupsDisplay: string = secondaryBusinessGroups.map((businessGroupDto) => businessGroupDto.businessGroup).join(', ');

    const primaryOwner: LambdaModel.OwnerDto = experimentOutput.ownersDto.filter((ownerDto) => ownerDto.primary)[0];
    const primaryOwnerDisplay = primaryOwner ? primaryOwner.alias : '';

    const secondaryOwners: LambdaModel.OwnerDto[]|undefined = experimentOutput.ownersDto.filter((ownerDto) => ownerDto.primary !== true);
    const secondaryOwnersDisplay: string = secondaryOwners.map((secondaryOwner) => secondaryOwner.alias).join(', ');

    const observersDisplay: string|undefined = experimentOutput.observersDto?.map((observerDto) => observerDto.alias).join(', ');
    const tagsDisplay: string|undefined = experimentOutput.tagsDto?.map((tagDto) => tagDto.tag).join(', ');

    const deObfuscatedMarketplaceId = decryptCustomerId(experimentOutput.marketplaceId);
    const marketplaceLabel = INVERTED_MARKETPLACE_MAP[Number(deObfuscatedMarketplaceId)];

    const currentStatus = experimentOutput.currentStatus;
    const currentStatusDisplay = (ExperimentStatus as any)[currentStatus!];
    const regionStatus = experimentOutput.regionCreationStatus;
    const regionStatusDisplay = (RegionCreationStatus as any)[regionStatus!];

    const multitreatmentExperimentsDisplay = experimentOutput.linkedMultiTreatmentExperimentIds? experimentOutput.linkedMultiTreatmentExperimentIds.join(', ') : '';

    const customMetricsDisplay = experimentOutput.customMetrics? experimentOutput.customMetrics.map((customMetric) => customMetric.displayName).join(', ') : '';

    const experiment: LimestoneExperiment = {
        experimentId: experimentOutput.experimentId,
        experimentIntegerId: experimentOutput.experimentIntegerId,
        creationDate: experimentOutput.creationDate,
        lastUpdatedDate: experimentOutput.lastUpdatedDate,
        metadata: {
            marketplace: new DisplayAttribute(AttributeLabels.MARKETPLACE, true, experimentOutput.marketplaceId, marketplaceLabel),
            title: new DisplayAttribute(AttributeLabels.TITLE, true, experimentOutput.title, experimentOutput.title),
            description: new DisplayAttribute(AttributeLabels.DESCRIPTION, true, experimentOutput.description, experimentOutput.description),
            productFamily: new DisplayAttribute(AttributeLabels.PRODUCT_FAMILY, true, experimentOutput.productFamily, experimentOutput.productFamily),
            primaryBusinessGroup: new DisplayAttribute(AttributeLabels.PRIMARY_BUSINESS_GROUP, true, primaryBusinessGroup, primaryBusinessGroupDisplay),
            secondaryBusinessGroups: new DisplayAttribute(AttributeLabels.SECONDARY_BUSINESS_GROUPS, true, secondaryBusinessGroups, secondaryBusinessGroupsDisplay),
            primaryOwner: new DisplayAttribute(AttributeLabels.PRIMARY_OWNER, true, primaryOwner, primaryOwnerDisplay),
            secondaryOwners: new DisplayAttribute(AttributeLabels.SECONDARY_OWNERS, true, secondaryOwners, secondaryOwnersDisplay),
            observers: new DisplayAttribute(AttributeLabels.OBSERVERS, true, experimentOutput.observersDto, observersDisplay!),
            tags: new DisplayAttribute(AttributeLabels.TAGS, true, experimentOutput.tagsDto, tagsDisplay!),
            rablRegionId: new DisplayAttribute(AttributeLabels.RABL_REGION_ID, true, experimentOutput.regionId, experimentOutput.regionId),
            discriminator: new DisplayAttribute(AttributeLabels.DISCRIMINATOR, true, experimentOutput.listerDiscriminator, experimentOutput.listerDiscriminator),
            startDate: new DisplayAttribute(AttributeLabels.START_DATE, true, experimentOutput.experimentStartDate, experimentOutput.experimentStartDate),
            endDate: new DisplayAttribute(AttributeLabels.END_DATE, true, experimentOutput.experimentEndDate, experimentOutput.experimentEndDate),
            regionCreationStatus: new DisplayAttribute(AttributeLabels.REGION_CREATION_STATUS, true, experimentOutput.regionCreationStatus, regionStatusDisplay),
            businessApprover: new DisplayAttribute(AttributeLabels.BUSINESS_APPROVER),
            experimentType: new DisplayAttribute(AttributeLabels.EXPERIMENT_TYPE, true, experimentOutput.experimentType, EXPERIMENT_TYPE_TO_DISPLAY.get(convertLifecycleTypeToExperimentType(experimentOutput.experimentType as LifecycleType))),
            linkedMultiTreatmentExperimentIds: new DisplayAttribute(AttributeLabels.LINKED_MULTI_TREATMENT_EXPERIMENT_IDS, true, experimentOutput.linkedMultiTreatmentExperimentIds, multitreatmentExperimentsDisplay),
            bundleEnabled: new DisplayAttribute(AttributeLabels.BUNDLE_ENABLED, true, experimentOutput.bundleEnabled, String(experimentOutput.bundleEnabled)),
            customMetrics: new DisplayAttribute(AttributeLabels.ADDITIONAL_CUSTOM_METRICS, true, experimentOutput.customMetrics, customMetricsDisplay),
            regionDefinitionType: new DisplayAttribute(AttributeLabels.REGION_DEFINITION_TYPE, true, experimentOutput.boundaryType, experimentOutput.boundaryType),
            dealsEnabled: new DisplayAttribute(AttributeLabels.DEALS_ENABLED, true, experimentOutput.dealsEnabled, String(experimentOutput.dealsEnabled)),
            treatmentType: new DisplayAttribute(AttributeLabels.TREATMENT_TYPE, true, experimentOutput.treatmentType, experimentOutput.treatmentType),
        },
        currentStatus: {
            currentStatus: new DisplayAttribute(AttributeLabels.CURRENT_STATUS, true, currentStatus, currentStatusDisplay),
            lastRefreshed: new DisplayAttribute(AttributeLabels.LAST_REFRESHED, true, null,  new Date().toLocaleString()),
        },
        productSelection: {
            offersFile: new DisplayAttribute(AttributeLabels.OFFERS_FILE)
        },
        regionSelection: {
            treatmentBoundaries: new DisplayAttribute(AttributeLabels.TREATMENT_BOUNDARIES)
        },
        selectionFinalized: experimentOutput.selectionFinalized,
        comment: experimentOutput.comment,
        customerDecision: experimentOutput.customerDecision,
        businessRecommendation: experimentOutput.businessRecommendation,
    };

    return experiment;
};
