import { ExperimentUserRoleType } from '../../api/experiment-service/lambda-model-types';
import { MetadataAttribute } from '../../enums/CommonTypes';
import { DisplayMode } from '../../interfaces/FormAttribute';
import { ExperimentAttributeProps } from '../ExperimentAttribute';
import { MultiSelectField, MultiSelectFieldConfig } from '../fields/MultiSelectField';
import { AttributeLabels } from '../../constants/display/string-constants';
import { LemsApiHandler } from '../../api/experiment-service/handler/lems-api-handler';
import { Realm } from '../../api/api-constants';
import ApiHandler from '../../api/experiment-service/handler/lems-api-handler-impl';
import { ExperimentStatusType } from '../../enums/ExperimentStatus';
import { LimestoneExperiment } from '../../interfaces/LimestoneExperiment';
import { SelectProps } from '@amzn/awsui-components-react-v3';

export class LinkedMultiTreatmentExperimentIds extends MultiSelectField<ExperimentAttributeProps> {
    protected displayConfig: MultiSelectFieldConfig;
    private experimentServiceAPI: LemsApiHandler;

    constructor(props: ExperimentAttributeProps) {
        super(props);

        const realm = props.realm ? props.realm : Realm.NA;
        this.experimentServiceAPI = new ApiHandler(realm);

        this.displayConfig = {
            label: AttributeLabels.LINKED_MULTI_TREATMENT_EXPERIMENT_IDS,
            editable: false,
            touched: false,
            placeholder: 'Select from the drop-down',
            hintText: 'Select the previously created experiments that are part of the same multi-treatment experiment.',
            filteringType: 'auto',
            statusType: 'loading',
            onChange: (event) => this.onChangeEvent(event as CustomEvent, MetadataAttribute.LINKED_MULTI_TREATMENT_EXPERIMENT_IDS),
            selectedOptions: [],
        };

        this.state = {
            displayValue: '',
            displayMode: props.displayMode ? props.displayMode : DisplayMode.CREATE,
            validity: true
        };
    }

    componentDidMount = async() => {
        let response: LimestoneExperiment[];
        if (this.props.userAttributes!.isAdmin) {
            response = await this.experimentServiceAPI.getExperimentsInGivenStatuses(
                Object.values(ExperimentStatusType)
                    .filter((status) =>
                        status !== ExperimentStatusType.REJECTED && status !== ExperimentStatusType.COMPLETE));
        } else {
            response = await this.experimentServiceAPI.getExperimentsByUserAlias(
                this.props.userAttributes!.username,
                [ExperimentUserRoleType.OBSERVER, ExperimentUserRoleType.OWNER]);
        }

        this.displayConfig.options = response.map((experiment) => {
            return {
                description: experiment.experimentId,
                label: experiment.metadata.title.displayValue,
                value: experiment.experimentId
            };
        });
        this.displayConfig.statusType = 'finished';

        if (this.props.initialValue) {
            this.setValueFromPayload(this.props.initialValue);
        }

        this.forceUpdate();
    }

    getPayloadValue = (): String[] => {
        const linkedMultiTreatmentExperimentIds: string[] = [];
        this.getSelectedOptions().forEach((selectedOption) => {
            linkedMultiTreatmentExperimentIds.push(selectedOption.value!);
        });

        return linkedMultiTreatmentExperimentIds;
    };

    setValueFromPayload = async(linkedMultiTreatmentExperimentIds: string[]) => {
        const selectedOptions = this.displayConfig.options?.filter((option: SelectProps.Option) => {
            return linkedMultiTreatmentExperimentIds.some((experimentId) => experimentId === option.value);
        }) ?? [];

        this.setValue(selectedOptions);
    }
}
