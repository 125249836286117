import { MetricType } from '../enums/MetricType';

export interface CustomEvaluationRequest {
    name: string;
    selection: File | null;
    metricTypes: MetricType[];
    editing: boolean;
    id?: number
}

export enum CustomEvaluationRequestAttributes {
    NAME = 'name',
    SELECTION = 'selection',
    METRIC_TYPES = 'metricTypes',
    EDITING = 'editing'
}
