import React, { FunctionComponent, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Wizard, WizardProps } from '@amzn/awsui-components-react-v3';
import { PageProps } from '../interfaces/IProps';
import { wizardI18N } from '../interfaces/CreateWizardTypes';
import { UserInputModal } from './UserInputModal';
import { WizardCancelModalAttributes, WizardSubmitModalAttributes } from '../constants/display/modal-constants';
import { PermissionControlledView } from '../permissions/PermissionControlledView';

export interface WizardWrapperProps extends RouteComponentProps, PageProps {
    steps: WizardProps.Step[];
    validateStep: (stepId: number) => boolean;
    submitResponse: () => Promise<void>;
    overrideActiveStepIndex?: number;
}

export const WizardWrapper: FunctionComponent<WizardWrapperProps> = (props: WizardWrapperProps) => {
    const [ activeStepIndex, updateActiveStepIndex ] = useState(props.overrideActiveStepIndex ? props.overrideActiveStepIndex : 0);
    const [ pristineState, updatePristineState ] = useState(true);
    const [ buttonLoadingState, updateButtonLoadingState ] = useState(false);
    const [ showCancelModal, updateCancelModalState ] = useState(false);
    const [ showSubmitModal, updateSubmitModalState ] = useState(false);

    useEffect(() => {
        if (props.overrideActiveStepIndex !== undefined) {
            updateActiveStepIndex(props.overrideActiveStepIndex);
        }
    }, [props.overrideActiveStepIndex]);


    const cancelModalHandlers = {
        dismiss: () => updateCancelModalState(false),
        submit: () => props.history.push('/')
    };

    const submitModalHandlers = {
        dismiss: () => updateSubmitModalState(false),
        submit: () => onWizardSubmitClick(),
    };

    const onWizardNavigate = (detail: WizardProps.NavigateDetail) => {
        if (detail.requestedStepIndex < activeStepIndex) {
            updateActiveStepIndex(detail.requestedStepIndex);
        } else {
            const steps = detail.requestedStepIndex - activeStepIndex;
            for (let i = 0; props.validateStep(activeStepIndex) && i < steps; i++) {
                updateActiveStepIndex(activeStepIndex + 1);
                updatePristineState(false);
            }

            updatePristineState(true);
        }
    };

    const onWizardSubmitClick = async () => {
        updateButtonLoadingState(true);
        updateSubmitModalState(false);

        await props.submitResponse().finally(() => updateButtonLoadingState(false));
    };

    const stepsWithErrorText = props.steps.map(({ errorText, ...step }) => ({ ...step,
        errorText: props.validateStep(activeStepIndex) || pristineState ? null : errorText
    }));

    return (
        <PermissionControlledView
            userAccessLevels={props.userAccessLevels}
            pagePermissionsMap={props.permissionsMap}
        >
            <UserInputModal
                visible={showCancelModal}
                buttonHandlers={cancelModalHandlers}
                {...WizardCancelModalAttributes}
            />

            <UserInputModal
                visible={showSubmitModal}
                buttonHandlers={submitModalHandlers}
                {...WizardSubmitModalAttributes}
            />

            <Wizard
                data-testid={'wizard-wrapper'}
                steps={stepsWithErrorText}
                activeStepIndex={activeStepIndex}
                i18nStrings={wizardI18N}
                isLoadingNextStep={buttonLoadingState}
                onCancel={() => updateCancelModalState(true)}
                onNavigate={({ detail }) => onWizardNavigate(detail)}
                onSubmit={() => updateSubmitModalState(true)}
            />
        </PermissionControlledView>
    );
};

export default withRouter(WizardWrapper);
