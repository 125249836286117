/**
 * Gets the number of empty strings in the given list.
 * @param originalValues list of strings.
 * @returns number of empty strings.
 */
export const getEmptyCount = (
    originalValues: string[]
): number => {
    return originalValues.filter((value) => value.trim().length === 0).length;
};

/**
 * Gets the duplicate values in the given set of strings.
 * @param originalValues list of strings.
 * @returns duplicate values in the list.
 */
export const getDuplicates = (
    originalValues: string[]
): Set<string> => {
    const nonEmptyValues = originalValues.filter((value) => value.trim().length > 0).map((value) => value.trim());

    const counter = nonEmptyValues.reduce((accumulator: any, value: any) => {
        accumulator[value] = ++accumulator[value] || 0;
        return accumulator;
    }, {});
      
    return new Set(nonEmptyValues.filter((value: string) => counter[value]));
};

/**
 * Gets the unique values in the given set of strings.
 * @param originalValues list of strings.
 * @returns unique values in the list.
 */
export const getUniqueValues = (
    originalValues: string[]
): string[] => {
    const nonEmptyValues = originalValues.filter((value) => value.trim().length > 0).map((value) => value.trim());

    const counter = nonEmptyValues.reduce((accumulator: any, value: any) => {
        accumulator[value] = ++accumulator[value] || 0;
        return accumulator;
    }, {});
      
    return nonEmptyValues.filter((value: any) => counter[value] === 0);
};