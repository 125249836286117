export enum ExperimentType {
    LISTER_EXPERIMENT = 'LISTER_EXPERIMENT',
    RESULT_ANALYSIS_ONLY = 'RESULT_ANALYSIS_ONLY'
}

/**
 * Map for experiment type to display on the UI.
 */
export const EXPERIMENT_TYPE_TO_DISPLAY = new Map<ExperimentType, string>([
    [ExperimentType.LISTER_EXPERIMENT, 'Static Experiment with Lister'],
    [ExperimentType.RESULT_ANALYSIS_ONLY, 'Result Analysis Only Experiment']
]);
