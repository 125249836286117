export enum OfferValidationStatusType {
    VALIDATING = 'VALIDATING',
    PASS = 'PASS',
    FAIL = 'FAIL',
    INCONCLUSIVE = 'INCONCLUSIVE',
    NOT_STARTED = 'NOT_STARTED'
}

export enum OfferRegionalizationStatusType {
    REGIONALIZED = 'REGIONALIZED',
    NOT_REGIONALIZED = 'NOT_REGIONALIZED'
}
