import React, { Component } from 'react';
import { Alert, Box, Button, ColumnLayout, ExpandableSection, Header } from '@amzn/awsui-components-react-v3';
import { IButtonHandler } from '../../interfaces/IButtonHandler';
import * as NOTIFICATION_MESSAGES from '../../constants/display/flashbar-messages';
import { LemsApiHandler } from '../../api/experiment-service/handler/lems-api-handler';
import LemsApiHandlerImpl from '../../api/experiment-service/handler/lems-api-handler-impl';
import { UserInputModal } from '../../common/UserInputModal';
import {
    SubmitBoundariesModalAttributes,
    SubmitOverrideBoundariesModalAttributes
} from '../../constants/display/modal-constants';
import { handleErrorResponse } from '../../utils/error-handler-utils';
import { DisplayTable } from '../../common/DisplayTable';
import { getColumnDefinitions, getColumnOptions, pageSizeOptions } from '../../constants/table/boundary-set-attributes';
import { BoundarySet } from '../../interfaces/BoundarySet';
import { LimestoneExperiment, LimestoneExperimentBoundaries } from '../../interfaces/LimestoneExperiment';
import { LifecycleType, Realm } from '../../api/api-constants';
import { RablRegionCreationStatusType } from '../../enums/RablRegionCreationStatus';
import { IProps } from '../../interfaces/IProps';
import { ActionRequiredMessages, AttributeLabels, TableHeaders } from '../../constants/display/string-constants';
import { Boundaries } from '../../form/attributes/Boundaries';
import { DisplayAttribute } from '../../interfaces/DisplayAttribute';
import { ActionType, PermissionsMap, UserAccessLevel } from '../../enums/PermissionsModel';
import { PermissionControlledButton } from '../../permissions/PermissionControlledButton';
import { getActionRequiredNotification } from '../../constants/display/flashbar-messages';
import { EMBED_BOUNDARIES_MAP, EMBED_REGION_MAP, EXCLUDES, INCLUDES, RABL_BASE_URLS } from '../../api/rabl-constants';
import { Boundary } from '../../api/region-service/api-interfaces';
import RMSApiHandler from '../../api/region-service/handler/rms-api-handler';
import { removeLemsBoundaryPrefix } from '../../api/experiment-service/adaptors/region-selection-adaptor';

export interface ExperimentRegionSectionProps extends IProps {
    realm: Realm;
    experimentId: string;
    setNotification: Function;
    userAccessLevels: Set<UserAccessLevel>;
    pagePermissionsMap: PermissionsMap;
    experiment: LimestoneExperiment;
}

export interface ExperimentRegionSectionState {
    regionSimulationBoundarySets: BoundarySet[];
    manualOverrideBoundarySets: BoundarySet[];
    selectedBoundarySet: BoundarySet|null;
    displayRegionResults: boolean;
    submitBoundariesButtonDisabled: boolean;
    submitBoundariesButtonLoading: boolean;
    showSubmitBoundariesModal: boolean;
    manuallyUploadSelectedBoundaries: string[];
    submitOverrideBoundariesButtonDisabled: boolean;
    submitOverrideBoundariesButtonLoading: boolean;
    showSubmitOverrideBoundariesModal: boolean;
    experimentRegionSelection: LimestoneExperimentBoundaries;
    rablEmbedUrl: string;
}

export class ExperimentRegionSection extends Component<ExperimentRegionSectionProps, ExperimentRegionSectionState> {
    private readonly buttonHandlers: any;
    private readonly submitBoundariesModalHandlers: IButtonHandler;
    private readonly submitOverrideBoundariesModalHandler: IButtonHandler;

    /** Experiment Service handler instance which provides api to get the experiment data from the backend */
    public experimentServiceAPI: LemsApiHandler;

    public regionManagementServiceAPI: RMSApiHandler;

    public constructor(props: any) {
        super(props);

        this.experimentServiceAPI = new LemsApiHandlerImpl(props.realm);
        this.regionManagementServiceAPI = new RMSApiHandler(props.realm);

        this.state = {
            regionSimulationBoundarySets: [],
            manualOverrideBoundarySets: [],
            selectedBoundarySet: null,
            displayRegionResults: false,
            submitBoundariesButtonDisabled: true,
            submitBoundariesButtonLoading: false,
            showSubmitBoundariesModal: false,
            manuallyUploadSelectedBoundaries: [],
            submitOverrideBoundariesButtonDisabled: true,
            submitOverrideBoundariesButtonLoading: false,
            showSubmitOverrideBoundariesModal: false,
            experimentRegionSelection: {
                treatmentBoundaries: new DisplayAttribute(AttributeLabels.TREATMENT_BOUNDARIES)
            },
            rablEmbedUrl: '',
        };

        this.buttonHandlers = {
            submitBoundaries: () => this.setState({ showSubmitBoundariesModal: true }),
            submitOverrideBoundaries: () => this.setState({ showSubmitOverrideBoundariesModal: true })
        };

        this.submitBoundariesModalHandlers = {
            dismiss: () => this.setState({ showSubmitBoundariesModal: false }),
            submit: () => this.submitFinalBoundaries()
        };

        this.submitOverrideBoundariesModalHandler = {
            dismiss: () => this.setState({ showSubmitOverrideBoundariesModal: false }),
            submit: () => this.submitOverrideBoundaries()
        };
    }

    componentDidMount = async() => {
        if (this.props.experiment.metadata.regionCreationStatus.payloadValue === RablRegionCreationStatusType.AWAITING_USER_RESPONSE) {
            try {
                const boundarySets = await this.experimentServiceAPI.getExperimentBoundaryOptions(this.props.experiment.experimentId);
                for (const boundarySet of boundarySets) {
                    boundarySet.rmsBoundaries = await this.getRmsBoundariesForBoundarySet(boundarySet);
                }
                this.setState({
                    regionSimulationBoundarySets: boundarySets.filter((boundarySet) => !boundarySet.isManuallyUploaded),
                    manualOverrideBoundarySets: boundarySets.filter((boundarySets) => boundarySets.isManuallyUploaded),
                    displayRegionResults: true,
                });

                this.props.setNotification!(getActionRequiredNotification('CONFIRM_TREATMENT_REGION', ActionRequiredMessages.CONFIRM_TREATMENT_REGION));
            } catch (error) {
                handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.getExperimentBoundaries.FAIL!);
            }
        } else {
            try {
                const response = await this.experimentServiceAPI.getAllExperimentBoundaries(this.props.experiment.experimentId);
                this.setState({ experimentRegionSelection: response! });
            } catch (error) {
                handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.getExperimentBoundaries.FAIL!);
            }
        }
        this.setState({
            rablEmbedUrl: await this.getRablEmbedUrl()
        });
    }

    submitFinalBoundaries = async() => {
        this.setState({ submitBoundariesButtonLoading: true, showSubmitBoundariesModal: false });

        await this.experimentServiceAPI.finalizeExperimentBoundaries(this.props.experimentId, this.state.selectedBoundarySet!)
            .then(() => {
                this.props.setNotification!(NOTIFICATION_MESSAGES.submitExperimentBoundaries.SUCCESS);
                window.location.reload();
            })
            .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.submitExperimentBoundaries.FAIL!))
            .finally(() => this.setState({ submitBoundariesButtonLoading: false }));
    }

    submitOverrideBoundaries = async() => {
        this.setState({ submitOverrideBoundariesButtonLoading: true, showSubmitOverrideBoundariesModal: false });

        await this.experimentServiceAPI.manualOverrideBoundaries(this.props.experimentId, this.state.manuallyUploadSelectedBoundaries)
            .then(() => {
                this.props.setNotification!(NOTIFICATION_MESSAGES.submitExperimentBoundaries.SUCCESS);
                window.location.reload();
            })
            .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.submitExperimentBoundaries.FAIL!))
            .finally(() => this.setState({ submitOverrideBoundariesButtonLoading: false }));
    }

    onBoundarySelectionChange = (selectedBoundarySets: BoundarySet[]) => {
        if (selectedBoundarySets.length === 0) {
            this.setState({ selectedBoundarySet: null, submitBoundariesButtonDisabled: true });
        } else if (selectedBoundarySets.length === 1) {
            this.setState({ selectedBoundarySet: selectedBoundarySets[0], submitBoundariesButtonDisabled: false });
        } else {
            // multiple boundary sets are selected, which is not allowed.
            console.warn('Multiple boundary sets are selected.');
            this.setState({ selectedBoundarySet: null, submitBoundariesButtonDisabled: true });
        }
    }

    getBoundarySetsTableColumnName = (array: BoundarySet[]) => {
        return array.length ? Array.from(array[0].attributes.keys()) : [];
    }

    updateFormState = (_fieldId: string, payloadValue: any, _displayValue: string, _isValid: boolean): void => {
        this.setState({
            manuallyUploadSelectedBoundaries: payloadValue,
            submitOverrideBoundariesButtonDisabled: payloadValue.length === 0
        });
    }

    getRmsBoundariesForBoundarySet = async(boundarySet: BoundarySet): Promise<Map<string, Boundary>> => {
        const boundaries = new Map<string, Boundary>();

        const boundaryOutputs = await this.regionManagementServiceAPI.getBoundariesInBatch(
            this.props.experiment.metadata.marketplace.displayValue,
            this.props.experiment.metadata.regionDefinitionType.payloadValue,
            Array.from(boundarySet.boundaries.keys()));

        boundaryOutputs.forEach((boundaryOutput) => {
            boundaries.set(boundaryOutput.boundaryName, boundaryOutput);
        });

        return boundaries;
    }

    getRablEmbedUrl = async() => {
        if (this.props.experiment.metadata.experimentType.payloadValue === LifecycleType.RESULT_ANALYSIS_ONLY) {
            const boundaryNames = this.state.experimentRegionSelection!.treatmentBoundaries.payloadValue
                .map((boundaryName: string) => removeLemsBoundaryPrefix(boundaryName));
            const boundaryOutputs = await this.regionManagementServiceAPI.getBoundariesInBatch(
                this.props.experiment.metadata.marketplace.displayValue,
                this.props.experiment.metadata.regionDefinitionType.payloadValue,
                boundaryNames);
            const boundaryIds = boundaryOutputs.map((boundaryOutput) => boundaryOutput.rablId);
            return `${RABL_BASE_URLS.getEndpoint(Realm.NA) + EMBED_BOUNDARIES_MAP}?${INCLUDES}=${[]}&${EXCLUDES}=${boundaryIds}`;
        } else {
            return RABL_BASE_URLS.getEndpoint(this.props.realm) + EMBED_REGION_MAP + this.props.experiment.metadata.rablRegionId.payloadValue;
        }
    }

    render() {
        const modals = (
            <>
                <UserInputModal
                    visible={this.state.showSubmitBoundariesModal}
                    buttonHandlers={this.submitBoundariesModalHandlers}
                    {...SubmitBoundariesModalAttributes}
                />
                <UserInputModal
                    visible={this.state.showSubmitOverrideBoundariesModal}
                    buttonHandlers={this.submitOverrideBoundariesModalHandler}
                    {...SubmitOverrideBoundariesModalAttributes}
                />
            </>
        );

        const treatmentBoundariesActionStripe = (
            <Header
                variant="h2"
                actions={
                    this.props.experiment.metadata.experimentType.payloadValue !== LifecycleType.RESULT_ANALYSIS_ONLY &&
                        <PermissionControlledButton
                            testId={'submit-boundaries-button'}
                            userAccessLevels={this.props.userAccessLevels}
                            actionType={ActionType.WRITE}
                            pagePermissionsMap={this.props.pagePermissionsMap}
                            hideIfNotAuthorized={true}
                            buttonProps={{
                                loading: this.state.submitBoundariesButtonLoading,
                                disabled: this.state.submitBoundariesButtonDisabled,
                                onClick: this.buttonHandlers.submitBoundaries,
                                variant: 'primary'
                            }}
                        >Finalize Region</PermissionControlledButton>
                }
            >
                Treatment Region
            </Header>
        );

        const boundariesList = <div style={{ display: 'table' }}>
            <div data-testid={'display-wrapper'} style={{ display: 'table-cell' }}>
                <Box variant="awsui-key-label">
                    <Box variant="strong"><u>{this.state.experimentRegionSelection!.treatmentBoundaries.displayLabel}</u></Box>
                </Box>
                <div style={{ maxHeight: 120, overflow: 'auto' }}>{this.state.experimentRegionSelection!.treatmentBoundaries.displayValue}</div>
            </div>
        </div>;

        const regionStatus = <div style={{ display: 'table' }}>
            <div data-testid={'display-wrapper'} style={{ display: 'table-cell' }}>
                <Box variant="awsui-key-label">
                    <Box variant="strong"><u>{this.props.experiment.metadata.regionCreationStatus.displayLabel}</u></Box>
                </Box>
                <div>{this.props.experiment.metadata.regionCreationStatus.displayValue}</div>
            </div>
        </div>;

        const content = (<>
            <ExpandableSection header={treatmentBoundariesActionStripe} variant='container' defaultExpanded={true}>
                {this.state.displayRegionResults ?
                    (<>
                        {this.state.regionSimulationBoundarySets.length !== 0 || this.state.manualOverrideBoundarySets.length !== 0 ?
                            (<Alert
                                header="Notes"
                                data-testid='region-results-table-alert'
                            >Please do not select more than one boundary set option to finalize
                                region.</Alert>) : (<></>)
                        }
                        <div style={{ margin: '10px 0' }}/>
                        {this.state.regionSimulationBoundarySets.length !== 0 ?
                            (<DisplayTable
                                data-testid='region-simulation-results'
                                items={this.state.regionSimulationBoundarySets}
                                tableLoading={false}
                                selectionType={'single'}
                                onSelectionChange={this.onBoundarySelectionChange}
                                selectedItems={this.state.selectedBoundarySet ? [this.state.selectedBoundarySet] : []}
                                title={
                                    <Header variant="h2">{TableHeaders.REGION_SIMULATION_RESULT} <span
                                        className="awsui-util-header-counter">({this.state.regionSimulationBoundarySets.length})</span>
                                    </Header>}
                                columnDefinitions={getColumnDefinitions(this.getBoundarySetsTableColumnName(this.state.regionSimulationBoundarySets))}
                                columnOptions={getColumnOptions(this.getBoundarySetsTableColumnName(this.state.regionSimulationBoundarySets))}
                                pageSizeOptions={pageSizeOptions}
                            />) : (<></>)
                        }
                        <div style={{ margin: '10px 0' }}/>
                        {this.state.manualOverrideBoundarySets.length !== 0 ?
                            (<DisplayTable
                                data-testid='pea-region-results'
                                items={this.state.manualOverrideBoundarySets}
                                tableLoading={false}
                                selectionType={'single'}
                                onSelectionChange={this.onBoundarySelectionChange}
                                selectedItems={this.state.selectedBoundarySet ? [this.state.selectedBoundarySet] : []}
                                title={
                                    <Header variant="h2">{TableHeaders.MANUALLY_UPLOADED_BOUNDARIES} <span
                                        className="awsui-util-header-counter">({this.state.manualOverrideBoundarySets.length})</span>
                                    </Header>}
                                columnDefinitions={getColumnDefinitions(this.getBoundarySetsTableColumnName(this.state.manualOverrideBoundarySets))}
                                columnOptions={getColumnOptions(this.getBoundarySetsTableColumnName(this.state.manualOverrideBoundarySets))}
                                pageSizeOptions={pageSizeOptions}
                            />) : (<></>)
                        }
                        <div style={{ margin: '10px 0' }}/>
                        <div>
                            <Boundaries
                                data-testid='boundaries-dropdown'
                                updateFormState={this.updateFormState}
                                marketplaceId={this.props.experiment.metadata.marketplace.payloadValue}
                                experimentId={this.props.experiment.experimentId}
                                realm={this.props.realm}
                                definitionType={this.props.experiment.metadata.regionDefinitionType.payloadValue}
                                startDate={this.props.experiment.metadata.startDate.payloadValue}
                                endDate={this.props.experiment.metadata.endDate.payloadValue}
                                allowOverlappingBoundaries={false}
                            />
                            <div style={{ margin: '10px 0' }}/>
                            <Button
                                data-testid={'submit-override-boundaries-button'}
                                loading={this.state.submitOverrideBoundariesButtonLoading}
                                disabled={this.state.submitOverrideBoundariesButtonDisabled}
                                onClick={this.buttonHandlers.submitOverrideBoundaries}
                            >Submit For Manual Override</Button>
                        </div>
                    </>) :
                    <>
                        {this.props.experiment.metadata.experimentType.payloadValue === LifecycleType.RESULT_ANALYSIS_ONLY
                            ? boundariesList
                            : <ColumnLayout columns={2} variant='text-grid'>
                                {boundariesList}
                                {regionStatus}
                            </ColumnLayout>}
                        {(this.props.experiment.metadata.experimentType.payloadValue === LifecycleType.RESULT_ANALYSIS_ONLY
                            || this.props.experiment.metadata.regionCreationStatus.payloadValue === RablRegionCreationStatusType.LIVE)
                        && <iframe src={this.state.rablEmbedUrl}
                            title='Region map'
                            width='100%'
                            height='500'
                            frameBorder='0'
                            scrolling='no'
                            style={{ padding: '20px 0px 1px 0px' }}
                        />}
                    </>
                }
            </ExpandableSection>

            {modals}
        </>);
        return (
            <div style={{ padding: 20 }}>
                {content}
            </div>
        );
    }
}
