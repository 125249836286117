export enum TableDimension {
    AGGREGATED_DIMENSION = 'AGGREGATED_DIMENSION',
    CHANNEL = 'CHANNEL',
    METRIC_DIMENSION = 'METRIC_DIMENSION'
}

export enum MetricDataType {
    METRIC_DIMENSION = 'METRIC_DIMENSION',
    OBSERVED_DATA = 'OBSERVED_DATA',
    RMSE_RATIO = 'RMSE_RATIO',
    P_VALUE_AUDIT = 'P_VALUE_AUDIT',
    ESTIMATED_BASE = 'ESTIMATED_BASE',
    ABSOLUTE_LIFT = 'ABSOLUTE_LIFT',
    PERCENT_LIFT = 'PERCENT_LIFT',
    LIFT_PER_USER = 'LIFT_PER_USER',
    P_VALUE = 'P_VALUE',
    LOWER_BOUND_90 = 'LOWER_BOUND_90',
    UPPER_BOUND_90 = 'UPPER_BOUND_90',
    POINT_ESTIMATE = 'POINT_ESTIMATE',
    TOTAL_LIFT = 'TOTAL_LIFT'
}

export interface TransactionalMetricsRow {
    dimensionValues: string[];
    metricValues: string[];
}

export interface TableSchema {
    dimensionColumnIndex: Object;
    metricColumnIndex: Object;
}

export interface MetricsTable {
    schema: TableSchema;
    rows: TransactionalMetricsRow[];
}

export interface GetTransactionalMetricsResponse {
    totalTreatmentCustomers: number;
    totalControlCustomers: number;
    table: MetricsTable;
}

export interface GetDownstreamMetricsResponse {
    table: MetricsTable;
}

export interface GetQuickSightEmbedUrlResponse {
    embedUrl: string;
}

export interface MetricsTableMap {
    [key: string]: MetricsTable
}

export interface GetCustomSelectionsTransactionalMetricRequest {
    selectionIds: string[];
    datasetDate: string;
}

export interface GetCustomSelectionsTransactionalMetricResponse {
    selectionIdToTableMap: MetricsTableMap;
    dataNotReadySelectionIds: string[];
}

export enum ExperimentMetricType {
    TRANSACTIONAL_METRICS = 'TRANSACTIONAL_METRICS',
    CUSTOMER_EVALUATION_METRICS = 'CUSTOMER_EVALUATION_METRICS',
    GCCP_ESTIMATES = 'GCCP_ESTIMATES',
    CUSTOM_METRIC = 'CUSTOM_METRIC',
}

export interface GetCompletedCollectionDatesResponse {
    dates: string[];
}

export interface ScheduleDataCollectionRequest {
    externalId: string;
    marketplaceId: number;
    dataType: string;
    collectionDates: string[];
    customAnalysisStartDate: string;
    customAnalysisEndDate: string;
    regionDefinitionType: string;
    isCustomTriggersUploaded: boolean;
}

export interface ScheduleDataCollectionResponse {
    cdcId: string;
}

export interface GetExperimentKeyMetricsResponse {
    tables: MetricsTable[];
}
