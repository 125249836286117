import { AttributeLabels } from '../../constants/display/string-constants';
import { MetadataAttribute } from '../../enums/CommonTypes';
import { DisplayMode } from '../../interfaces/FormAttribute';
import { constructDate } from '../../utils/date-utils';
import { ExperimentAttributeProps } from '../ExperimentAttribute';
import { DateField, DateFieldConfig } from '../fields/DateField';

export class GccpMetricRequestEndDate extends DateField {
    protected displayConfig: DateFieldConfig;

    constructor(props: ExperimentAttributeProps) {
        super(props);

        const { initialValue, displayMode } = props;

        this.validationRules = { required: true };
        const { isValid } = this.validate(initialValue, this.validationRules);

        this.displayConfig = {
            label: AttributeLabels.END_DATE,
            editable: props.editable === true,
            touched: false,
            isDateEnabled: (date: Date) => this.isDateEnabled(date),
            onChange: (event) => this.onChangeEvent(event as CustomEvent<any>, MetadataAttribute.END_DATE),
            disabled: true,
            value: initialValue,
        };

        this.state = {
            displayValue: '',
            displayMode: displayMode ? displayMode : DisplayMode.CREATE,
            validity: isValid,
            startDate: props.startDate
        };
    }

    componentDidMount() {
        if (this.state.displayMode === DisplayMode.CREATE) {
            this.setValueFromPayload('');
            this.props.updateFormState!(MetadataAttribute.END_DATE, this.getPayloadValue(), this.getDisplayValue(), false);
        } else if (this.props.initialValue) {
            this.setValueFromPayload(this.props.initialValue);
        }
    }

    componentDidUpdate() {
        this.displayConfig.disabled = this.props.startDate === '';
        if (this.state.startDate !== this.props.startDate) {
            this.setValueFromPayload('');
            this.setState({ startDate: this.props.startDate });
        }
    }

    isDateEnabled(date: Date): boolean {
        const earliestDate = constructDate(this.props.startDate!);
        // Default data collection date is the next day of today, end date should before data collection date
        const today = new Date();

        if (date <= earliestDate || date > today) {
            return false;
        }

        return true;
    }
}
