import { LemsApiHandler } from '../../api/experiment-service/handler/lems-api-handler';
import ApiHandler from '../../api/experiment-service/handler/lems-api-handler-impl';
import { ExperimentAttributeProps } from '../ExperimentAttribute';
import { ProductSelectionAttribute } from '../../enums/CommonTypes';
import { Realm } from '../../api/api-constants';
import { DisplayMode } from '../../interfaces/FormAttribute';
import { AttributeLabels } from '../../constants/display/string-constants';
import { TextAreaField, TextAreaFieldConfig } from '../fields/TextAreaField';

export class ProductSelectionList extends TextAreaField {
    private static readonly ASIN_DELIMITER = ',';

    protected displayConfig: TextAreaFieldConfig;
    private experimentServiceAPI: LemsApiHandler;
    private selectionListContent: string;
    private selectionList: string[];

    constructor(props: ExperimentAttributeProps) {
        super(props);

        const { initialValue, displayMode } = props;
        const realm = props.realm ? props.realm : Realm.NA;
        this.experimentServiceAPI = new ApiHandler(realm);

        this.validationRules = { maxLength: 300000 };
        const { isValid, errorText } = this.validate(initialValue, this.validationRules);

        this.displayConfig = {
            label: AttributeLabels.OFFERS_LIST,
            editable: true,
            rows: 6,
            placeholder: 'Ex: ASIN1, ASIN2, ASIN3',
            touched: false,
            hintText: 'A list of offers separated by comma',
            errorText,
            onChange: (event) => this.onChangeEvent(event as CustomEvent, ProductSelectionAttribute.OFFERS_FILE),
            value: props.initialValue,
        };

        this.state = {
            displayValue: '',
            displayMode: displayMode ? displayMode : DisplayMode.CREATE,
            validity: isValid
        };

        this.selectionListContent = '';
        this.selectionList = [];
    }

    componentDidMount() {
        if (this.props.initialValue) {
            this.setValueFromPayload(this.props.initialValue);
        }
    }

    setValueFromPayload = async(newContent: string[]) => {
        await this.setValue(this.convertListToListString(newContent));
    }

    setValue = async(newValue: string) => {
        const { isValid, errorText } = this.validate(newValue, this.validationRules);

        this.selectionListContent = newValue;
        this.selectionList = this.convertListStringToList(newValue);
        this.displayConfig = {
            ...this.displayConfig,
            errorText,
            value: newValue,
        };

        await new Promise((resolve) => this.setState({ displayValue: newValue, validity: isValid }, () => resolve(newValue)));
    }

    getPayloadValue = () => {
        return this.selectionList;
    };

    convertListStringToList = (listString: string): string[] => {
        return listString.replace(/\s/g, '').split(ProductSelectionList.ASIN_DELIMITER);
    }

    convertListToListString = (list: string[]): string => {
        const content = '';
        list.forEach((element) => content.concat(element).concat(ProductSelectionList.ASIN_DELIMITER));
        return content;
    }
}
