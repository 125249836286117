import React from 'react';
import { FormField } from '@amzn/awsui-components-react-v3';
import { Form, FormFileProps } from 'react-bootstrap';
import { ExperimentAttribute, ExperimentAttributeConfig, ExperimentAttributeProps } from '../ExperimentAttribute';
import { IValidationRules } from '../../validation/validation-rules';

export interface FileFieldConfig extends ExperimentAttributeConfig, Omit<FormFileProps, 'label'> {
    value: any;
}

/**
 * Field that will allow user to upload a file. It renders the JSX Element from React-Boostrap.
 * Reference: https://react-bootstrap.github.io/components/forms/
 */
export abstract class FileField<T extends  ExperimentAttributeProps> extends ExperimentAttribute<T> {
    protected displayConfig!: FileFieldConfig;
    protected fileConfig!: any;
    protected summaryDisplay: any;

    parseValueFromEvent = (event: any): File => event.target.files[0];

    getValidity = () => !this.fileConfig.isInvalid;

    getDisplayValue = () => this.fileConfig.label;

    extractContentFromFile = async(file: File): Promise<any> => file;

    validateFile = (value: File, _fileContent: any, validationRules: IValidationRules) => this.validate(value.name, validationRules);

    renderCreateMode = () => (
        <FormField
            data-testid={'create-wrapper'}
            label={this.displayConfig.label}
            constraintText={this.displayConfig.hintText}
            secondaryControl={undefined}
            errorText={(!this.state.validity && this.displayConfig.touched) ? this.displayConfig.errorText : undefined}>
            {this.getPolarisElement()}
        </FormField>
    );

    getPolarisElement = () => (
        <>
            <Form.File {...this.fileConfig} disabled={this.props.disabled} isValid={this.getValidity}/>
            {this.summaryDisplay}
        </>
    )
}
