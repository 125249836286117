import React, { FunctionComponent, Fragment } from 'react';
import { Button, Modal, SpaceBetween } from '@amzn/awsui-components-react-v3';
import { IButtonHandler } from '../interfaces/IButtonHandler';

export interface ModalProps {
    visible: boolean;
    buttonHandlers: IButtonHandler;
    testId: string;
    header: string;
    content?: JSX.Element;
    submitButtonLoading?: boolean;
}

export const UserInputModal: FunctionComponent<ModalProps> = (props: ModalProps) => {
    return (
        <Modal
            data-testid={props.testId}
            visible={props.visible}
            onDismiss={props.buttonHandlers.dismiss}
            header={<Fragment>{props.header}</Fragment>}
            footer={
                <Fragment>
                    <SpaceBetween direction="horizontal" size="s">
                        <Button
                            data-testid={`${props.testId}-cancel-button`}
                            variant="normal"
                            onClick={props.buttonHandlers.dismiss}>Cancel</Button>
                        <Button
                            data-testid={`${props.testId}-submit-button`}
                            variant="primary"
                            onClick={props.buttonHandlers.submit}
                            loading={props.submitButtonLoading ?? false}>Confirm</Button>
                    </SpaceBetween>
                </Fragment>}>
            {props.content}
        </Modal>
    );
};
