export enum SecondaryComponentType {
    DOWNLOAD_BUTTON = 'download-button'
}

export interface SecondaryComponent {
    type: SecondaryComponentType;
    label?: string;
    filePath?: string;
    onClick?: () => void;
}

export enum DisplayMode { CREATE, VIEW, EDIT }

export interface SecondaryControl {
    helpText?: string;
    secondaryComponent?: SecondaryComponent
}
export interface Form {
    [name: string]: JSX.Element;
}

export interface FormValues {
    [name: string]: any;
}