export const maxPageWidth = '1800px';
export const minPageWidth = '50px';
export const pageMargin = '0 auto';
export const pagePadding = '50px';

export const graphWidth = '1000px';
export const graphNodeWidth = 172;
export const graphNodeHeight = 36;
export const graphEdgeLabelWidth = 150;
export const graphEdgeLabelHeight = 40;
export const graphEdgeLabelPosition = 'c';