import { FlashbarProps } from '@amzn/awsui-components-react-v3';

export interface FlashbarMessageDefinition extends FlashbarProps.MessageDefinition {
    baseContent: string;
    id: string;
}

interface FlashbarApiResponse {
    SUCCESS: FlashbarMessageDefinition;
    FAIL: FlashbarMessageDefinition;
}

enum NotificationMessageType {
    SUCCESS = 'success',
    FAIL = 'error',
    WARNING = 'warning',
    INFO = 'info'
}

export const getActionRequiredNotification = (id: string, content: string): FlashbarMessageDefinition => {
    return {
        id,
        header: 'ACTION REQUIRED',
        baseContent: content,
        type: NotificationMessageType.INFO,
        dismissible: false
    };
};

export const validationInProgress: FlashbarMessageDefinition = {
    id: 'validationInProgressInfo',
    header: 'Selection Validation is in Progress',
    baseContent: '',
    type: NotificationMessageType.INFO,
    dismissible: false
};

export const startingExperiment: FlashbarMessageDefinition = {
    id: 'startingExperimentInfo',
    header: 'Regionalization is in Progress',
    baseContent: '',
    type: NotificationMessageType.INFO,
    dismissible: false
};

export const endingExperiment: FlashbarMessageDefinition = {
    id: 'endingExperimentInfo',
    header: 'Unregionalization is in Progress',
    baseContent: '',
    type: NotificationMessageType.INFO,
    dismissible: false
};

export const uploadMetadata: FlashbarApiResponse = {
    SUCCESS: {
        id: 'uploadMetadataSuccess',
        header: 'Success!',
        baseContent: 'Experiment definition has been uploaded successfully',
        type: NotificationMessageType.SUCCESS,
        dismissible: true
    },
    FAIL: {
        id: 'uploadMetadataFailure',
        header: 'Failed',
        baseContent: 'There was an error while uploading definition',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const uploadProductSelection: FlashbarApiResponse = {
    SUCCESS: {
        id: 'uploadProductSelectionSuccess',
        header: 'Success!',
        baseContent: 'Product Selection has been uploaded successfully. You will be redirected to the Experiment Detail Page in 3 seconds...',
        type: NotificationMessageType.SUCCESS,
        dismissible: true
    },
    FAIL: {
        id: 'uploadProductSelectionFailure',
        header: 'Failed',
        baseContent: 'There was an error while uploading product selection',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const downloadSelection: Partial<FlashbarApiResponse> = {
    FAIL: {
        id: 'downloadProductSelectionFailure',
        header: 'Failed',
        baseContent: 'There was an error while downloading product selection',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const startExperiment: FlashbarApiResponse = {
    SUCCESS: {
        id: 'startExperimentSuccess',
        header: 'Success!',
        baseContent: 'Experiment is now starting. Refresh the page to get the most updated Experiment Status.',
        type: NotificationMessageType.SUCCESS,
        dismissible: true
    },
    FAIL: {
        id: 'startExperimentFailure',
        header: 'Failed',
        baseContent: 'There was an error while starting the experiment',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const endExperiment: FlashbarApiResponse = {
    SUCCESS: {
        id: 'endExperimentSuccess',
        header: 'Success!',
        baseContent: 'Experiment is now ending. Refresh the page to get the most updated Experiment Status.',
        type: NotificationMessageType.SUCCESS,
        dismissible: true
    },
    FAIL: {
        id: 'endExperimentFailure',
        header: 'Failed',
        baseContent: 'There was an error while ending the experiment',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const updateExperimentTitle: FlashbarApiResponse = {
    SUCCESS: {
        id: 'updateExperimentTitleSuccess',
        header: 'Success!',
        baseContent: 'Experiment Title has been updated successfully.',
        type: NotificationMessageType.SUCCESS,
        dismissible: true
    },
    FAIL: {
        id: 'updateExperimentTitleFailure',
        header: 'Failed',
        baseContent: 'There was an error in updating the experiment title',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const updateExperimentDescription: FlashbarApiResponse = {
    SUCCESS: {
        id: 'updateExperimentDescriptionSuccess',
        header: 'Success!',
        baseContent: 'Experiment Description has been updated successfully.',
        type: NotificationMessageType.SUCCESS,
        dismissible: true
    },
    FAIL: {
        id: 'updateExperimentDescriptionFailure',
        header: 'Failed',
        baseContent: 'There was an error in updating the Experiment Description',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const readExperiment: Partial<FlashbarApiResponse> = {
    FAIL: {
        id: 'readExperimentFailure',
        header: 'Failed',
        baseContent: 'There was an error in getting the experiment',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const getExperimentsByOwner: Partial<FlashbarApiResponse> = {
    FAIL: {
        id: 'getExperimentsByOwnerFailure',
        header: 'Failed',
        baseContent: 'There was an error in getting your experiments',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const getExperimentsInGivenStatuses: Partial<FlashbarApiResponse> = {
    FAIL: {
        id: 'getExperimentsInGivenStatusesFailure',
        header: 'Failed',
        baseContent: 'There was an error in getting your experiments',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const getExperimentBoundaries: Partial<FlashbarApiResponse> = {
    FAIL: {
        id: 'getExperimentBoundariesFailure',
        header: 'Failed',
        baseContent: 'There was an error in getting the experiment regions',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const submitExperimentBoundaries: FlashbarApiResponse = {
    SUCCESS: {
        id: 'submitExperimentBoundariesSuccess',
        header: 'Success!',
        baseContent: 'Treatment Region is now submitted. Refresh the page to get the most updated Experiment Status.',
        type: NotificationMessageType.SUCCESS,
        dismissible: true
    },
    FAIL: {
        id: 'submitExperimentBoundariesFailure',
        header: 'Failed',
        baseContent: 'There was an error in submitting the treatment region',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const submitCustomerDecision: FlashbarApiResponse = {
    SUCCESS: {
        id: 'submitCustomerDecisionSuccess',
        header: 'Success!',
        baseContent: 'Successfully added decision to the experiment.',
        type: NotificationMessageType.SUCCESS,
        dismissible: true
    },
    FAIL: {
        id: 'submitCustomerDecisionFailure',
        header: 'Failed',
        baseContent: 'There was an error in submitting the customer decision',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const submitBusinessRecommendation: FlashbarApiResponse = {
    SUCCESS: {
        id: 'submitBusinessRecommendationSuccess',
        header: 'Success!',
        baseContent: 'Successfully added business recommendation to the experiment.',
        type: NotificationMessageType.SUCCESS,
        dismissible: true
    },
    FAIL: {
        id: 'submitBusinessRecommendationFailure',
        header: 'Failed',
        baseContent: 'There was an error in submitting the business recommendation',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const finalizeSelection: FlashbarApiResponse = {
    SUCCESS: {
        id: 'finalizeSelectionSuccess',
        header: 'Success!',
        baseContent: 'Product Selection submitted. Refresh the page to get the most updated Experiment Status.',
        type: NotificationMessageType.SUCCESS,
        dismissible: true
    },
    FAIL: {
        id: 'finalizeSelectionFailure',
        header: 'Failed',
        baseContent: 'There was an error in submitting the selection',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const getExperimentsByApprover: FlashbarApiResponse = {
    SUCCESS: {
        id: 'getExperimentsByApproverSuccess',
        header: 'Success!',
        baseContent: 'Successfully retrieved experiments for approver',
        type: NotificationMessageType.SUCCESS,
        dismissible: true
    },
    FAIL: {
        id: 'getExperimentsByApproverFailure',
        header: 'Failed',
        baseContent: 'There was an error in getting experiments',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const submitApprovalDecision: FlashbarApiResponse = {
    SUCCESS: {
        id: 'submitApprovalDecisionSuccess',
        header: 'Success!',
        baseContent: 'Successfully submitted your approval decision.',
        type: NotificationMessageType.SUCCESS,
        dismissible: true
    },
    FAIL: {
        id: 'submitApprovalDecisionFailure',
        header: 'Failed',
        baseContent: 'There was an error in submitting your approval decision',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const getAllExperimentBoundaries: FlashbarApiResponse = {
    SUCCESS: {
        id: 'getAllExperimentBoundariesSuccess',
        header: 'Success!',
        baseContent: 'Successfully retrieved experiment approvers',
        type: NotificationMessageType.SUCCESS,
        dismissible: true
    },
    FAIL: {
        id: 'getAllExperimentBoundariesFailure',
        header: 'Failed',
        baseContent: 'There was an error in getting your experiment approvers',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const getStaticConfigurationData: FlashbarApiResponse = {
    SUCCESS: {
        id: 'getStaticConfigurationDataSuccess',
        header: 'Success!',
        baseContent: 'Successfully retrieved static configurations',
        type: NotificationMessageType.SUCCESS,
        dismissible: true
    },
    FAIL: {
        id: 'getStaticConfigurationDataFailure',
        header: 'Failed',
        baseContent: 'There was an error in getting your static configuration data',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const updateStaticConfigurationData: FlashbarApiResponse = {
    SUCCESS: {
        id: 'updateStaticConfigurationDataSuccess',
        header: 'Success!',
        baseContent: 'Successfully updated and deployed static configurations! Your configuration will be baking in the next 10 minutes, so DO NOT make another update in that time',
        type: NotificationMessageType.SUCCESS,
        dismissible: true
    },
    FAIL: {
        id: 'updateStaticConfigurationDataFailure',
        header: 'Failed',
        baseContent: 'There was an error in updating your static configuration data',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const getExperimentById: FlashbarApiResponse = {
    SUCCESS: {
        id: 'getExperimentByIdSuccess',
        header: 'Success!',
        baseContent: 'Successfully retrieved experiment!',
        type: NotificationMessageType.FAIL,
        dismissible: true
    },
    FAIL: {
        id: 'getExperimentByIdFail',
        header: 'Failed',
        baseContent: 'There was an error in getting your experiment',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const createExperimentUserRequest: FlashbarApiResponse = {
    SUCCESS: {
        id: 'createExperimentUserRequestSuccess',
        header: 'Success!',
        baseContent: 'Successfully created experiment user request!',
        type: NotificationMessageType.SUCCESS,
        dismissible: true
    },
    FAIL: {
        id: 'createEperimentUserRequestFail',
        header: 'Failed',
        baseContent: 'There was an error in creating your experiment user request',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const getExperimentUserRequest: FlashbarApiResponse = {
    SUCCESS: {
        id: 'getExperimentUserRequestSuccess',
        header: 'Success!',
        baseContent: 'Successfully retrieved experiment user request list!',
        type: NotificationMessageType.SUCCESS,
        dismissible: true
    },
    FAIL: {
        id: 'getEperimentUserRequestFail',
        header: 'Failed',
        baseContent: 'There was an error in getting your experiment user request list',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const getExperimentUserRequestOffers: FlashbarApiResponse = {
    SUCCESS: {
        id: 'getExperimentUserRequestOffersSuccess',
        header: 'Success!',
        baseContent: 'Successfully retrieved experiment user request offers for current request!',
        type: NotificationMessageType.SUCCESS,
        dismissible: true
    },
    FAIL: {
        id: 'getEperimentUserRequestOffersFail',
        header: 'Failed',
        baseContent: 'There was an error in getting your experiment user request offers for current request',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const executeExperimentUserRequest: FlashbarApiResponse = {
    SUCCESS: {
        id: 'executeExperimentUserRequestOffersSuccess',
        header: 'Success!',
        baseContent: 'Successfully executed user request!',
        type: NotificationMessageType.SUCCESS,
        dismissible: true
    },
    FAIL: {
        id: 'executeEperimentUserRequestOffersFail',
        header: 'Failed',
        baseContent: 'There was an error in executing your user request',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const getTransactionalMetrics: Partial<FlashbarApiResponse> = {
    FAIL: {
        id: 'getTransactionalMetricsFail',
        header: 'Failed',
        baseContent: 'There was an error in getting transactional metrics for the experiment',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const getDownstreamMetrics: Partial<FlashbarApiResponse> = {
    FAIL: {
        id: 'getDownstreamMetricsFail',
        header: 'Failed',
        baseContent: 'There was an error in getting downstream metrics for the experiment',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const getPEASelectionResult: Partial<FlashbarApiResponse> = {
    FAIL: {
        id: 'getPEASelectionResultFail',
        header: 'Failed',
        baseContent: 'There was an error in getting the pre-experiment selection analysis results',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const downloadPEASelection: FlashbarApiResponse = {
    SUCCESS: {
        id: 'downloadPEASelectionSuccess',
        header: 'Success!',
        baseContent: 'Successfully downloaded pre-experiment selection analysis file!',
        type: NotificationMessageType.SUCCESS,
        dismissible: true
    },
    FAIL: {
        id: 'downloadPEASelectionFail',
        header: 'Failed',
        baseContent: 'There was an error in downloading the pre-experiment selection analysis file',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const getExperimentLifecycleGraph: Partial<FlashbarApiResponse> = {
    FAIL: {
        id: 'getExperimentLifecycleGraphFail',
        header: 'Failed',
        baseContent: 'There was an error in getting the system level experiment progress graph',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const getExperimentProgressDisplay: Partial<FlashbarApiResponse> = {
    FAIL: {
        id: 'getExperimentProgressDisplayFail',
        header: 'Failed',
        baseContent: 'There was an error in getting the experiment progress display',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const validationComplete: FlashbarApiResponse = {
    SUCCESS: {
        id: 'validationCompleteSuccess',
        header: 'Validation Complete',
        baseContent: 'All ASINs passed validation. Scroll down to the Experiment Selection section to finalize this selection.',
        type: NotificationMessageType.SUCCESS,
        dismissible: true
    },
    FAIL: {
        id: 'validationCompleteWarning',
        header: 'Warning',
        baseContent: 'Some ASINs failed validation. Scroll down to the Experiment Selection section to edit or finalize this selection',
        type: NotificationMessageType.WARNING,
        dismissible: true
    }
};

export const getQuickSightEmbedUrl: Partial<FlashbarApiResponse> = {
    FAIL: {
        id: 'getQuickSightEmbedUrl',
        header: 'Failed',
        baseContent: 'There was an error in getting the experiment\'s QuickSight dashboard',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const submitCustomEvaluationRequest: FlashbarApiResponse = {
    SUCCESS: {
        id: 'submitCustomEvaluationRequestSuccess',
        header: 'Success',
        baseContent: 'The custom evaluation request was submitted successfully. Metrics will be available starting this Sunday.',
        type: NotificationMessageType.SUCCESS,
        dismissible: true
    },
    FAIL: {
        id: 'submitCustomEvaluationRequestFailure',
        header: 'Failed',
        baseContent: 'There was an error in submitting the custom evaluation request.',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const getCustomEvaluationRequests: Partial<FlashbarApiResponse> = {
    FAIL: {
        id: 'getCustomEvaluationRequestsFailure',
        header: 'Failed',
        baseContent: 'There was an error in getting custom evaluation requests.',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const getCustomMetricRequest: Partial<FlashbarApiResponse> = {
    FAIL: {
        id: 'getCustomMetricRequestFailure',
        header: 'Failed',
        baseContent: 'There was an error in getting custom metrics.',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const getRegionDefinition: Partial<FlashbarApiResponse> = {
    FAIL: {
        id: 'getRegionDefinitionsFailure',
        header: 'Failed',
        baseContent: 'There was an error in retrieving all region definitions in the given marketplace.',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const createRegionDefinition: FlashbarApiResponse = {
    SUCCESS: {
        id: 'createRegionDefinitionSuccess',
        header: 'Success!',
        baseContent: 'Successfully uploaded the region definition file. Please refresh the page to see the new definition in the table below.',
        type: NotificationMessageType.SUCCESS,
        dismissible: true
    },
    FAIL: {
        id: 'createRegionDefinitionFailure',
        header: 'Failed',
        baseContent: 'There was an error in uploading the region definition file.',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const updateExperimentBoundaries: Partial<FlashbarApiResponse> = {
    FAIL: {
        id: 'updateExperimentBoundaries',
        header: 'Failed',
        baseContent: 'There was an error in updating the experiment boundaries.',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const scheduleDataCollection: FlashbarApiResponse = {
    SUCCESS: {
        id: 'scheduleDataCollectionSuccess',
        header: 'Success!',
        baseContent: 'Successfully scheduled the data collection.',
        type: NotificationMessageType.SUCCESS,
        dismissible: true
    },
    FAIL: {
        id: 'scheduleDataCollectionFailure',
        header: 'Failed',
        baseContent: 'There was an error in scheduling the data collection.',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};

export const uploadCustomerTriggersFile: Partial<FlashbarApiResponse> = {
    FAIL: {
        id: 'uploadCustomerTriggersFile',
        header: 'Failed',
        baseContent: 'There was an error in uploading customer triggers file.',
        type: NotificationMessageType.FAIL,
        dismissible: true
    }
};
