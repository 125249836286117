export interface GccpMetricRequest {
    useDefaultcustomerTriggers: boolean;
    startDate: string;
    endDate: string;
    customerTriggersFile: File | null;
    useDefaultScienceModel: boolean;
    editing: boolean;
    id?: number;
}

export enum GccpMetricRequestAttributes {
    CUSTORMER_TRIGGERS = 'customerTriggers',
    START_DATE = 'startDate',
    END_DATE = 'endDate',
    CUSTOMER_TRIGGERS_FILE = 'customerTriggersFile',
    SCIENCE_MODEL = 'useDefaultScienceModel',
    EDITING = 'editing'
}
