import React, { FunctionComponent, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Container, Header } from '@amzn/awsui-components-react-v3';

interface Announcement {
    title: string;
    content: string;
}

const useStyles = makeStyles(({
    header: {
        margin: '5px 15px',
        padding: '10px 10px 0 10px'
    },
    newsItem: {
        margin: '15px 15px',
        padding: '0 0 10px 10px'
    }
}));

export const AnnouncementSection: FunctionComponent = () => {
    const classes = useStyles();
    const initialAnnouncementState: Announcement[] = [
        { title: 'ReSES US Launch', content: 'ReSES launched its first Experiment successfully on 1/9/2020' },
        { title: 'ReSES OAM Launch', content: 'ReSES launched its second Experiment successfully on 2/22/2021' },
        { title: 'ReSES EU Launch', content: 'ReSES is set to launch its first experiment in EU in 2021' }
    ];

    const [announcements,] = useState(initialAnnouncementState);

    return (
        <Container header={<Header variant='h1'>Announcements</Header>}>
            {announcements.map((announcement, index) => (
                <div key={index} className={classes.newsItem}>
                    <Box variant={'strong'}>{announcement.title}</Box>
                    <Box>{announcement.content}</Box>
                </div>
            ))}
        </Container>
    );
};
