import { FlashbarMessageDefinition } from '../constants/display/flashbar-messages';
import React from 'react';

const INVALID_ARGUMENTS_CODE = 400;
const UNAUTHORIZED_ERROR_CODE = 403;

/**
 * Handles an error reponse from an API call to the backend.
 * @param error error object from the API call.
 * @param setNotification function to set notification.
 * @param flashbarMessage message to be displayed as part of the error.
 */
export const handleErrorResponse = (error: any, setNotification: Function, flashbarMessage: FlashbarMessageDefinition) => {
    let errorMessage: JSX.Element = <>
        There was an error in our backend service. Please try again after some time. If the issue persists please <a href="https://tiny.amazon.com/785yvnus/tcorpamazissu">cut us a SIM</a>.
    </>;

    if (!error || !error.response) {
        console.error(error);
    } else {
        switch (error.response.status) {
            case INVALID_ARGUMENTS_CODE:
                errorMessage = <>error.response.data.message</>;
                break;
            case UNAUTHORIZED_ERROR_CODE:
                errorMessage = <>You are not authorized to access this data. Please contact the ReSES team to onboard to our platform.</>;
                break;
            default:
                console.error(error.response.data);
        }
    }
    
    setNotification(flashbarMessage, errorMessage);
};