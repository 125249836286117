import React, { Fragment, FunctionComponent, useState } from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { makeStyles } from '@material-ui/core/styles';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { pageMargin, pagePadding } from '../constants/display/page-size';
import { IProps } from '../interfaces/IProps';
import { Realm } from '../api/api-constants';
import { Portal } from '../api/api-constants';
import { createExperimentPage, homePage } from '../pages';
import { AdminHeader, BasicHeader } from '../constants/display/website-headers';
import { UserAttributes } from '../interfaces/UserAttributes';

interface WebsiteHeaderProps extends IProps {
    userAttributes: UserAttributes;
    isAdminPortal: Boolean;
    username: string;
    togglePortal: (newPortal: Portal) => Promise<void>;
    realm: Realm;
    toggleRealm: (newRealm: Realm) => Promise<void>;
}

export const useBasicStyles = makeStyles((theme) => ({
    appBar: {
        transition: 'background-color ease 0.2s'
    },
    brand: {
        fontSize: '2.5rem',
        color: theme.palette.secondary.main,
        gridColumn: '1',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
            color: theme.palette.secondary.main
        },
    },
    user: {
        float: 'left',
        display: 'flex',
        gridColumn: '2',
        margin: '15px'
    },
    rightAlignedComponents: {
        float: 'right',
        display: 'flex',
        gridColumn: '3'
    },
    realmDisplay: {
        color: 'white'
    },
    toggleButtonDisplay: {
        color: 'white',
        fontSize: 10
    },
    toolbar: {
        display: 'grid',
        gridTemplateColumns: 'max-content auto max-content',
        margin: pageMargin,
        padding: '0',
        width: `calc(100% - 2 * ${pagePadding})`
    },
}));

export const useAdminStyles = makeStyles((theme) => ({
    appBar: {
        transition: 'background-color ease 0.2s'
    },
    brand: {
        color: theme.palette.primary.main,
        gridColumn: '1',
        textDecoration: 'none',
    },
    user: {
        float: 'left',
        display: 'flex',
        gridColumn: '2',
        margin: '15px'
    },
    rightAlignedComponents: {
        float: 'right',
        display: 'flex',
        gridColumn: '3'
    },
    realmDisplay: {
        color: 'black'
    },
    toggleButtonDisplay: {

    },
    toolbar: {
        display: 'grid',
        background: theme.palette.secondary.main,
        gridTemplateColumns: 'max-content auto max-content',
        padding: '30',
        width: '100%'
    }
}));

export const WebsiteHeader: FunctionComponent<WebsiteHeaderProps> = (props: WebsiteHeaderProps) => {
    const [menuAnchor, setMenuAnchor] = useState(null);

    const basicStyleClass = useBasicStyles();
    const adminStyleClass = useAdminStyles();

    const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 0 });

    const handleClick = (event: any) => {
        setMenuAnchor(event.currentTarget);
    };

    const handleChange = async(newRealm: Realm) => {
        await props.toggleRealm(newRealm);
        setMenuAnchor(null);
    };

    const handlePortalChange = async(newPortal: Portal) => {
        await props.togglePortal(newPortal);
    };

    let classes = (props.isAdminPortal) ? adminStyleClass : basicStyleClass;
    let headerContents = (props.isAdminPortal) ? AdminHeader : BasicHeader;

    const currentPath = window.location.pathname;
    const realmMenuDisplay = (currentPath !== createExperimentPage.path) ?
        (<Fragment>
            <Button aria-controls='simple-menu' aria-haspopup='true' onClick={handleClick} color='primary'>
                <Typography variant='h6' className={classes.realmDisplay}>
                    {props.realm.toUpperCase()}
                    <KeyboardArrowDownIcon />
                </Typography>
            </Button>
            <Menu
                id='simple-menu'
                anchorEl={menuAnchor}
                keepMounted
                open={Boolean(menuAnchor)}
                onClose={() => handleChange(props.realm)}
            >
                <MenuItem onClick={() => handleChange(Realm.NA)}>NA</MenuItem>
                <MenuItem onClick={() => handleChange(Realm.EU)}>EU</MenuItem>
                <MenuItem onClick={() => handleChange(Realm.FE)}>FE</MenuItem>
            </Menu>
        </Fragment>) :
        (<Typography variant='h6' className={classes.realmDisplay}>{`REALM: ${props.realm.toUpperCase()}`}</Typography>);

    const portalDisplay = (currentPath === homePage.path && props.userAttributes.isAdmin) ? (
        <Button onClick={() => handlePortalChange(headerContents.targetPortal)} className={classes.toggleButtonDisplay}>
            {headerContents.targetName}
        </Button>) : null;

    const header = (
        <>
            <AppBar data-testid={props.testId} className={classes.appBar} position="sticky" id='website-header'>
                <Toolbar className={classes.toolbar}>
                    <Link to={homePage.path}>
                        <h3 className={classes.brand}> {headerContents.title} </h3>
                    </Link>
                    <Typography className={classes.user}>
                        <span className={classes.user}>{`User: ${props.userAttributes.name ? props.userAttributes.name : 'Unidentified'} (${props.username})`}</span>
                    </Typography>
                    <span className={classes.rightAlignedComponents}>
                        {realmMenuDisplay}
                        {portalDisplay}
                    </span>
                </Toolbar>
            </AppBar>
        </>
    );

    const elevatedHeader = React.cloneElement(header, { elevation: trigger ? 4 : 0 });

    return (
        <Fragment>
            {elevatedHeader}
        </Fragment>
    );
};
