import React from 'react';
import { Box, TableProps } from '@amzn/awsui-components-react-v3';
import { BoundarySet } from '../../interfaces/BoundarySet';
import { ClickableBackdrop } from '../../common/ClickableBackdrop';
import { EMBED_BOUNDARIES_MAP, EXCLUDES, INCLUDES, RABL_BASE_URLS } from '../../api/rabl-constants';
import { Realm } from '../../api/api-constants';
import { ColumnOption } from '../../common/DisplayTable';

export const getColumnDefinitions = (boundarySetColumns: string[]): Array<TableProps.ColumnDefinition<BoundarySet>> => {
    const columnDefinitions: Array<TableProps.ColumnDefinition<BoundarySet>> = [
        {
            id: 'Treatment Region',
            header: 'Treatment Region',
            cell: (item: BoundarySet) => {
                const boundaryDisplayNames: string[] = Array.from(item.boundaries.keys()).map((boundaryName) => {
                    return `${boundaryName}: ${item.boundaries.get(boundaryName)}`; // <Boundary Name>: <Boundary Description>
                });

                const boundaryIds = Array.from(item.rmsBoundaries!.keys()).map((boundaryName) => {
                    return item.rmsBoundaries!.get(boundaryName)!.rablId;
                });

                return (<ClickableBackdrop name={'View Boundaries'} content={(
                    <div>
                        <div style={{
                            maxHeight: '500px',
                            width: '30%',
                            float: 'left',
                            overflow: 'hidden',
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '5px 5px 0px 10px'
                        }}>
                            <div data-testid={'display-wrapper'}>
                                <div style={{ textAlign: 'center' }}>
                                    <Box variant="awsui-key-label" >
                                        <Box variant="strong"><u>Treatment Boundaries</u></Box>
                                    </Box>
                                </div>
                                <div style={{ maxHeight: '450px', overflowY: 'auto', scrollPadding: '25px' }}>
                                    <Box variant="p">
                                        {boundaryDisplayNames.join(', ')}
                                    </Box>
                                </div>
                            </div>
                        </div>
                        <iframe
                            src={`${RABL_BASE_URLS.getEndpoint(Realm.NA) + EMBED_BOUNDARIES_MAP}?${INCLUDES}=${[]}&${EXCLUDES}=${boundaryIds}`}
                            title='Region map'
                            width='100%'
                            height='500'
                            frameBorder='0'
                            scrolling='no'
                            style={{ width: '70%', float: 'left' }}
                        />
                    </div>
                )}/>);
            },
            width: 200
        }
    ];

    boundarySetColumns.forEach((columnName: string) => {
        columnDefinitions.push({
            id: columnName,
            header: columnName,
            cell: (item: BoundarySet) => <div>{item.attributes.get(columnName)}</div>,
        });
    });

    return columnDefinitions;
};

export const getColumnOptions = (boundarySetColumns: string[]): ColumnOption[] => {
    const columnOptions: Array<ColumnOption> = boundarySetColumns.map((columnName: string) => {
        return {
            id: columnName,
            label: columnName,
            editable: true,
            visible: true,
        };
    });

    return [
        {
            id: 'Treatment Region',
            label: 'Treatment Region',
            visible: true,
        },
        ...columnOptions,
    ];
};

export const pageSizeOptions = [
    { value: 10, label: '10 options' },
    { value: 15, label: '15 options' },
    { value: 20, label: '20 options' }];
