import { ExperimentStep } from '../enums/CommonTypes';
import { DisplayAttribute } from './DisplayAttribute';
import { TagDto } from '../api/experiment-service/lambda-model-types';
import { DateRangePickerProps } from '@amzn/awsui-components-react-v3/polaris/date-range-picker/interfaces';
import { convertToAbsoluteValue } from '../common/DateRangeSelector';
import { convertUTCToLocalDate } from '../utils/date-utils';

export interface LimestoneExperimentMetadata extends ExperimentStep {
    marketplace: DisplayAttribute;
    title: DisplayAttribute;
    description: DisplayAttribute;
    productFamily: DisplayAttribute;
    primaryBusinessGroup: DisplayAttribute;
    secondaryBusinessGroups: DisplayAttribute;
    primaryOwner: DisplayAttribute;
    secondaryOwners: DisplayAttribute;
    observers: DisplayAttribute;
    tags: DisplayAttribute;
    rablRegionId: DisplayAttribute;
    regionDefinitionType: DisplayAttribute;
    discriminator: DisplayAttribute;
    startDate: DisplayAttribute;
    endDate: DisplayAttribute;
    regionCreationStatus: DisplayAttribute;
    businessApprover: DisplayAttribute;
    experimentType: DisplayAttribute;
    linkedMultiTreatmentExperimentIds: DisplayAttribute;
    bundleEnabled: DisplayAttribute;
    customMetrics: DisplayAttribute;
    dealsEnabled: DisplayAttribute;
    treatmentType: DisplayAttribute;
}

export interface LimestoneExperimentSelection extends ExperimentStep {
    offersFile: DisplayAttribute;

}

export interface LimestoneExperimentStatus extends ExperimentStep {
    currentStatus: DisplayAttribute;
    lastRefreshed: DisplayAttribute;
}

export interface LimestoneExperimentBoundaries extends ExperimentStep {
    treatmentBoundaries: DisplayAttribute;
}

export interface LimestoneExperiment {
    experimentId: string;
    experimentIntegerId?: string;
    metadata: LimestoneExperimentMetadata;
    productSelection: LimestoneExperimentSelection;
    regionSelection: LimestoneExperimentBoundaries;
    currentStatus: LimestoneExperimentStatus;
    creationDate?: string;
    lastUpdatedDate?: string;
    selectionFinalized?: boolean;
    multitreatmentExperiments?: string[];
    comment?: string;
    businessRecommendation?: string;
    customerDecision?: string;
}

export const isTestExperiment = (experiment: LimestoneExperiment) =>
    (experiment.metadata.tags.payloadValue).some((tag: TagDto) => tag.tag.toLowerCase() === 'test');

/**
 * Filter a list of experiments by start date and a date range. 
 * 
 * @param experiments experiment to filter
 * @param dateRange {@link DateRangePickerProps.Value} or null value
 * @return a list of experiments of which start date is within the given range
 */
export const filterExperimentsByDate = (experiments: LimestoneExperiment[], dateRange: DateRangePickerProps.Value | null) => {
    if (dateRange == null) {
        return experiments;
    } else {
        const absoluteRange = convertToAbsoluteValue(dateRange);
        return experiments.filter((experiment) =>
            (new Date(experiment.metadata.startDate.payloadValue).getTime() - convertUTCToLocalDate(new Date(absoluteRange.startDate)).getTime() >= 0)
            && (new Date(experiment.metadata.startDate.payloadValue).getTime() - convertUTCToLocalDate(new Date(absoluteRange.endDate)).getTime() <= 0));
    }
};
