import React, { FunctionComponent } from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { PageSection } from '../../layout/PageSection';
import { homePage } from '..';
import { pageMargin } from '../../constants/display/page-size';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'grid',
        gridTemplateColumns: 'min-content min-content',
        margin: pageMargin,
        padding: '50px 0 100px 0',
        width: 'min-content'
    },
    description: {
        opacity: 1,
    },
    '@keyframes descriptionFadeIn': {
        from: { opacity: 0 },
        to: { opacity: 1 }
    },
    introContainer: {
        color: 'white',
        margin: 'auto 0',
        textAlign: 'center',
    },
    title: {
        whiteSpace: 'nowrap',
    },
    titleName: {
        opacity: 1,
        color: theme.palette.secondary.main,
        width: 'max-content',
    },
    '@keyframes titleMeetFadeIn': {
        from: { opacity: 0 },
        to: { opacity: 1 }
    },
    '@keyframes titleNameFadeIn': {
        from: { opacity: 0 },
        to: { opacity: 1 }
    }
}));

const getBackgroundColor = (theme: Theme): string => theme.palette.primary.main;

/**
 * The Header Section of the Home Page
 */
export const HomePageHeader: FunctionComponent = () => {
    const classes = useStyles();

    return (
        <PageSection testId={`${homePage.testId}-header`} backgroundColor={getBackgroundColor} className={classes.container}>
            <div className={classes.introContainer}>
                <h2 className={classes.titleName}>Regional Selection Experimentation Service</h2>
                <h5 className={classes.description}>A self-service system to regionally experiment with your selection.</h5>
            </div>
        </PageSection>
    );
};
