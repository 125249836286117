/**
 * Operation type of creating region definition.
 */
export enum CreateRegionDefinitionOperationType {
    UPLOAD_TO_DATABASE = 0,
    CREATE_RABL_REGION = 1,
}

/**
 * Configuration for region definition creation operation.
 */
export interface CreateRegionDefinitionOperationConfig {
    /**
     * Name displayed as the drop-down select option.
     */
    name: string,
}

/**
 * Map for region definition operation config.
 * Key is the {@link CreateRegionDefinitionOperationType} and value is the {@link CreateRegionDefinitionOperationConfig}
 */
export const REGION_DEFINITION_OPERATION_CONFIG_MAP = new Map<CreateRegionDefinitionOperationType, CreateRegionDefinitionOperationConfig>([
    [CreateRegionDefinitionOperationType.CREATE_RABL_REGION, {
        name: 'Create RABL Boundaries',
    }],
    [CreateRegionDefinitionOperationType.UPLOAD_TO_DATABASE, {
        name: 'Only Upload to Database',
    }],
]);

/* Default region definition operation type will be shown as the default value in select field. */
export const DEFAULT_REGION_DEFINITION_CREATION_OPERATION = CreateRegionDefinitionOperationType.CREATE_RABL_REGION;
