import { Boundary } from '../api/region-service/api-interfaces';

export interface BoundarySet {
    boundaries: Map<string, string>;
    attributes: Map<string, string>;
    isManuallyUploaded: boolean;
    rmsBoundaries?: Map<string, Boundary>
}

export const convertBoundarySetToObject = (boundarySet: BoundarySet) : any => {
    const boundaries = Object.fromEntries(boundarySet.boundaries);
    const attributes = Object.fromEntries(boundarySet.attributes);
    return {
        boundaries,
        attributes,
        isManuallyUploaded: boundarySet.isManuallyUploaded
    };
};
