import * as LambdaModel from '../lambda-model-types';
import { LimestoneExperimentMetadata } from '../../../interfaces/LimestoneExperiment';

const DUMMY_REGION_ID = 'dummy_region_id';

export const convertMetadataToPayload = (metadata: LimestoneExperimentMetadata): LambdaModel.CreateExperimentPayload => {
    return {
        experimentMetadata: {
            marketplaceId: metadata.marketplace.payloadValue,
            title: metadata.title.payloadValue,
            description: metadata.description.payloadValue,
            productFamily: metadata.productFamily.payloadValue,
            ownersDto: [...metadata.secondaryOwners.payloadValue, metadata.primaryOwner.payloadValue],
            observersDto: metadata.observers.payloadValue,
            tagsDto: metadata.tags.payloadValue,
            regionId: metadata.rablRegionId.payloadValue || DUMMY_REGION_ID,
            listerDiscriminator: metadata.discriminator.payloadValue,
            experimentStartDate: metadata.startDate.payloadValue,
            experimentEndDate: metadata.endDate.payloadValue,
            businessApprover: metadata.businessApprover.payloadValue,
            experimentType: metadata.experimentType.payloadValue,
            linkedMultiTreatmentExperimentIds: metadata.linkedMultiTreatmentExperimentIds.payloadValue,
            bundleEnabled: metadata.bundleEnabled.payloadValue,
            customMetrics: metadata.customMetrics.payloadValue,
            boundaryType: metadata.regionDefinitionType.payloadValue,
            dealsEnabled: metadata.dealsEnabled.payloadValue,
            treatmentType: metadata.treatmentType.payloadValue,
        }
    };
};
