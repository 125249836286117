import React, { FunctionComponent } from 'react';
import { Portal } from '../../api/api-constants';
import { QuickLinksSection } from './QuickLinksSection';
import { AnnouncementSection } from './AnnouncementSection';
import { QuickStartSection } from './QuickStartSection';
import { RealmDisplaySection } from './RealmDisplaySection';
import { adminQuickLinkCardItems, basicQuickLinkCardItems } from '../../constants/display/quicklinkcardcontent';
import { PageProps } from '../../interfaces/IProps';


const HomePage: FunctionComponent<PageProps> = ({ realm, portal }) => {
    const cardItems = portal === Portal.ADMIN ? adminQuickLinkCardItems : basicQuickLinkCardItems;
    return (
        <>
            <QuickStartSection />
            <RealmDisplaySection realm={realm} />
            <QuickLinksSection cardItems={cardItems}/>
            <AnnouncementSection />
        </>
    );
};

export default HomePage;
