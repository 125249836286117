import React, { Component } from 'react';
import { DataTableGroupDefinition } from '../../common/DataTableGroup';
import CdcApiHandlerImpl from '../../api/data-collection/handler/cdc-api-handler-impl';
import { CDC_DATA_NOT_READY_CODE, Realm } from '../../api/api-constants';
import { MetricsTable } from '../../api/data-collection/lambda-model-types';
import { handleErrorResponse } from '../../utils/error-handler-utils';
import * as TransactionalMetricsAdaptor from '../../api/data-collection/adaptors/transactional-metrics-adaptor';
import * as NOTIFICATION_MESSAGES from '../../constants/display/flashbar-messages';
import { LimestoneExperiment } from '../../interfaces/LimestoneExperiment';
import { CdcApiHandler } from '../../api/data-collection/handler/cdc-api-handler';
import { Button, FormField, NonCancelableCustomEvent, SelectProps } from '@amzn/awsui-components-react-v3';
import { GccpMetricRequest } from '../../interfaces/GccpMetricRequest';
import { createEmptyGccpMtericsRequest } from '../../utils/form-utils';
import { GccpMetricRequestField } from '../../form/attributes/GccpMetricRequestField';
import { MetricsPageDisplay } from './MetricsPageDisplay';
import { UserInputModal } from '../../common/UserInputModal';
import { SubmitGccpMetricRequestModalAttributes } from '../../constants/display/modal-constants';
import { constructDateString } from '../../utils/date-utils';
import { AwsServicesApiHandler } from '../../api/aws-services/aws-services-handler';
import AwsServicesApiHandlerImpl from '../../api/aws-services/aws-services-handler-impl';
import { decryptCustomerId } from '@amzn/amazon-id';
import { GCCP_METRICS_REQUESTS_DESCRIPTION } from '../../constants/display/string-constants';

export interface DownstreamMetricsSectionProps {
    realm: Realm;
    experiment: LimestoneExperiment;
    addMetricElement?: JSX.Element;
    setNotification: Function;
}

export interface DownstreamMetricsSectionState {
    selectedDay: string;
    experiment: LimestoneExperiment;
    availableDays: string[];
    tableGroups: DataTableGroupDefinition[];
    showSpinner: boolean;
    buttonLoadingState: boolean;
    newGccpMetricRequests: GccpMetricRequest[];
    showSubmitGccpMetricRequestModal: boolean;
    submittingGccpMetricRequest: boolean;
    gccpMetricRequestToSubmitIndex: number;
}


export class DownstreamMetricsSection extends Component<DownstreamMetricsSectionProps, DownstreamMetricsSectionState> {
    public dataCollectionAPI: CdcApiHandler;
    public awsServicesHandler: AwsServicesApiHandler;

    private gccpMetricRequestId = 0;

    constructor(props: DownstreamMetricsSectionProps) {
        super(props);
        this.state = {
            experiment: props.experiment,
            selectedDay: '',
            availableDays: [],
            tableGroups: [],
            showSpinner: false,
            buttonLoadingState: false,
            newGccpMetricRequests: [],
            showSubmitGccpMetricRequestModal: false,
            submittingGccpMetricRequest: false,
            gccpMetricRequestToSubmitIndex: 0,
        };

        this.dataCollectionAPI = new CdcApiHandlerImpl(props.realm);
        this.awsServicesHandler = new AwsServicesApiHandlerImpl(props.realm);
    }

    componentDidMount = async() => {
        await this.fetchAvailableDates();
    }
    
    fetchAvailableDates = async() => {
        this.setState({ showSpinner: true });
        await this.dataCollectionAPI.getCompletedCollectionDates(this.state.experiment.experimentId, 'DSE_FINAL_DOWNSTREAM_METRICS')
            .then((dates: string[]) => {
                this.setState({ availableDays: dates });
            })
            .catch((error: any) => {
                handleErrorResponse(error, this.props.setNotification, NOTIFICATION_MESSAGES.getDownstreamMetrics.FAIL!);
                this.setState({ tableGroups: [] });
            })
            .finally(() => this.setState({ showSpinner: false }));
    }

    fetchDownstreamMetrics = async() => {
        this.setState({ showSpinner: true });
        await this.dataCollectionAPI.getFinalDownstreamMetrics(this.state.experiment.experimentId, this.state.selectedDay)
            .then((table: MetricsTable) => {
                this.setState({ tableGroups: TransactionalMetricsAdaptor.convertMetricsTableToDataTableGroup(table, 'Downstream Metrics') });
            })
            .catch((error: any) => {
                if (!(error.response && error.response.status === CDC_DATA_NOT_READY_CODE)) {
                    handleErrorResponse(error, this.props.setNotification, NOTIFICATION_MESSAGES.getDownstreamMetrics.FAIL!);
                }

                this.setState({ tableGroups: [] });
            })
            .finally(() => this.setState({ showSpinner: false }));
    }

    onDateChange = async(event: NonCancelableCustomEvent<SelectProps.ChangeDetail>) => {
        await this.setState({ selectedDay: event.detail.selectedOption.value! });
        await this.fetchDownstreamMetrics();
    }

    addGccpMetricRequest = () => {
        const newGccpMtericsRequest = createEmptyGccpMtericsRequest();
        newGccpMtericsRequest.id = this.gccpMetricRequestId++;
        this.setState(({ newGccpMetricRequests }) => ({
            newGccpMetricRequests: [
                ...newGccpMetricRequests,
                newGccpMtericsRequest
            ]
        }));
    }

    updateGccpMetricRequest = (gccpMetricRequest: GccpMetricRequest, index: number) => {
        this.setState(({ newGccpMetricRequests }) => ({
            newGccpMetricRequests: [
                ...newGccpMetricRequests.slice(0, index),
                {
                    ...gccpMetricRequest
                },
                ...newGccpMetricRequests.slice(index + 1)
            ]
        }));
    }

    deleteGccpMetricRequest = (index: number) => {
        this.setState(({ newGccpMetricRequests }) => ({
            newGccpMetricRequests: [
                ...newGccpMetricRequests.slice(0, index),
                ...newGccpMetricRequests.slice(index + 1)
            ]
        }));
    }

    saveGccpMetricRequest = (index: number) => {
        this.setState(({
            showSubmitGccpMetricRequestModal: true,
            gccpMetricRequestToSubmitIndex: index,
        }));
    }

    submitGccpMetricRequest = async(index: number) => {
        this.setState(({ submittingGccpMetricRequest: true }));

        const gccpMetricRequest = this.state.newGccpMetricRequests[index];
        const experimentId = this.state.experiment.experimentId;
        const marketplaceId = this.state.experiment.metadata.marketplace.payloadValue;
        const decryptedMarketplaceId = Number(decryptCustomerId(marketplaceId));
        // Use the next day of today as collection date
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        const collectionDates = [constructDateString(tomorrow)];
        let dataType;
        if (gccpMetricRequest.useDefaultScienceModel) {
            dataType = 'DSE_FINAL_DOWNSTREAM_METRICS';
        } else {
            dataType = 'DSE_REGIONALLY_AGGREGATED_METRICS';
        }

        let noUploadError = true;
        const customerTriggersFile = gccpMetricRequest.customerTriggersFile;

        if (customerTriggersFile !== null) {
            await this.awsServicesHandler.uploadCustomerTriggersFile(customerTriggersFile, experimentId, decryptedMarketplaceId, collectionDates[0])
                .catch((error: any) => {
                    noUploadError = false;
                    handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.uploadCustomerTriggersFile.FAIL!);
                });
        }

        if (noUploadError) { 
            await this.dataCollectionAPI.scheduleDataCollection(
                experimentId,
                decryptedMarketplaceId,
                dataType,
                collectionDates,
                gccpMetricRequest.startDate,
                gccpMetricRequest.endDate,
                this.state.experiment.metadata.regionDefinitionType.payloadValue,
                !gccpMetricRequest.useDefaultcustomerTriggers,
            ).then(() => {
                this.props.setNotification!(NOTIFICATION_MESSAGES.scheduleDataCollection.SUCCESS);
                this.deleteGccpMetricRequest(index);
            }).catch((error: any) => {
                handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.scheduleDataCollection.FAIL);
            });
        }

        this.setState({
            submittingGccpMetricRequest: false,
            showSubmitGccpMetricRequestModal: false
        });
    }

    render() {
        const addGccpMetricRequestForm = (
            <FormField 
                data-testid={'add-gccp-metrics-section'}
                description={GCCP_METRICS_REQUESTS_DESCRIPTION}
            >
                {this.state.newGccpMetricRequests.map((gccpMetricRequest, index) =>
                    <GccpMetricRequestField
                        data-testid='gccp-metric-request'
                        key={gccpMetricRequest.id}
                        gccpMetricRequest={gccpMetricRequest}
                        index={index}
                        updateFormState={this.updateGccpMetricRequest}
                        deleteGccpMetricRequest={this.deleteGccpMetricRequest}
                        saveGccpMetricRequest={this.saveGccpMetricRequest}
                        experiment={this.state.experiment}
                    />
                )}
                <Button 
                    data-testid='add-gccp-metric-request-button'
                    onClick={this.addGccpMetricRequest}
                    iconName='add-plus'
                >
                    GCCP Metric Request
                </Button>
            </FormField>
        );

        return (
            <>
                <MetricsPageDisplay 
                    id='downstream-metrics'
                    experiment={this.props.experiment}
                    tableGroups={this.state.tableGroups}
                    tableKey='Downstream Metrics'
                    dataNotReadyWarning={`Downstream Metrics are not ready for this experiment: \
                        ${this.state.experiment.metadata.title.displayValue} (ID: ${this.state.experiment.experimentIntegerId})`}
                    selectedDay={this.state.selectedDay}
                    availableDays={this.state.availableDays}
                    showSpinner={this.state.showSpinner}
                    onDateChangeFunction={this.onDateChange}
                />
                <>
                    {this.state.showSubmitGccpMetricRequestModal && <UserInputModal
                        visible={this.state.showSubmitGccpMetricRequestModal}
                        buttonHandlers={{
                            dismiss: () => {
                                this.updateGccpMetricRequest({
                                    ...this.state.newGccpMetricRequests[this.state.gccpMetricRequestToSubmitIndex],
                                    editing: true
                                }, this.state.gccpMetricRequestToSubmitIndex);
                                this.setState({ showSubmitGccpMetricRequestModal: false });
                            },
                            submit: () => this.submitGccpMetricRequest(this.state.gccpMetricRequestToSubmitIndex)
                        }}
                        {...SubmitGccpMetricRequestModalAttributes}
                        submitButtonLoading={this.state.submittingGccpMetricRequest}
                    />}
                    {addGccpMetricRequestForm}
                </>
            </>
        );
    }
};
