import React from 'react';

import { Box } from '@amzn/awsui-components-react-v3';

export const AttributeLabels = {
    MARKETPLACE: 'Marketplace',
    TITLE: 'Title',
    DESCRIPTION: 'Description',
    PRODUCT_FAMILY: 'Product Family',
    PRIMARY_BUSINESS_GROUP: 'Primary Business Group',
    SECONDARY_BUSINESS_GROUPS: 'Secondary Business Groups',
    PRIMARY_OWNER: 'Primary Owner',
    SECONDARY_OWNERS: 'Secondary Owners',
    OBSERVERS: 'Observers',
    TAGS: 'Tags',
    RABL_REGION_ID: 'RABL Region ID',
    DISCRIMINATOR: 'Discriminator',
    START_DATE: 'Start Date',
    END_DATE: 'End Date',
    OFFERS_FILE: 'Product Selection File',
    OFFERS_LIST: 'Product Selection List',
    TREATMENT_BOUNDARIES: 'Treatment Boundaries',
    REGION_CREATION_STATUS: 'Treatment Region Status',
    CURRENT_STATUS: 'Current Status',
    LAST_REFRESHED: 'Last Refreshed',
    BUSINESS_APPROVER: 'L8 Contact',
    REJECTION_REASON: 'Rejection Reason',
    BOUNDARIES: 'Boundaries',
    EXPERIMENT_TYPE: 'Regional Experiment Type',
    LINKED_MULTI_TREATMENT_EXPERIMENT_IDS: 'Linked Multi-treatment Experiments',
    BUNDLE_ENABLED: 'Enable Bundle Workflow',
    ADDITIONAL_CUSTOM_METRICS: 'Additional Custom Metrics',
    REGION_DEFINITION_TYPE: 'Region Definition Type',
    DEALS_ENABLED: 'Unregionalize Deal ASINs',
    COMMENT: 'Comment',
    CUSTOMER_TRIGGERS_FILE: 'Customer Triggers File',
    CUSTOMER_TRIGGERS_TYPE: 'Customer Triggers Type',
    TREATMENT_TYPE: 'Treatment Type',
    CUSTOMER_DECISION: 'Launch Decision',
    BUSINESS_RECOMMENDATION: 'Business Recommendation',
};

export const TableHeaders = {
    MY_EXPERIMENTS: 'My Experiments',
    MY_EXPERIMENTS_TEST: 'My Test Experiments',
    APPROVERS_QUEUE: 'Approvers Queue',
    ALL_EXPERIMENTS: 'All Running & Upcoming Experiments',
    ALL_EXPERIMENTS_TEST: 'All Running & Upcoming Test Experiments',
    BOUNDARY_OPTIONS: 'Boundary Set Options',
    REGION_SIMULATION_RESULT: 'Region Simulation Results',
    MANUALLY_UPLOADED_BOUNDARIES: 'Manually Uploaded Boundaries',
    AVAILABLE_REGION_DEFINITION_TYPES: 'Available Region Definition Types',
};

export const ActionRequiredMessages = {
    CONFIRM_SELECTION_PASS: 'Selection validation is complete, please scroll down to the experiment selection section to finalize your selection.',
    CONFIRM_SELECTION_WARNINGS: 'Selection validation is complete with some warnings, please scroll down to the experiment selection section to finalize your selection.',
    CONFIRM_TREATMENT_REGION: 'Please scroll down to region selection section to finalize your treatment region.',
    CONFIRM_SELECTION_ANALYSIS: 'Please scroll down to the selection analysis section to view the selection analysis results.'
};

export const DataTableNames = {
    DOWNSTREAM_METRICS: 'Downstream Metrics'
};

export const UserAttributeLabels = {
    GIVEN_NAME: 'given_name',
    LAST_NAME: 'family_name',
    USER_ROLES: 'custom:LDAP_GROUP',
    ADMIN_ROLE: 'admin',
    CREATOR_ROLE: 'creator',
    DEV_ROLE: 'dev'
};

export const LIMESTONE_ADMIN_ALIAS = 'limestone-dev';
export const TEST_USER_ALIAS = 'svc-limestone-test-user';

export const CUSTOM_EVALUATION_REQUESTS_DESCRIPTION: React.ReactElement = <>
    <Box variant="p">
        Metrics will be generated by default for Restricted Selection, Nonrestricted Selection,
        Substitute Selection, Consumables, Hardlines, Softlines, and all of Amazon.
        Upload any other sets of selection you would like to generate transactional
        metrics on. These sets of selection will only be used to collect metrics, and will not
        be regionalized as part of the experiment.
    </Box>
    <Box variant="p">Format the selection file as a single list of ASINs in CSV format. <a href='/files/template_file.csv' download>Example File</a></Box>
</>;

export const GCCP_METRICS_REQUESTS_DESCRIPTION: React.ReactElement = <>
    <Box variant="p">
        GCCP metrics will be ready within 3 days and available on this page.
    </Box>
    <Box variant="p">Format the customer triggers file in CSV format. <a href='/files/sample-triggered-customers.csv' download>Example File</a></Box>
</>;
