import { EndpointMap } from '../endpoint-map';

export const CDC_API_ENDPOINTS = new EndpointMap({
    beta_na: 'https://ihfjcgyl73.execute-api.us-east-1.amazonaws.com/prod-us-east-1',
    gamma_na: 'https://ihfjcgyl73.execute-api.us-east-1.amazonaws.com/prod-us-east-1',
    prod_na: 'https://ihfjcgyl73.execute-api.us-east-1.amazonaws.com/prod-us-east-1',
    beta_eu: 'https://nuh9xy8pv6.execute-api.eu-west-1.amazonaws.com/prod-eu-west-1',
    prod_eu: 'https://nuh9xy8pv6.execute-api.eu-west-1.amazonaws.com/prod-eu-west-1',
    beta_fe: 'https://og64zuyvf8.execute-api.us-west-2.amazonaws.com/beta-us-west-2',
    prod_fe: 'https://rbcbc91nv8.execute-api.us-west-2.amazonaws.com/prod-us-west-2',
    dev_na: 'https://5zlywcl5xl.execute-api.us-east-1.amazonaws.com/dev-us-east-1',
    dev_eu: 'https://5zlywcl5xl.execute-api.us-east-1.amazonaws.com/dev-us-east-1',
    dev_fe: 'https://5zlywcl5xl.execute-api.us-east-1.amazonaws.com/dev-us-east-1',
});
