import { Portal } from '../../api/api-constants';
	 
export const AdminHeader = {
    title: 'ReSES Administrator System',
    targetPortal: Portal.BASIC,
    targetName: 'Basic Portal',
};

export const BasicHeader = {
    title: 'ReSES',
    targetPortal: Portal.ADMIN,
    targetName: 'Admin Portal',
};