import React, { FunctionComponent } from 'react';
import { Button, ButtonProps } from '@amzn/awsui-components-react-v3';
import { ActionType, PermissionsMap, UserAccessLevel } from '../enums/PermissionsModel';
import { isActionAuthorizedForUser } from '../utils/auth-utils';
import { IProps } from '../interfaces/IProps';

export interface PermissionControlledButtonProps extends IProps {
    userAccessLevels: Set<UserAccessLevel>;
    pagePermissionsMap: PermissionsMap;
    actionType: ActionType;
    buttonProps: ButtonProps;
    hideIfNotAuthorized?: boolean;
}

export const PermissionControlledButton: FunctionComponent<PermissionControlledButtonProps> = ({
    testId,
    children,
    actionType,
    buttonProps,
    userAccessLevels,
    pagePermissionsMap,
    hideIfNotAuthorized = false,
}) => {
    const hasPermission = isActionAuthorizedForUser(userAccessLevels, actionType, pagePermissionsMap);
    if (hideIfNotAuthorized && !hasPermission) {
        return null;
    }

    return (
        <Button
            {...buttonProps}
            data-testid={testId}
            disabled={buttonProps.disabled || !hasPermission}
        >{children}</Button>
    );
};
