import React, { FunctionComponent } from 'react';
import { Step, StepLabel, Stepper } from '@material-ui/core';
import CheckCircle from '@material-ui/icons/CheckCircle';
import HourglassEmpty from '@material-ui/icons/HourglassEmpty';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import { IProps } from '../interfaces/IProps';
import { Box } from '@amzn/awsui-components-react-v3';

export interface ProgressStepConfiguration {
    label: string;
    executionTimeInMins: number;
    description?: string;
}

export interface ProgressStepperProps extends IProps {
    steps: ProgressStepConfiguration[],
    activeStepIndex: number;
}

export const ProgressStepper: FunctionComponent<ProgressStepperProps> = ({
    steps,
    testId,
    activeStepIndex
}) => {

    const getStepperIcon = (index: number): JSX.Element => {
        if (index < activeStepIndex) {
            return <CheckCircle fontSize='large' />;
        }

        if (index > activeStepIndex) {
            return <FiberManualRecord fontSize='large' />;
        }

        return <HourglassEmpty fontSize='large' />;
    };

    const getExecutionTimeDisplay = (executionTimeInMins: number): string => {
        if (executionTimeInMins < 60) {
            return `${executionTimeInMins} mins`;
        }

        const executionTimeInHours = Math.ceil(executionTimeInMins / 60);
        if (executionTimeInHours < 24) {
            return `${executionTimeInHours} hour(s)`;
        }

        const executionTimeInDays = Math.ceil(executionTimeInHours / 24);
        if (executionTimeInDays < 7) {
            return `${executionTimeInDays} day(s)`;
        }

        return `${Math.round((executionTimeInDays / 7) * 2)/ 2} week(s)`;
    };

    return (
        <Box textAlign={'center'} data-testid={testId}>
            <Stepper activeStep={activeStepIndex} alternativeLabel>
                {steps.map((step, idx) => (
                    <Step key={step.label}>
                        <StepLabel StepIconComponent={() => getStepperIcon(idx)}>
                            <Box variant={'strong'}>{step.label}</Box>
                            {idx === activeStepIndex &&
                                <Box>{`Duration: ${getExecutionTimeDisplay(step.executionTimeInMins)}`}</Box>
                            }
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
};
