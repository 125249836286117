import React from 'react';
import { Multiselect, MultiselectProps, SelectProps } from '@amzn/awsui-components-react-v3';
import { ExperimentAttribute, ExperimentAttributeConfig, ExperimentAttributeProps } from '../ExperimentAttribute';
import { IValidationRules } from '../../validation/validation-rules';

export interface MultiSelectFieldConfig extends ExperimentAttributeConfig, MultiselectProps {}

/**
 * Field to allow use select multiple options from a given list of options.
 * Reference: https://polaris.a2z.com/components/awsui-multiselect/?example=default
 */
export abstract class MultiSelectField<T extends  ExperimentAttributeProps> extends ExperimentAttribute<T> {
    protected displayConfig!: MultiSelectFieldConfig;

    parseValueFromEvent = (event: CustomEvent<MultiselectProps.MultiselectChangeDetail>): readonly SelectProps.Option[] => event.detail.selectedOptions;

    getSelectedOptions = (): readonly SelectProps.Option[] => this.displayConfig.selectedOptions!;

    validate = (options: any[], validationRules: IValidationRules) => {
        let isValid = true;
        const errors: string[] = [];

        if (validationRules) {
            if (validationRules.required && (!options || options.length === 0)) {
                isValid = false;
                errors.push('Field is required');
            }

            if (validationRules.maxOptions && (options && options.length > validationRules.maxOptions)) {
                isValid = false;
                errors.push('Exceeds max number of allowed options');
            }
        }

        const errorText = errors.length !== 0 ? errors.join(' and ') : undefined;
        return { isValid, errorText };
    }

    setValue = async(newValue: SelectProps.Option[]) => {
        const newDisplayValue = newValue ? newValue.map((option: SelectProps.Option) => option.label).join(', ') : '';
        const { isValid, errorText } = this.validate(newValue, this.validationRules);

        this.displayConfig = {
            ...this.displayConfig,
            touched: true,
            selectedOptions: newValue,
            invalid: this.displayConfig.touched && !isValid,
            errorText,
        };

        await new Promise((resolve) => this.setState({ displayValue: newDisplayValue, validity: isValid }, () => resolve(newValue)));
    }

    setValueByIds = async(selectedIds: string[]) => {
        const { isValid, errorText } = this.validate(selectedIds, this.validationRules);

        const selectedOptions = selectedIds ? selectedIds.map((selectedId) => {
            return (this.displayConfig.options as SelectProps.Option[])!.find((option: SelectProps.Option) => option.value === selectedId)!;
        }) : [];

        const newDisplayValue = selectedOptions.map((option) => option!.label).join(', ');

        this.displayConfig = {
            ...this.displayConfig,
            touched: true,
            selectedOptions,
            invalid: this.displayConfig.touched && !isValid,
            errorText,
        };

        await new Promise((resolve) => this.setState({ displayValue: newDisplayValue, validity: isValid }, () => resolve(selectedIds)));
    }

    getPolarisElement = () => <Multiselect data-testid={this.props['data-testid']} {...this.displayConfig}/>
}
