import React from 'react';
import { ExperimentStatusType } from '../../enums/ExperimentStatus';
import { FileValidationRules } from '../../validation/validation-rules';
import { ExperimentAttributeProps } from '../ExperimentAttribute';
import { FileField, FileFieldConfig } from '../fields/FileField';
import { DisplayMode } from '../../interfaces/FormAttribute';
import { AttributeLabels } from '../../constants/display/string-constants';
import { CustomerTriggersAttribute } from '../../enums/CommonTypes';
import { Icon } from '@amzn/awsui-components-react-v3';

export class CustomerTriggersFile extends FileField<ExperimentAttributeProps> {
    protected displayConfig: FileFieldConfig;
    protected validationRules: FileValidationRules;
    
    constructor(props: ExperimentAttributeProps) {
        super(props);

        this.validationRules = {
            required: true,
            allowedOptions: ['text/csv'],
            maxSizeInBytes: 4000000000,
            emptyCheck: true,
        };

        const editable = this.props.experimentStatus === ExperimentStatusType.RUNNING;
    
        this.displayConfig = {
            label: AttributeLabels.CUSTOMER_TRIGGERS_FILE,
            editable,
            touched: false,
            value: []
        };

        this.fileConfig = {
            id: 'customer-triggers-file',
            custom: true,
            label: props.initialFileName?.length ? props.initialFileName : 'Upload Customer Triggers',
            onChange: (event: CustomEvent) => this.onChangeEvent(event, CustomerTriggersAttribute.CUSTOMER_TRIGGERS_FILE)
        };

        this.summaryDisplay = null;
        
        this.state = {
            displayValue: '',
            displayMode: props.displayMode ? props.displayMode : DisplayMode.CREATE,
            editInProgress: false,
            validity: false
        };
    
    }

    componentDidMount = async() => {
        if (this.props.initialValue) {
            this.setValueFromPayload(this.props.initialValue);
        }

        this.forceUpdate();
    }

    setValue = async(newValue: File) => {
        this.displayConfig.value = newValue;
        
        const { isValid, errorText } = this.validateFile(newValue, this.validationRules);

        const fileName = newValue.name;

        this.displayConfig = {
            ...this.displayConfig,
            touched: true,
            errorText
        };

        this.fileConfig = {
            ...this.fileConfig,
            label: fileName,
            isInvalid: !isValid
        };

        this.summaryDisplay = fileName && isValid ? (
            <div style={{ marginTop: '5px' }}>
                <span className="awsui-util-status-positive">
                    <Icon name="status-positive" /> {`File ${fileName} is now available`}
                    <div style={{ marginTop: '5px' }}>{`Size: ${newValue.size/1000000}MB`}</div>
                </span>
            </div>
        ) : null;

        await new Promise((resolve) => this.setState({ displayValue: fileName, validity: isValid }, () => resolve(newValue)));
    }

    setValueFromPayload = async(newContent: File) => {
        this.setValue(newContent);
    }

    validateFile = (value: File, validationRules: FileValidationRules) => {
        let isValid: boolean = true;
        const errors: string[] = [];

        if (validationRules) {
            if (validationRules.required && !value) {
                isValid = false;
                errors.push('Field is required');
            }

            if (validationRules.allowedOptions && value && !validationRules.allowedOptions.includes(value.type)) {
                isValid = false;
                errors.push('This is not a valid file type');
            }

            if (validationRules.maxSizeInBytes && value && value.size > validationRules.maxSizeInBytes) {
                isValid = false;
                errors.push('Max File Upload Size is ' + validationRules.maxSizeInBytes / 1000000 + ' MB');
            }
        }

        const errorText = errors.length !== 0 ? errors.join(' and ') : undefined;

        return { isValid, errorText };
    }

    getPayloadValue = () => this.displayConfig.value;

    renderViewMode = () => <></>;
}
