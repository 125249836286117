import axios, { AxiosInstance, AxiosRequestConfig, AxiosError } from 'axios';
import AWS from 'aws-sdk';
import { Auth } from 'aws-amplify';
import { Realm, Stage } from './api-constants';
import { EndpointMap, getStageFromUrl } from './endpoint-map';
import axiosRetry, { IAxiosRetryConfig, isRetryableError, exponentialDelay } from 'axios-retry';

export const defaultRetryConfig: IAxiosRetryConfig = {
    retries: 2,
    retryCondition: (error: AxiosError) => isRetryableError(error),
    shouldResetTimeout: true,
    retryDelay: exponentialDelay,
};

axiosRetry(axios, defaultRetryConfig);

export class ApiHandler {

    private readonly instance: AxiosInstance;
    protected stage: Stage;
    protected realm: Realm;
    protected config: AxiosRequestConfig = {
        headers: {
            'Content-Type': 'application/x-amz-json-1.1'
        }
    };

    public constructor(realm: Realm, endpoints: EndpointMap) {
        this.stage = getStageFromUrl();
        const baseURL = endpoints.getEndpoint(realm);
        this.instance = axios.create({ baseURL });
        this.realm = realm;
    }

    public getConfig = () => this.config

    protected authenticate = async() => {
        const session = await Auth.currentSession();
        this.config.headers['Authorization'] = session.getIdToken().getJwtToken();
    }

    public setupAWSCredentials = async() => {
        const credentials = await Auth.currentCredentials();
        AWS.config.update(
            {
                accessKeyId: credentials.accessKeyId,
                secretAccessKey: credentials.secretAccessKey,
                sessionToken: credentials.sessionToken
            }
        );
    }

    private getAlias = async() => (await Auth.currentAuthenticatedUser()).getUsername().split('_')![1];

    protected sendHttpPutRequest = async(url: string, payload: any) => {
        await this.authenticate();
        payload.requesterAlias = await this.getAlias();

        const response = await this.instance.put(url, payload, this.getConfig());
        if (!response) {
            console.error(`No response from ${url}`);
        }

        return response.data;
    }

    protected sendHttpGetRequest = async(url: string, requestConfig: AxiosRequestConfig) => {
        await this.authenticate();

        const response = await this.instance.get(url, requestConfig);
        if (!response) {
            console.error(`No response from ${url}`);
        }

        return response.data;
    }

    protected sendHttpPostRequest = async(url: string, payload: any) => {
        await this.authenticate();
        payload.requesterAlias = await this.getAlias();

        const response = await this.instance.post(url, payload, this.getConfig());
        if (!response) {
            console.error(`No response from ${url}`);
        }

        return response.data;
    }
}
