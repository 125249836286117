import React, { FunctionComponent } from 'react';
import { ActionType, PermissionsMap, UserAccessLevel } from '../enums/PermissionsModel';
import { isActionAuthorizedForUser } from '../utils/auth-utils';
import UnauthenticatedPage from '../pages/ErrorPages/UnauthenticatedPage';
import { ErrorBoundary } from '../common/ErrorBoundary';

export interface PermissionControlledViewProps {
    userAccessLevels: Set<UserAccessLevel>;
    pagePermissionsMap: PermissionsMap;
    actionType?: ActionType;
    hideIfNotAuthorized?: boolean;
}

export const PermissionControlledView: FunctionComponent<PermissionControlledViewProps> = ({
    children,
    userAccessLevels,
    pagePermissionsMap,
    hideIfNotAuthorized = false,
    actionType = ActionType.READ,
}) => {
    if (!isActionAuthorizedForUser(userAccessLevels, actionType, pagePermissionsMap)) {

        return !hideIfNotAuthorized ? <UnauthenticatedPage/> : null;
    }

    return <ErrorBoundary>{children}</ErrorBoundary>;
};