import { AttributeLabels } from '../../constants/display/string-constants';
import { CustomerTriggersAttribute } from '../../enums/CommonTypes';
import { CustomerTriggersType } from '../../enums/CustomerTriggersType';
import { DisplayMode } from '../../interfaces/FormAttribute';
import { ExperimentAttributeProps } from '../ExperimentAttribute';
import { SelectField, SelectFieldConfig } from '../fields/SelectField';

export class CustomerTriggersTypeField extends SelectField {
    protected displayConfig: SelectFieldConfig;

    constructor(props: ExperimentAttributeProps) {
        super(props);

        const { initialValue, displayMode } = props;

        this.validationRules = { required: true };
        const { isValid, errorText } = this.validate(initialValue, this.validationRules);

        this.displayConfig = {
            label: AttributeLabels.CUSTOMER_TRIGGERS_TYPE,
            editable: false,
            touched: false,
            placeholder: 'Select from the drop-down',
            filteringType: 'auto',
            hintText: 'Select the type of customer triggers which you want to conduct',
            errorText,
            onChange: (event) => this.onChangeEvent(event as CustomEvent, CustomerTriggersAttribute.CUSTOMER_TRIGGERS_TYPE),
            selectedOption: null,
        };

        this.state = {
            displayValue: '',
            displayMode: displayMode ? displayMode : DisplayMode.CREATE,
            validity: isValid
        };
    }

    componentDidMount() {
        if (this.props.initialValue) {
            this.setValueFromPayload(this.props.initialValue);
        }

        this.displayConfig.options = [
            { label: 'Default Customer Triggers', value: CustomerTriggersType.DEFAULT_CUSTOMER_TRIGGERS.toString() },
            { label: 'Use Your Own Customer Triggers', value: CustomerTriggersType.CUSTOMIZED_CUSTOMER_TRIGGERS.toString() },
        ];

        this.forceUpdate();
    }
}
