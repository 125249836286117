import { DisplayAttribute } from '../interfaces/DisplayAttribute';

export interface KeyValuePair {
    [name: string]: any;
}

export interface ExperimentStep extends KeyValuePair {
    [key: string]: DisplayAttribute;
}

export enum MetadataAttribute {
    MARKETPLACE = 'marketplace',
    TITLE = 'title',
    DESCRIPTION = 'description',
    PRODUCT_FAMILY = 'productFamily',
    PRIMARY_BUSINESS_GROUP = 'primaryBusinessGroup',
    SECONDARY_BUSINESS_GROUPS = 'secondaryBusinessGroups',
    PRIMARY_OWNER = 'primaryOwner',
    SECONDARY_OWNERS = 'secondaryOwners',
    OBSERVERS = 'observers',
    TAGS = 'tags',
    RABL_REGION_ID = 'rablRegionId',
    DISCRIMINATOR = 'discriminator',
    START_DATE = 'startDate',
    END_DATE = 'endDate',
    BUSINESS_APPROVER = 'businessApprover',
    BOUNDARIES = 'boundaries',
    EXPERIMENT_TYPE = 'experimentType',
    LINKED_MULTI_TREATMENT_EXPERIMENT_IDS = 'linkedMultiTreatmentExperimentIds',
    BUNDLE_ENABLED = 'bundleEnabled',
    ADDITIONAL_CUSTOM_METRICS = 'customMetrics',
    REGION_DEFINITION_TYPE = 'regionDefinitionType',
    DEALS_ENABLED = 'dealsEnabled',
    TREATMENT_TYPE = 'treatmentType',
}

export enum ProductSelectionAttribute {
    OFFERS_FILE = 'offersFile'
}

export enum RegionSelectionAttribute {
    TREATMENT_BOUNDARIES = 'treatmentBoundaries'
}

export enum ExperimentApprovalAttribute {
    REJECTION_REASON = 'rejectionReason'
}

export enum CustomerTriggersAttribute {
    CUSTOMER_TRIGGERS_FILE = 'customerTriggersFile',
    CUSTOMER_TRIGGERS_TYPE = 'customerTriggersType',
}

export interface SelectionErrorConfig {
    emptyAsinCount: number;
    duplicateAsins: string[];
}
