import React, { FunctionComponent } from 'react';
import { SideNavigation, SideNavigationProps } from '@amzn/awsui-components-react-v3';
import { basicPages, adminPages } from '../pages';
import { IProps } from '../interfaces/IProps';
import { ExternalLinks } from '../constants/display/external-links';
import { Realm } from '../api/api-constants';

interface SidebarProps extends IProps {
    realm: Realm;
    activeHref: string,
    isAdminPortal: boolean
}

export const Sidebar: FunctionComponent<SidebarProps> = ({ activeHref, isAdminPortal, realm }) => {
    const menuItems: SideNavigationProps.Link[] = [];
    let pagesToRender = isAdminPortal ? adminPages : basicPages;
    pagesToRender.filter((page) => !page.hideTab).forEach((page) => {
        menuItems.push({
            type: 'link',
            text: page.title,
            href: `${page.path}?realm=${realm}`,
            external: false
        });
    });

    const externalItems: SideNavigationProps.Link[] = [
        { type: 'link', ...ExternalLinks.WIKI },
        { type: 'link', ...ExternalLinks.FEEDBACK_INTAKE },
        { type: 'link', ...ExternalLinks.TT }
    ];

    return (
        <SideNavigation
            activeHref={activeHref}
            items={[
                {
                    type: 'section',
                    text: 'Menu',
                    items: menuItems
                },
                {
                    type: 'section',
                    text: 'External Links',
                    items: externalItems
                }
            ]}/>
    );
};
