import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { PageProps } from '../../interfaces/IProps';
import { PermissionControlledView } from '../../permissions/PermissionControlledView';
import { ExperimentType } from '../../enums/ExperimentType';
import { CreateExperimentWizard } from './CreateExperimentWizard';
import { ExperimentTypeField } from '../../form/attributes/ExperimentTypeField';
import { DisplayMode } from '../../interfaces/FormAttribute';
import { Container } from '@amzn/awsui-components-react-v3';

export interface CreateExperimentPageProps extends RouteComponentProps, PageProps {}

export interface CreateExperimentPageState {
    experimentType: string;
}

export class CreateExperimentPage extends Component<CreateExperimentPageProps, CreateExperimentPageState> {
    constructor(props: CreateExperimentPageProps) {
        super(props);

        this.state = {
            experimentType: '',
        };
    }

    updateExperimentType = (_fieldId: string, payloadValue: string, _displayValue: string, _isValid: boolean) => {
        this.setState({ experimentType: payloadValue });
    }

    render() {
        /** TODO: Add different create wizards for different experiment types. */
        let wizard: JSX.Element | null;
        let experimentTypeField: JSX.Element | null;
        switch (this.state.experimentType) {
            case ExperimentType.LISTER_EXPERIMENT:
                wizard = <CreateExperimentWizard data-testid='create-experiment-wizard' experimentType={ExperimentType.LISTER_EXPERIMENT} {...this.props}/>;
                experimentTypeField = null;
                break;
            case ExperimentType.RESULT_ANALYSIS_ONLY:
                wizard = <CreateExperimentWizard data-testid='create-experiment-wizard' experimentType={ExperimentType.RESULT_ANALYSIS_ONLY} {...this.props}/>;
                experimentTypeField = null;
                break;
            default:
                wizard = null;
                experimentTypeField = (
                    <Container>
                        <ExperimentTypeField
                            data-testid='experiment-type-dropdown'
                            displayMode={DisplayMode.CREATE}
                            updateFormState={this.updateExperimentType}
                        />
                    </Container>
                );
        }

        return (
            <PermissionControlledView
                userAccessLevels={this.props.userAccessLevels}
                pagePermissionsMap={this.props.permissionsMap}
            >
                <div style={{ padding: '20px' }}>
                    <Container>
                        {wizard}
                        {experimentTypeField}
                    </Container>
                </div>
            </PermissionControlledView>
        );
    }
}

export default withRouter(CreateExperimentPage);
