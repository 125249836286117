import { ActionType, PermissionsMap } from '../../enums/PermissionsModel';

export const DEFAULT_PERMISSIONS: PermissionsMap = {
    ADMIN: [],
    CREATOR: [],
    PRIMARY_OWNER: [],
    SECONDARY_OWNER: [],
    OBSERVER: [],
    APPROVER: [],
    DEFAULT: [ActionType.READ]
};

export const CREATE_EXPERIMENT_PAGE_PERMISSIONS: PermissionsMap = {
    ADMIN: [ActionType.READ],
    CREATOR: [ActionType.READ],
    PRIMARY_OWNER: [],
    SECONDARY_OWNER: [],
    OBSERVER: [],
    APPROVER: [],
    DEFAULT: []
};

export const EXPERIMENT_DETAIL_PAGE_PERMISSIONS: PermissionsMap = {
    ADMIN: [ActionType.WRITE, ActionType.SAVE, ActionType.ELEVATED_READ, ActionType.ADMIN_ACTION],
    CREATOR: [],
    PRIMARY_OWNER: [ActionType.WRITE, ActionType.SAVE, ActionType.ELEVATED_READ],
    SECONDARY_OWNER: [ActionType.WRITE, ActionType.SAVE, ActionType.ELEVATED_READ],
    OBSERVER: [ActionType.SAVE, ActionType.ELEVATED_READ],
    APPROVER: [],
    DEFAULT: [ActionType.READ]
};

export const MY_EXPERIMENTS_PAGE_PERMISSIONS: PermissionsMap = {
    ADMIN: [],
    CREATOR: [],
    PRIMARY_OWNER: [],
    SECONDARY_OWNER: [],
    OBSERVER: [],
    APPROVER: [],
    DEFAULT: [ActionType.READ]
};

export const TRANSACTIONAL_METRICS_PAGE_PERMISSIONS: PermissionsMap = {
    ADMIN: [ActionType.WRITE, ActionType.SAVE],
    CREATOR: [],
    PRIMARY_OWNER: [ActionType.WRITE, ActionType.SAVE],
    SECONDARY_OWNER: [ActionType.WRITE, ActionType.SAVE],
    OBSERVER: [ActionType.SAVE],
    APPROVER: [],
    DEFAULT: [ActionType.READ]
};

export const EXPERIMENT_DASHBOARD_PAGE_PERMISSIONS: PermissionsMap = {
    ADMIN: [ActionType.READ],
    CREATOR: [],
    PRIMARY_OWNER: [ActionType.READ],
    SECONDARY_OWNER: [ActionType.READ],
    OBSERVER: [ActionType.READ],
    APPROVER: [],
    DEFAULT: []
};

export const APPROVERS_QUEUE_PERMISSIONS: PermissionsMap = {
    ADMIN: [],
    CREATOR: [],
    PRIMARY_OWNER: [],
    SECONDARY_OWNER: [],
    OBSERVER: [],
    APPROVER: [],
    DEFAULT: [ActionType.READ]
};

export const APPROVER_DETAIL_PAGE_PERMISSIONS: PermissionsMap = {
    ADMIN: [ActionType.READ, ActionType.ADMIN_ACTION],
    CREATOR: [],
    PRIMARY_OWNER: [],
    SECONDARY_OWNER: [],
    OBSERVER: [],
    APPROVER: [ActionType.READ, ActionType.WRITE],
    DEFAULT: []
};

export const STATIC_CONFIGURATION_PAGE_PERMISSIONS: PermissionsMap = {
    ADMIN: [ActionType.READ, ActionType.WRITE],
    CREATOR: [],
    PRIMARY_OWNER: [],
    SECONDARY_OWNER: [],
    OBSERVER: [],
    APPROVER: [],
    DEFAULT: []
};

export const ASIN_ACTION_PAGE_PERMISSIONS: PermissionsMap = {
    ADMIN: [ActionType.READ, ActionType.WRITE],
    CREATOR: [],
    PRIMARY_OWNER: [],
    SECONDARY_OWNER: [],
    OBSERVER: [],
    APPROVER: [],
    DEFAULT: []
};

export const ALL_EXPERIMENTS_PAGE_PERMISSIONS: PermissionsMap = {
    ADMIN: [ActionType.READ],
    CREATOR: [],
    PRIMARY_OWNER: [],
    SECONDARY_OWNER: [],
    OBSERVER: [],
    APPROVER: [],
    DEFAULT: []
};

export const CREATE_REGION_DEFINITION_PAGE_PERMISSIONS: PermissionsMap = {
    ADMIN: [ActionType.READ, ActionType.WRITE],
    CREATOR: [],
    PRIMARY_OWNER: [],
    SECONDARY_OWNER: [],
    OBSERVER: [],
    APPROVER: [],
    DEFAULT: []
};
