/**
 * This is the class which is used to display any experiment field.
 * @member displayLabel the label which is used to name the field.
 * @member payloadValue the value which is in the format as needed by the backend.
 * @member displayValue the value which is in the format as needed by the frontend display.
 * @member isValid flag to represent whether the value is valid or not.
 */
export class DisplayAttribute {
    displayLabel: string;
    payloadValue: any;
    isValid: boolean;
    displayValue: any;

    public constructor(displayLabel: string, isValid?: boolean, payloadValue?: any, displayValue?: string) {
        this.displayLabel = displayLabel;
        this.payloadValue = payloadValue;
        this.isValid = isValid ? isValid: false;
        this.displayValue = displayValue? displayValue: '';
    }

    updateAttributeDetails(isValid: boolean, payloadValue: any, displayValue: any) {
        this.isValid = isValid;
        this.payloadValue = payloadValue;
        this.displayValue = displayValue;
    }
}