import { DisplayAttribute } from '../interfaces/DisplayAttribute';
import { LimestoneExperiment } from '../interfaces/LimestoneExperiment';
import { AttributeLabels } from '../constants/display/string-constants';
import { CustomEvaluationRequest } from '../interfaces/CustomEvaluationRequest';
import { LifecycleType } from '../api/api-constants';
import { GccpMetricRequest } from '../interfaces/GccpMetricRequest';

/**
 * Creates an default limestone experiment entity.
 * @returns empty limestone experiment entity.
 */
export const createEmptyLimestoneExperiment = (): LimestoneExperiment => {
    return {
        experimentId: '',
        metadata: {
            marketplace: new DisplayAttribute(AttributeLabels.MARKETPLACE),
            title: new DisplayAttribute(AttributeLabels.TITLE),
            description: new DisplayAttribute(AttributeLabels.DESCRIPTION),
            productFamily: new DisplayAttribute(AttributeLabels.PRODUCT_FAMILY),
            primaryBusinessGroup: new DisplayAttribute(AttributeLabels.PRIMARY_BUSINESS_GROUP, true),
            secondaryBusinessGroups: new DisplayAttribute(AttributeLabels.SECONDARY_BUSINESS_GROUPS, true, []),
            primaryOwner: new DisplayAttribute(AttributeLabels.PRIMARY_OWNER),
            secondaryOwners: new DisplayAttribute(AttributeLabels.SECONDARY_OWNERS, true, []),
            observers: new DisplayAttribute(AttributeLabels.OBSERVERS, true, []),
            tags: new DisplayAttribute(AttributeLabels.TAGS, true, []),
            rablRegionId: new DisplayAttribute(AttributeLabels.RABL_REGION_ID),
            discriminator: new DisplayAttribute(AttributeLabels.DISCRIMINATOR),
            startDate: new DisplayAttribute(AttributeLabels.START_DATE, false, ''),
            endDate: new DisplayAttribute(AttributeLabels.END_DATE, false, ''),
            regionCreationStatus: new DisplayAttribute(AttributeLabels.REGION_CREATION_STATUS, true),
            businessApprover: new DisplayAttribute(AttributeLabels.BUSINESS_APPROVER),
            experimentType: new DisplayAttribute(AttributeLabels.EXPERIMENT_TYPE, true, LifecycleType.BLOCK_LIST, LifecycleType.BLOCK_LIST), // default experiment type is BLOCK_LIST
            linkedMultiTreatmentExperimentIds: new DisplayAttribute(AttributeLabels.LINKED_MULTI_TREATMENT_EXPERIMENT_IDS, true, []),
            bundleEnabled: new DisplayAttribute(AttributeLabels.BUNDLE_ENABLED, true, true, ''),
            customMetrics: new DisplayAttribute(AttributeLabels.ADDITIONAL_CUSTOM_METRICS, true, [], ''),
            regionDefinitionType: new DisplayAttribute(AttributeLabels.REGION_DEFINITION_TYPE, true, ''),
            dealsEnabled: new DisplayAttribute(AttributeLabels.DEALS_ENABLED, true, true),
            treatmentType: new DisplayAttribute(AttributeLabels.TREATMENT_TYPE, true, ''),
        },
        productSelection: {
            offersFile: new DisplayAttribute(AttributeLabels.OFFERS_FILE)
        },
        regionSelection: {
            treatmentBoundaries: new DisplayAttribute(AttributeLabels.TREATMENT_BOUNDARIES, false, '')
        },
        currentStatus: {
            currentStatus: new DisplayAttribute(AttributeLabels.CURRENT_STATUS, true),
            lastRefreshed: new DisplayAttribute(AttributeLabels.LAST_REFRESHED, true)
        }
    };
};

/**
 * Creates a default custom evaluation request.
 * @returns empty custom evaluation request.
 */
export const createEmptyCustomEvaluationRequest = (): CustomEvaluationRequest => {
    return {
        name: '',
        selection: null,
        metricTypes: [],
        editing: true,
    };
};

/**
 * Creates a default GCCP metric request.
 * @returns empty GCCP metric request.
 */
export const createEmptyGccpMtericsRequest = (): GccpMetricRequest => {
    return {
        useDefaultcustomerTriggers: true,
        startDate: '',
        endDate: '',
        customerTriggersFile: null,
        useDefaultScienceModel: true,
        editing: true,
    };
};
