import { MetricDataType } from '../api/data-collection/lambda-model-types';

type StatusTypeMap = {
    [name in MetricDataType]: string;
};

// This should be updated as per the CDC Metrics: https://tiny.amazon.com/98tzrpu2/codeamazpackReSEblobmainsrc
export const TransactionalMetricColumnMap: StatusTypeMap = {
    METRIC_DIMENSION: 'Metric',
    OBSERVED_DATA: 'Observed Data',
    ESTIMATED_BASE: 'Estimated Base',
    PERCENT_LIFT: 'Percent Lift',
    ABSOLUTE_LIFT: 'Absolute Lift',
    LIFT_PER_USER: 'Lift per user',
    RMSE_RATIO: 'RMSE Ratio',
    P_VALUE_AUDIT: 'p-value-audit',
    P_VALUE: 'p value',
    POINT_ESTIMATE: 'Point Estimate',
    LOWER_BOUND_90: '90% Lower Bound',
    UPPER_BOUND_90: '90% Upper Bound',
    TOTAL_LIFT: 'Total Lift'
};
