import { EndpointMap } from '../endpoint-map';

/**
 * Region Management Service frontend API endpoints.
 */
export const RMS_API_ENDPOINTS = new EndpointMap({
    beta_na: 'https://k6ji9h0kni.execute-api.us-east-1.amazonaws.com/beta-us-east-1',
    beta_eu: 'https://k6ji9h0kni.execute-api.us-east-1.amazonaws.com/beta-us-east-1',
    beta_fe: 'https://k6ji9h0kni.execute-api.us-east-1.amazonaws.com/beta-us-east-1',
    gamma_na: 'https://6ie6ldliu5.execute-api.us-east-1.amazonaws.com/prod-us-east-1',
    prod_na: 'https://6ie6ldliu5.execute-api.us-east-1.amazonaws.com/prod-us-east-1',
    prod_eu: 'https://f9fbb4o8ng.execute-api.eu-west-1.amazonaws.com/prod-eu-west-1',
    prod_fe: 'https://e8akkfbhah.execute-api.us-west-2.amazonaws.com/prod-us-west-2',
    dev_na: 'https://vskivbkawk.execute-api.us-east-1.amazonaws.com/dev-us-east-1',
    dev_eu: 'https://vskivbkawk.execute-api.us-east-1.amazonaws.com/dev-us-east-1',
    dev_fe: 'https://vskivbkawk.execute-api.us-east-1.amazonaws.com/dev-us-east-1',
});
