import AWS from 'aws-sdk';
import { downloadFile, FileFormat } from '../../utils/file-utils';
import { AwsRegionMap, CDC_AWS_ACCOUNTS, Realm } from '../api-constants';
import { ApiHandler } from '../api-handler';
import { AwsServicesApiHandler } from './aws-services-handler';
import { EndpointMap } from '../endpoint-map';

export default class AwsServicesApiHandlerImpl extends ApiHandler implements AwsServicesApiHandler {
    public constructor(realm: Realm) {
        super(realm, new EndpointMap({}));
    }

    public downloadSelectionAnalysisFile = async(experimentId: string, s3FileUri: string): Promise<void> => {
        await this.setupAWSCredentials();

        const s3BucketNameParts = s3FileUri.split('//')[1].split('/', 3);

        new AWS.S3().getObject({
            Bucket: s3BucketNameParts[0],
            Key: s3BucketNameParts[1] + '/' + s3BucketNameParts[2]
        }, (error, data) => {
            if (error) {
                console.error(error);
            } else {
                const result = new TextDecoder().decode(data.Body as any);
                downloadFile(result.split('\n'), `${experimentId}-selection-analysis`, FileFormat.TSV);
            }
        });
    }

    public uploadCustomerTriggersFile = async(customerTriggersFile: File, experimentId: string, marketplaceId: number, collectionDate: string): Promise<void> => {
        await this.setupAWSCredentials();
        
        const collectionDateParts = collectionDate.split('-');
        const objectKey = [marketplaceId, collectionDateParts[0], collectionDateParts[1], collectionDateParts[2], experimentId].join('/');
        const deploymentGroupName = this.stage.concat('-').concat(this.realm);
        const awsRegion = AwsRegionMap.get(deploymentGroupName);
        const accountId = CDC_AWS_ACCOUNTS.get(deploymentGroupName);
        const bucketName = `reses-custom-customer-triggers-${this.stage}-${awsRegion}-${accountId}`;
     
        const content = await customerTriggersFile.arrayBuffer();

        await new AWS.S3().putObject({
            Bucket: bucketName,
            Key: objectKey + '/' + customerTriggersFile.name,
            ContentType: customerTriggersFile.type,
            Body: content,
        }).promise();
    }
}
