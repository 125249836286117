import { OfferValidationStatusType } from '../../enums/OfferStatusTypes';
import { LimestoneExperiment } from '../../interfaces/LimestoneExperiment';
import { BaseEntityType, LifecycleType } from '../api-constants';
import { MetricType } from '../../enums/MetricType';

export interface OwnerDto {
    alias: string;
    primary: boolean;
}

export interface BusinessGroupDto {
    businessGroup: string;
    primary: boolean;
}

export interface ObserverDto {
    alias: string;
}

export interface TagDto {
    tag: string;
}

export interface RunDatesDto {
    startDate: string;
    endDate: string;
}

export interface CustomExperimentMetricDto {
    metricType: string;
}

export interface CustomMetricDisplayDto {
    metricType: string;
    displayName: string;
    description: string;
    collectionSchedule: string;
}

export interface MetadataPayload {
    marketplaceId: string,
    title: string,
    description: string,
    productFamily: string,
    ownersDto: OwnerDto[],
    observersDto?: ObserverDto[],
    tagsDto?: TagDto[],
    regionId: string;
    listerDiscriminator: string;
    experimentStartDate: string;
    experimentEndDate: string;
    businessApprover: string;
    experimentType: string;
    linkedMultiTreatmentExperimentIds: string[];
    bundleEnabled: boolean;
    customMetrics: CustomExperimentMetricDto[];
    boundaryType: string;
    dealsEnabled: boolean;
    treatmentType: string;
}

export interface ExperimentOfferDto {
    asin: string;
    merchantId: string;
    sku: string;
    marketplaceId?: string;
    offerValidationStatus?: string;
    offerRegionalizationStatus?: string;
    offerValidationFailedReasons?: string[];
}

export interface ExperimentBoundaryDto {
    boundary: BoundaryDto
    experimentRegionType: string; // e.g., CONTROL, TREATMENT
    boundaryDefinitionType: string; // e.g., ZIP3, NUTS3
}

export interface BoundaryDto {
    boundaryId?: string
    boundaryName?: string;
    boundaryDescription?: string;
}

export interface ExperimentApproverDto {
    alias: string;
    type: string;
    status: string;
    rejectReason: string;
}

export enum ExperimentUserRoleType {
    OWNER = 'OWNER',
    OBSERVER = 'OBSERVER',
    APPROVER = 'APPROVER'
}

export interface ExperimentOutput extends Omit<MetadataPayload, 'customMetrics'> {
    experimentId: string;
    experimentIntegerId: string;
    currentStatus?: string;
    experimentType: string;
    program?: string;
    creationDate?: string;
    lastUpdatedDate?: string;
    regionCreationStatus: string;
    businessGroupsDto: BusinessGroupDto[];
    selectionFinalized: boolean;
    customMetrics: CustomMetricDisplayDto[];
    boundaryType: string;
    comment?: string;
    treatmentType: string;
    customerDecision?: string;
    businessRecommendation?: string;
}

export interface CreateExperimentPayload {
    experimentMetadata: MetadataPayload
}

export enum ArtifactType {
    PRODUCT_SELECTION = 0,
    REGION_SELECTION = 1
}

export enum EntityType {
    EXPERIMENT = 'EXPERIMENT',
    SELECTION = 'SELECTION',
    EXPERIMENT_USER_REQUEST = 'EXPERIMENT_USER_REQUEST'
}

export interface CreateExperimentResponse {
    success: boolean;
    experimentId: string;
    experimentIntegerId: number;
}

export interface GetExperimentsByUserAliasRequest {
    userAlias: string;
    userRoles: ExperimentUserRoleType[];
}

export interface GetExperimentsByUserAliasResponse {
    experiments: LimestoneExperiment[];
}

export interface GetExperimentBoundariesResponse {
    boundaries: ExperimentBoundaryDto[];
}

export interface UpdateExperimentTitlePayload {
    experimentId: string;
    title: string;
}

export interface UpdateExperimentDescriptionPayload {
    experimentId: string;
    description: string;
}

export interface AddSecondaryOwnerPayload {
    experimentId: string;
    owner: OwnerDto;
}

export interface RemoveSecondaryOwnerPayload {
    experimentId: string;
    owner: OwnerDto;
}

export interface AddObserverPayload {
    experimentId: string;
    observer: ObserverDto;
}

export interface RemoveObserverPayload {
    experimentId: string;
    observer: ObserverDto;
}

export interface AddTagPayload {
    experimentId: string;
    tag: TagDto;
}

export interface AddTagResponse {
    success: boolean;
}

export interface RemoveTagPayload {
    experimentId: string;
    tag: TagDto;
}

export interface GetExperimentOffersPaginatedRequest {
    entityId: string;
    marketplaceId: string;
    maxResults: number;
    nextToken?: string|null;
}

export interface GetExperimentOffersPaginatedResponse {
    experimentOffers: ExperimentOfferDto[];
    nextToken: string;
}

export interface GetExperimentsByApproverResponse {
    experiments: ExperimentOutput[];
}

export interface SubmitApprovalDecisionPayload {
    approverAlias: string;
    approverRole: string;
    updatedStatus: string;
    rejectionReason?: string;
}

export interface SubmitApprovalDecisionResponse {
    success: boolean;
}

export interface GetAllExperimentApproversResponse {
    approvers: ExperimentApproverDto[];
}

export interface ExperimentUserRequestOutput {
    requestId: string;
    requestType: string;
    validationStatus: OfferValidationStatusType;
    validationFailureReasons: string;
    requestStatus: string;
    requesterAlias: string;
}

export interface BoundarySetDto {
    boundaries: Object;
    attributes: Object;
    isManuallyUploaded: boolean;
}

export interface FinalizeExperimentBoundariesResponse {
    success: true;
}

export interface FinalizeProductSelectionResponse {
    success: boolean;
}

export interface GetExperimentBoundaryOptionsResponse {
    boundarySets: BoundarySetDto[];
}

export interface AggregatedSelectionMetricsDto {
    comparisonPoint: number;
    lowerThreshold: number;
    upperThreshold: number;
}

export enum SelectionAnalysisWarningType {
   WARNING = 'WARNING',
   ERROR = 'ERROR',
   NONE = 'NONE'
}

export interface SelectionAnalysisWarningDto {
    warningType: SelectionAnalysisWarningType;
    warningMessage: string;
}

export interface PreExperimentSelectionAnalysisWarningsDto {
    amazonCpWarning: SelectionAnalysisWarningDto;
    amazonUnitsShippedWarning: SelectionAnalysisWarningDto;
    amazonGmsWarning: SelectionAnalysisWarningDto;
    consumableCpWarning: SelectionAnalysisWarningDto;
    consumableUnitsShippedWarning: SelectionAnalysisWarningDto;
    consumableGmsWarning: SelectionAnalysisWarningDto;
}

export interface GlMap {
    [key: string]: number;
 }

export interface PreExperimentSelectionAnalysisResultDto {
    experimentId: string;
    preExperimentAnalysisType: string;
    selectionDataS3Uri: string;
    aggregatedSelectionMetrics: AggregatedSelectionMetricsDto;
    glMap: GlMap;
    preExperimentSelectionAnalysisWarnings: PreExperimentSelectionAnalysisWarningsDto;
}

export interface GetPreExperimentSelectionAnalysisResponse {
    preExperimentSelectionAnalysisResult?: PreExperimentSelectionAnalysisResultDto;
}

export enum LifecycleStepDisplayStatus {
    FAILED = 'FAILED',
    COMPLETE = 'COMPLETE',
    NOT_STARTED = 'NOT_STARTED',
    IN_PROGRESS = 'IN_PROGRESS',
    AWAITING_USER_INPUT = 'AWAITING_USER_INPUT'
}

export interface LifecycleStepDisplay {
    displayName: string;
    description: string;
    currentStatus: LifecycleStepDisplayStatus;
    totalExecutionTime: string;
    startTimestamp: string;
    endTimestamp: string;
}

export interface LifecycleStepConfigurationMap {
    [key: string]: LifecycleStepDisplay;
}

export interface GetExperimentLifecycleGraphResponse {
    edges: string[][];
    nodeConfigurationMap: LifecycleStepConfigurationMap;
}

export interface ExperimentProgressStatusDto {
    displayName: string;
    executionTimeInMins: number;
}

export interface GetExperimentProgressDisplayResponse {
    currentStatusRank: number;
    progressStatusList: ExperimentProgressStatusDto[];
}

export interface GetAllBoundaryOptionsResponse {
    boundaries: BoundaryDto[];
}

export interface UpdateExperimentRunDatesPayload {
    experimentId: string;
    experimentStartDate?: string;
    experimentEndDate?: string;
}

export interface GetUnavailableRunDatesPayload {
    marketplaceId: string;
    asins: string[];
}

export interface GetUnavailableRunDatesResponse {
    unavailableRunDates: RunDatesDto[];
}

export interface CreateExperimentOfferSelectionInput {
    experimentId: string,
    lifecycleType: LifecycleType,
    merchantId?: string,
    metricTypes?: MetricType[],
    title?: string
}

export interface CreateExperimentOfferSelectionResponse {
    selectionId: string
}

export interface SelectionOutput {
    selectionId: string;
    lifecycleType: LifecycleType;
    title: string;
}

export interface GetSelectionsInExperimentOutput {
    selections: SelectionOutput[];
}

/**
 * This interface could be used for any API responses with only success field. This kind of APIs is mainly used to trigger
 * some backend activities.
 */
export interface ActivityTriggerResponse {
    success: boolean;
}

export enum OrchestrationStep {
    EXPERIMENT_RUNNING = 'EXPERIMENT_RUNNING',
    RESULT_ANALYSIS_SCHEDULED = 'RESULT_ANALYSIS_SCHEDULED',
}

export enum OrchestrationSystemOutcome {
    /** This status is where the system has completed execution and can proceed to the next step. */
    PASS = 'PASS',
    /** System has completed execution but has output which is invalid to proceed and must stop at this stage. */
    FAIL = 'FAIL',
    /** Lifecycle can proceed to the next step but warnings related to the system has to be displayed to the user. */
    WARNING = 'WARNING',
}

export interface SendOrchestratorCompletionEventInput {
    baseEntityId: string;
    baseEntityType: BaseEntityType;
    stepName: OrchestrationStep;
    systemOutcome: OrchestrationSystemOutcome;
    systemOutput: string;
}

export interface GetCustomMetricOptionsOutput {
    options: CustomMetricDisplayDto[];
}

export interface AddCustomMetricToExperimentInput {
    experimentId: string;
    metrics: CustomExperimentMetricDto[];
}
