import React, { FunctionComponent } from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { maxPageWidth, minPageWidth, pagePadding } from '../constants/display/page-size';
import { IProps } from '../interfaces/IProps';

interface PageSectionProps extends IProps {
    backgroundColor?: Function;
    className?: string;
}

const useStyles = makeStyles<Theme, PageSectionProps>((theme) => ({
    background: {
        backgroundColor: ({ backgroundColor }): string => (backgroundColor ? backgroundColor(theme) : theme.palette.background.default),
        transition: 'background-color ease 0.2s'
    },
    container: {
        margin: '0 auto',
        maxWidth: maxPageWidth,
        minWidth: minPageWidth,
        padding: `0 ${pagePadding}`
    }
}));

export const PageSection: FunctionComponent<PageSectionProps> = ({ backgroundColor, children, className, testId }) => {
    const classes = useStyles({ backgroundColor, testId });

    return (
        <div data-testid={testId} className={classes.background}>
            <div className={clsx(classes.container, className)}>{children}</div>
        </div>
    );
};
