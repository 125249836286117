import React, { Component } from 'react';
import { Spinner, Flashbar } from '@amzn/awsui-components-react-v3';
import { LimestoneExperiment } from '../../interfaces/LimestoneExperiment';
import CdcApiHandlerImpl from '../../api/data-collection/handler/cdc-api-handler-impl';
import { DataTableGroup, DataTableGroupDefinition } from '../../common/DataTableGroup';
import { convertMetricsTableToDataTableGroup } from '../../api/data-collection/adaptors/transactional-metrics-adaptor';
import { Realm } from '../../api/api-constants';

/**
 * Props for {@link ExperimentDashboardMetrics}.
 */
export interface ExperimentDashboardMetricsProps {
    experiment: LimestoneExperiment;
    realm: Realm;
}

export interface ExperimentDashboardMetricsState {
    showSpinner: boolean;
    tableGroups: DataTableGroupDefinition[][];
}

/**
 * Key metrics to display on the experiment dashboard as a cell in experiment tables.
 */
class ExperimentDashboardMetrics extends Component<ExperimentDashboardMetricsProps, ExperimentDashboardMetricsState> {
    public cdcServiceApi: CdcApiHandlerImpl;

    constructor(props: ExperimentDashboardMetricsProps) {
        super(props);
        this.state = {
            showSpinner: false,
            tableGroups: [],
        };

        this.cdcServiceApi = new CdcApiHandlerImpl(props.realm);
    }

    componentDidMount = async() => {
        await this.fetchMetrics();
    }

    componentDidUpdate = async(prevProps: Readonly<ExperimentDashboardMetricsProps>) => {
        if (this.props.experiment.experimentId !== prevProps.experiment.experimentId) {
            await this.fetchMetrics();
        }
    }

    fetchMetrics = async() => {
        this.setState({ showSpinner: true, tableGroups: [] });

        await this.cdcServiceApi.getMetricsInExperimentDashboard(this.props.experiment.experimentId)
            .then((metricsTables) => {
                this.setState({ tableGroups: metricsTables.map((metricsTable) =>
                    convertMetricsTableToDataTableGroup((metricsTable)))
                });
            })
            .finally(() => this.setState({ showSpinner: false }));
    }

    render() {
        let content;
        if (this.state.showSpinner) {
            content = (<Spinner size='normal'/>);
        } else {
            content = <div>
                {this.state.tableGroups.length !== 0 ?
                    this.state.tableGroups?.map((tableDefinitions) =>
                        tableDefinitions.map((tableDefinition) =>
                            <DataTableGroup
                                key={tableDefinition.groupName}
                                dataTableGroupDefinition={tableDefinition}
                                expandableSection={true}
                                firstColumnHeader={true}
                            />
                        )) :
                    <Flashbar
                        items={[{
                            content: 'Metrics are not ready yet for this experiment.',
                            type: 'warning',
                        }]}
                    />
                }
            </div>;
        }
        return content;
    };
}

export default ExperimentDashboardMetrics;
