import { FlashbarProps } from '@amzn/awsui-components-react-v3';
import { ExperimentType } from '../enums/ExperimentType';

export const ExperimentStepIds = {
    METADATA: new Map([[ExperimentType.LISTER_EXPERIMENT, 0], [ExperimentType.RESULT_ANALYSIS_ONLY, 0]]),
    PRODUCT_REGION_SELECTION: new Map([[ExperimentType.LISTER_EXPERIMENT, 1], [ExperimentType.RESULT_ANALYSIS_ONLY, 2]]),
    SCHEDULE_EXPERIMENT: new Map([[ExperimentType.LISTER_EXPERIMENT, 2], [ExperimentType.RESULT_ANALYSIS_ONLY, 1]]),
    CUSTOM_EVALUATION_REQUESTS: new Map([[ExperimentType.LISTER_EXPERIMENT, 3], [ExperimentType.RESULT_ANALYSIS_ONLY, 3]]),
    SUMMARY: new Map([[ExperimentType.LISTER_EXPERIMENT, 4], [ExperimentType.RESULT_ANALYSIS_ONLY, 4]]),
};

export interface INotifications {
    [id: string]: FlashbarProps.MessageDefinition
}

export const wizardI18N = {
    stepNumberLabel: (stepNumber: number) => `Step ${stepNumber}`,
    collapsedStepsLabel: (stepNumber: number, stepsCount: number) => `Step ${stepNumber} of ${stepsCount}`,
    cancelButton: 'Cancel',
    previousButton: 'Previous',
    nextButton: 'Next',
    submitButton: 'Submit',
    optional: 'optional'
};
