import React, { FunctionComponent } from 'react';
import { Box, Cards, CardsProps } from '@amzn/awsui-components-react-v3';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

export interface QuickLinkCardItem {
    name: string;
    link: string;
}

const quickLinkCardsPerRow = [
    {
        'cards': 1
    },
    {
        'minWidth': 500,
        'cards': 2
    }
];

const useStyles = makeStyles({
    cardItem: {
        textAlign: 'center',
        padding: '50px',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline'
        }
    }
});

interface QuickLinkCardProps {
    item: QuickLinkCardItem;
}

const QuickLinkCardContent: FunctionComponent<QuickLinkCardProps> = ({ item }) => {
    const classes = useStyles();
    return (
        <Link to={item.link}>
            <div className={classes.cardItem}>
                <Box variant="h2" className={classes.cardItem}>{item.name}</Box>
            </div>
        </Link>
    );
};

const quickLinkCardsDefinitions: CardsProps.CardDefinition<QuickLinkCardItem> = {
    header: (item) => <QuickLinkCardContent item={item} />,
};

export interface QuickLinkSectionProps {
    cardItems: QuickLinkCardItem[];
}

export const QuickLinksSection: FunctionComponent<QuickLinkSectionProps> = ({ cardItems }) => (
    <div data-testid={'quicklink-section'} className="custom-home-main-content-area">
        <Cards cardDefinition={quickLinkCardsDefinitions} items={cardItems} cardsPerRow={quickLinkCardsPerRow} />
    </div>
);
