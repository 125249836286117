import React, { Component } from 'react';
import { Box, Button, ColumnLayout, Container, ExpandableSection, Header, Icon, Spinner, Flashbar } from '@amzn/awsui-components-react-v3';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ApiHandler from '../../api/experiment-service/handler/lems-api-handler-impl';
import { LimestoneExperiment, LimestoneExperimentBoundaries } from '../../interfaces/LimestoneExperiment';
import { IButtonHandler } from '../../interfaces/IButtonHandler';
import * as NOTIFICATION_MESSAGES from '../../constants/display/flashbar-messages';
import * as FormUtils from '../../utils/form-utils';
import * as UrlUrils from '../../utils/url-utils';
import { LemsApiHandler } from '../../api/experiment-service/handler/lems-api-handler';
import { PageProps } from '../../interfaces/IProps';
import { DisplayMode } from '../../interfaces/FormAttribute';
import { UserInputModal } from '../../common/UserInputModal';
import { ApproveExperimentModalAttributes, RejectExperimentModalAttributes } from '../../constants/display/modal-constants';
import { handleErrorResponse } from '../../utils/error-handler-utils';
import { Approver } from '../../interfaces/Approver';
import { ApprovalStatusType, ApprovalStatus } from '../../enums/ApprovalStatusType';
import { ApproverRoleType, ApproverRole } from '../../enums/ApproverRoleType';
import { RejectionReason } from '../../form/attributes/RejectionReason';
import { DisplayAttribute } from '../../interfaces/DisplayAttribute';
import { AttributeLabels, LIMESTONE_ADMIN_ALIAS } from '../../constants/display/string-constants';
import { approversQueue } from '../index';
import { ExperimentStatusType } from '../../enums/ExperimentStatus';
import {
    Marketplace,
    Title,
    Description,
    ProductFamily,
    PrimaryBusinessGroup,
    SecondaryBusinessGroups,
    PrimaryOwner,
    SecondaryOwners,
    Observers,
    Tags,
    RablRegionId,
    Discriminator,
    StartDate,
    EndDate,
    AdditionalCustomMetricsField,
    RegionDefinitionType,
} from '../../form/attributes';
import { ExperimentSelectionSection } from '../ExperimentDetails/ExperimentSelectionSection';

export interface ApproverDetailPageProps extends RouteComponentProps, PageProps {}

export interface ApproverDetailPageState {
    experimentId: string;
    experimentIntegerId: number;
    experiment: LimestoneExperiment;
    downloadButtonLoading: boolean;
    approveButtonDisabled: boolean;
    approveButtonLoading: boolean;
    showApproveModal: boolean;
    rejectButtonDisabled: boolean;
    rejectButtonLoading: boolean;
    showRejectModal: boolean;
    invalidPageStateModalReason: string;
    showInvalidPageStateFlashbar: boolean;
    rejectionReason: DisplayAttribute;
    isRejectModalValid: boolean;
    approverAlias: string;
    approvalType: ApproverRoleType;
    showSpinner: boolean;
}

export class ApproverDetailPage extends Component<ApproverDetailPageProps, ApproverDetailPageState> {
    private readonly buttonHandlers: any;
    private readonly approveModalHandlers: IButtonHandler;
    private readonly rejectModalHandlers: IButtonHandler;

    /** Experiment Service handler instance which provides api to get the experiment data from the backend */
    public experimentServiceAPI: LemsApiHandler;

    private static INVALID_EXPERIMENT_STATUS_REASON = 'This experiment is not in the stage which requires approvals.';
    private static UNAUTHORIZED_APPROVER_REASON = 'You are not authorized to access this page.';
    private static INVALID_EXPERIMENT_ID_REASON = 'The experiment ID provided in the URL does not exist, please enter a valid experiment ID.';

    public constructor(props: any) {
        super(props);

        this.experimentServiceAPI = new ApiHandler(props.realm);

        this.state = {
            experimentId: '',
            experimentIntegerId: -1,
            showSpinner: true,
            experiment: FormUtils.createEmptyLimestoneExperiment(),
            downloadButtonLoading: false,
            approveButtonDisabled: true,
            approveButtonLoading: false,
            showApproveModal: false,
            rejectButtonDisabled: true,
            rejectButtonLoading: false,
            showRejectModal: false,
            isRejectModalValid: true,
            approverAlias: '',
            invalidPageStateModalReason: ApproverDetailPage.INVALID_EXPERIMENT_ID_REASON,
            showInvalidPageStateFlashbar: true,
            approvalType: ApproverRoleType.BUSINESS,
            rejectionReason: new DisplayAttribute(AttributeLabels.REJECTION_REASON),
        };

        this.experimentServiceAPI = new ApiHandler(props.realm);

        this.buttonHandlers = {
            approveExperiment: () => this.setState({ showApproveModal: true }),
            rejectExperiment: () => this.setState({ showRejectModal: true })
        };

        this.approveModalHandlers = {
            dismiss: () => this.setState({ showApproveModal: false }),
            submit: () => this.submitApproval()
        };

        this.rejectModalHandlers = {
            dismiss: () => this.setState({ showRejectModal: false }),
            submit: () => this.submitRejection()
        };
    }

    componentDidMount = async() => {
        const { experimentId, experimentIntegerId } = UrlUrils.parseQueryParametersFromUrl(new URL(window.location.href));
        this.setState({ showSpinner: true });

        if (experimentId && experimentIntegerId) {
            this.setState({ experimentId, experimentIntegerId });

            const experiment = await this.experimentServiceAPI.readExperiment(experimentId, experimentIntegerId)
                .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.readExperiment.FAIL!));

            if (experiment) {
                if (experiment.currentStatus.currentStatus.payloadValue !== ExperimentStatusType.AWAITING_APPROVALS) {
                    this.setState({
                        showSpinner: false,
                        showInvalidPageStateFlashbar: true,
                        invalidPageStateModalReason: ApproverDetailPage.INVALID_EXPERIMENT_STATUS_REASON
                    });
                } else {
                    await this.experimentServiceAPI.getAllExperimentBoundaries(experimentId)
                        .then((response: LimestoneExperimentBoundaries|undefined) => {
                            experiment.regionSelection = response!;
                            this.setState({ approveButtonDisabled: false, rejectButtonDisabled: false });
                        })
                        .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.getExperimentBoundaries.FAIL!))
                        .finally(() => this.setState({ showSpinner: false, experiment }));

                    await this.experimentServiceAPI.getAllExperimentApprovers(experimentId)
                        .then((approvers: Approver[]) => {
                            if (this.props.userAttributes?.isAdmin && approvers.filter((approver) => this.isApproverValidForApproving(approver, LIMESTONE_ADMIN_ALIAS)).length > 0) {
                                this.setState({ approverAlias: LIMESTONE_ADMIN_ALIAS, approvalType: ApproverRoleType.LIMESTONE, showInvalidPageStateFlashbar: false });
                            } else if (approvers.filter((approver) => this.isApproverValidForApproving(approver, this.props.userAttributes?.username!)).length > 0) {
                                this.setState({ approverAlias: this.props.userAttributes?.username!, approvalType: ApproverRoleType.BUSINESS, showInvalidPageStateFlashbar: false });
                            } else {
                                this.setState({ showInvalidPageStateFlashbar: true, invalidPageStateModalReason: ApproverDetailPage.UNAUTHORIZED_APPROVER_REASON });
                            }
                        })
                        .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.getAllExperimentBoundaries.FAIL!))
                        .finally(() => this.setState({ showSpinner: false }));
                }
            }
        }
    }

    submitApproval = async() => {
        this.setState({ rejectButtonDisabled: true, approveButtonLoading: true, showApproveModal: false });

        await this.experimentServiceAPI.submitApprovalDecision(this.state.experimentId, this.state.approvalType, this.state.approverAlias, ApprovalStatusType.APPROVED)
            .then(() => {
                this.props.setNotification!(NOTIFICATION_MESSAGES.submitApprovalDecision.SUCCESS);
                this.props.history.push(approversQueue.path);
            })
            .catch((error: any) => {
                handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.submitApprovalDecision.FAIL!);
                this.setState({ rejectButtonDisabled: false, approveButtonDisabled: false, approveButtonLoading: false });
            });
    }

    submitRejection = async() => {
        if (this.state.rejectionReason.isValid) {
            this.setState({ rejectButtonLoading: true, showRejectModal: false, approveButtonDisabled: true, isRejectModalValid: true });

            await this.experimentServiceAPI.submitApprovalDecision(this.state.experimentId, this.state.approvalType, this.state.approverAlias, ApprovalStatusType.REJECTED, this.state.rejectionReason.payloadValue)
                .then(() => {
                    this.props.setNotification!(NOTIFICATION_MESSAGES.submitApprovalDecision.SUCCESS);
                    this.props.history.push(approversQueue.path);
                })
                .catch((error: any) => {
                    handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.submitApprovalDecision.FAIL!);
                    this.setState({ rejectButtonDisabled: false, rejectButtonLoading: false, approveButtonDisabled: false });
                });
        } else {
            this.setState({ isRejectModalValid: false });
        }
    }

    updateRejectionReason = async(_fieldId: string, payloadValue: any, displayValue: string, isValid: boolean) => {
        const rejectionReason = this.state.rejectionReason;
        rejectionReason.updateAttributeDetails(isValid, payloadValue, displayValue);
        this.setState({ rejectionReason });
    }

    isApproverValidForApproving = (approver: Approver, expectedAlias: string) => {
        return approver.alias === expectedAlias && approver.status === ApprovalStatus.PENDING_RESPONSE;
    }

    render() {
        const rejectionReasonField = (
            <RejectionReason
                data-testid='approver-rejection-reason-input'
                displayMode={DisplayMode.CREATE}
                updateFormState={this.updateRejectionReason}
                initialValue={this.state.rejectionReason.displayValue}
            />
        );

        const rejectModalErrorMessage = !this.state.isRejectModalValid && (
            <span className="awsui-util-status-negative"><Icon name="status-warning" />Please enter a rejection reason</span>
        );

        const rejectModalContent = (
            <>
                <>{rejectionReasonField}</>
                <>{rejectModalErrorMessage}</>
            </>);

        const actionStripe = (
            <Box margin={{ 'bottom':'m','top':'xxs' }}>
                <div className="awsui-util-action-stripe-large">
                    <div className="awsui-util-action-stripe-title">
                        <Box variant="h1">{`${this.state.experiment.metadata.title.displayValue} (ID: ${this.state.experiment.experimentIntegerId})`}</Box>
                        <Box variant="h2">{'Approval Type: '}<span>{ApproverRole[this.state.approvalType]}</span></Box>
                    </div>
                </div>
            </Box>);

        const modals = (
            <>
                <UserInputModal
                    visible={this.state.showApproveModal}
                    buttonHandlers={this.approveModalHandlers}
                    {...ApproveExperimentModalAttributes}
                />

                <UserInputModal
                    visible={this.state.showRejectModal}
                    buttonHandlers={this.rejectModalHandlers}
                    content={rejectModalContent}
                    {...RejectExperimentModalAttributes}
                />
            </>
        );

        let content: JSX.Element;
        if (this.state.showSpinner) {
            content = (<Spinner data-testid={'approver-detail-page-spinner'} size='large' />);
        } else if (this.state.showInvalidPageStateFlashbar) {
            content = <Flashbar data-testid={'invalid-state-flashbar'} items={[{
                header: `Unauthorized Request for Experiment Approvals: ${this.state.experiment.metadata.title.displayValue} (ID: ${this.state.experimentIntegerId})`,
                content: this.state.invalidPageStateModalReason,
                type: 'warning'
            }]}/>;
        } else {
            content = (
                <>
                    {actionStripe}

                    <div style={{ padding: 20 }}>
                        <ExpandableSection header={<Header variant="h2">Experiment Definition</Header>} variant='container' defaultExpanded={true}>
                            <ColumnLayout columns={4} variant='text-grid'>
                                <Marketplace
                                    data-testid='marketplace-view'
                                    realm={this.props.realm}
                                    displayMode={DisplayMode.VIEW}
                                    initialValue={this.state.experiment.metadata.marketplace.payloadValue}
                                />
                                <Title
                                    data-testid='title-view'
                                    displayMode={DisplayMode.VIEW}
                                    initialValue={this.state.experiment.metadata.title.payloadValue}
                                />
                                <Description
                                    data-testid='description-view'
                                    displayMode={DisplayMode.VIEW}
                                    initialValue={this.state.experiment.metadata.description.payloadValue}
                                />
                                <ProductFamily
                                    data-testid='product-family-view'
                                    displayMode={DisplayMode.VIEW}
                                    initialValue={this.state.experiment.metadata.productFamily.payloadValue}
                                />
                                <PrimaryBusinessGroup
                                    data-testid='primary-business-group-view'
                                    displayMode={DisplayMode.VIEW}
                                    initialValue={this.state.experiment.metadata.primaryBusinessGroup.payloadValue}
                                />
                                <SecondaryBusinessGroups
                                    data-testid='secondary-business-groups-view'
                                    displayMode={DisplayMode.VIEW}
                                    initialValue={this.state.experiment.metadata.secondaryBusinessGroups.payloadValue}
                                />
                                <PrimaryOwner
                                    data-testid='primary-owner-view'
                                    displayMode={DisplayMode.VIEW}
                                    initialValue={this.state.experiment.metadata.primaryOwner.payloadValue}
                                />
                                <SecondaryOwners
                                    data-testid='secondary-owners-view'
                                    displayMode={DisplayMode.VIEW}
                                    initialValue={this.state.experiment.metadata.secondaryOwners.payloadValue}
                                />
                                <Observers
                                    data-testid='observers-view'
                                    displayMode={DisplayMode.VIEW}
                                    initialValue={this.state.experiment.metadata.observers.payloadValue}
                                />
                                <Tags
                                    data-testid='tags-view'
                                    displayMode={DisplayMode.VIEW}
                                    initialValue={this.state.experiment.metadata.tags.payloadValue}
                                />
                                <RablRegionId
                                    data-testid='rabl-region-id-view'
                                    displayMode={DisplayMode.VIEW}
                                    initialValue={this.state.experiment.metadata.rablRegionId.payloadValue}
                                />
                                <RegionDefinitionType
                                    data-testid='region-definition-type-dropdown'
                                    displayMode={DisplayMode.VIEW}
                                    initialValue={this.state.experiment.metadata.regionDefinitionType.payloadValue}
                                />
                                <Discriminator
                                    data-testid='discriminator-view'
                                    displayMode={DisplayMode.VIEW}
                                    initialValue={this.state.experiment.metadata.discriminator.payloadValue}
                                />
                                <StartDate
                                    data-testid='start-date-view'
                                    displayMode={DisplayMode.VIEW}
                                    initialValue={this.state.experiment.metadata.startDate.payloadValue}
                                />
                                <EndDate
                                    data-testid='end-date-view'
                                    displayMode={DisplayMode.VIEW}
                                    initialValue={this.state.experiment.metadata.endDate.payloadValue}
                                />
                                <AdditionalCustomMetricsField
                                    data-testid='additional-custom-metrics-dropdown'
                                    displayMode={DisplayMode.VIEW}
                                    initialValue={this.state.experiment.metadata.customMetrics.payloadValue}
                                />
                            </ColumnLayout>
                        </ExpandableSection>
                    </div>

                    <ExperimentSelectionSection
                        testId={'experiment-selection-section'}
                        realm={this.props.realm}
                        experimentId={this.state.experimentId}
                        marketplaceId={this.state.experiment.metadata.marketplace.payloadValue}
                        experimentStatus={this.state.experiment.currentStatus.currentStatus.payloadValue}
                        setNotification={this.props.setNotification!}
                        userAccessLevels={this.props.userAccessLevels}
                        pagePermissionsMap={this.props.permissionsMap}
                        experimentLifecycleType={this.state.experiment.metadata.experimentType.payloadValue}
                    />

                    <div style={{ padding: 20 }}>
                        <ExpandableSection header={<Header variant="h2">Experiment Boundaries</Header>} variant='container' defaultExpanded={true}>
                            <ColumnLayout columns={2} variant='text-grid'>
                                <div style={{ display: 'table' }}>
                                    <div data-testid={'display-wrapper'} style={{ display: 'table-cell' }}>
                                        <Box variant="awsui-key-label"><Box variant="strong"><u>{this.state.experiment.regionSelection.treatmentBoundaries.displayLabel}</u></Box></Box>
                                        <div>{this.state.experiment.regionSelection.treatmentBoundaries.displayValue}</div>
                                    </div>
                                </div>
                                <div style={{ display: 'table' }}>
                                    <div data-testid={'display-wrapper'} style={{ display: 'table-cell' }}>
                                        <Box variant="awsui-key-label"><Box variant="strong"><u>{this.state.experiment.metadata.regionCreationStatus.displayLabel}</u></Box></Box>
                                        <div>{this.state.experiment.metadata.regionCreationStatus.displayValue}</div>
                                    </div>
                                </div>
                            </ColumnLayout>
                        </ExpandableSection>
                    </div>

                    <div style={{ display: 'inline-block' }}>
                        <span style={{ padding: 10 }}>
                            <Button
                                variant='normal'
                                data-testid={'reject-experiment-button'}
                                loading={this.state.rejectButtonLoading}
                                disabled={this.state.rejectButtonDisabled}
                                onClick={this.buttonHandlers.rejectExperiment}
                            >Reject</Button>
                        </span>
                        <span style={{ padding: 10 }}>
                            <Button
                                variant='primary'
                                data-testid={'approve-experiment-button'}
                                loading={this.state.approveButtonLoading}
                                disabled={this.state.approveButtonDisabled}
                                onClick={this.buttonHandlers.approveExperiment}
                            >Approve</Button>
                        </span>
                    </div>

                    {modals}
                </>
            );
        }
        return (
            <Container>
                {content}
            </Container>);
    }
}

export default withRouter(ApproverDetailPage);
