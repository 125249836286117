import React from 'react';
import { Link } from 'react-router-dom';
import { approverDetailPage } from '../../pages';
import { ApproverExperimentAttributes } from '../../interfaces/Approver';
import { ApproverRole } from '../../enums/ApproverRoleType';
import { TableProps } from '@amzn/awsui-components-react-v3';

export const columnDefinitions: Array<TableProps.ColumnDefinition<ApproverExperimentAttributes>> = [
    {
        id: 'title',
        header: 'Title',
        cell: (item) => {
            return <Link to={`${approverDetailPage.path}?experimentId=${item.experimentId}&experimentIntegerId=${item.experimentIntegerId}`}>{item.title}</Link>;
        },
        width: 200
    },
    {
        id: 'Approval Type',
        header: 'Approval Type',
        cell: (item) => ApproverRole[item.approverType],
        width: 200
    },
    {
        id: 'experimentId',
        header: 'ExperimentId',
        cell: (item) => item.experimentId,
        width: 350
    },
    {
        id: 'productFamily',
        header: 'Product Family',
        cell: (item) => item.productFamily,
        width: 150
    },
    {
        id: 'primaryOwner',
        header: 'Primary Owner',
        cell: (item) => item.primaryOwner
    },
    {
        id: 'marketplace',
        header: 'Marketplace',
        cell: (item) => item.marketplace,
        width: 130
    },
];

export const columnOptions = [
    { id: 'title', label: 'Title', editable: false, visible: true },
    { id: 'approvalType', label: 'Approval Type', editable: false, visible: true },
    { id: 'experimentId', label: 'ExperimentId', editable: true, visible: false },
    { id: 'productFamily', label: 'Product Family', editable: true, visible: true },
    { id: 'primaryOwner', label: 'Primary Owner', editable: true, visible: true },
    { id: 'marketplace', label: 'Marketplace', editable: true, visible: true },
];

export const pageSizeOptions = [
    { value: 10, label: '10 experiments' },
    { value: 15, label: '15 experiments' },
    { value: 20, label: '20 experiments' }];
