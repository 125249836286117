export interface ExternalLink {
    text: string;
    href: string;
}

export const ExternalLinks: { [name: string]: ExternalLink } = {
    WIKI: { text: 'ReSES Wiki', href: 'https://w.amazon.com/bin/view/Limestone/' },
    GUIDE: { text: 'Guide', href: 'https://w.amazon.com/bin/view/Limestone/OnboardingGuide/' },
    EXPERIMENT_INTAKE: { text: 'Feedback', href: 'https://sim.amazon.com/issues/create?template=46ed5cc6-076e-4465-9368-a544a233378e' },
    MAILING_LIST: { text: 'Mailing List', href: 'https://email-list.corp.amazon.com/email-list/email-list.mhtml?action=search&name=reses-interests' },
    FEEDBACK_INTAKE: { text: 'Feature Request', href: 'https://sim.amazon.com/issues/create?template=d1f4a412-cdbd-43f0-87e9-d33e977dca74' },
    TT: { text: 'Trouble Ticket', href: 'https://tiny.amazon.com/785yvnus/tcorpamazissu' }
};