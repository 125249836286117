import React, { Component } from 'react';
import { Box, ColumnLayout, Container, ExpandableSection, Header, Spinner } from '@amzn/awsui-components-react-v3';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { LimestoneExperiment } from '../../interfaces/LimestoneExperiment';
import { IButtonHandler } from '../../interfaces/IButtonHandler';
import { EXPERIMENT_TREATMENT_COMPLETE_STATUSES, ExperimentStatusType } from '../../enums/ExperimentStatus';
import { LemsApiHandler } from '../../api/experiment-service/handler/lems-api-handler';
import LemsApiHandlerImpl from '../../api/experiment-service/handler/lems-api-handler-impl';
import { PageProps } from '../../interfaces/IProps';
import { RablRegionCreationStatusType } from '../../enums/RablRegionCreationStatus';
import { UserInputModal } from '../../common/UserInputModal';
import { EndExperimentModalAttributes, StartExperimentModalAttributes } from '../../constants/display/modal-constants';
import { handleErrorResponse } from '../../utils/error-handler-utils';
import { Approver } from '../../interfaces/Approver';
import { experimentDetailPage } from '../index';
import { ExperimentSelectionSection } from './ExperimentSelectionSection';
import { ExperimentLifecycleGraph } from './ExperimentLifecycleGraph';
import { ExperimentOfferDto, GetExperimentProgressDisplayResponse } from '../../api/experiment-service/lambda-model-types';
import { ExperimentRegionSection } from './ExperimentRegionSection';
import PageNotFound from '../ErrorPages/PageNotFound';
import { PermissionControlledButton } from '../../permissions/PermissionControlledButton';
import { addUserAccessLevelsFromExperiment } from '../../utils/auth-utils';
import { ActionType } from '../../enums/PermissionsModel';
import { PermissionControlledView } from '../../permissions/PermissionControlledView';
import * as NOTIFICATION_MESSAGES from '../../constants/display/flashbar-messages';
import * as FormUtils from '../../utils/form-utils';
import * as UrlUrils from '../../utils/url-utils';
import { ProgressStepper, ProgressStepConfiguration } from '../../common/ProgressStepper';
import { ExperimentDefinitionSection } from './ExperimentDefinitionSection';
import { ExperimentNavigationBar } from './ExperimentNavigationBar';
import { ExperimentDecisionSection } from './ExperimentDecisionSection';

export interface ExperimentDetailPageProps extends RouteComponentProps, PageProps {}

export interface ExperimentDetailPageState {
    showSpinner: boolean;
    experimentId: string;
    experimentIntegerId: number;
    experiment: LimestoneExperiment;
    approvers: Approver[];
    offers: ExperimentOfferDto[];
    downloadButtonLoading: boolean;
    startButtonDisabled: boolean;
    startButtonLoading: boolean;
    endButtonDisabled: boolean;
    endButtonLoading: boolean;
    showEndExperimentModal: boolean;
    showStartExperimentModal: boolean;
    viewMetricsButtonDisabled: boolean;
    asinActionsButtonDisabled: boolean;
    currentProgressStatusRank: number;
    experimentProgressStatuses: ProgressStepConfiguration[];
    experimentDashboardButtonDisabled: boolean;
}

export class ExperimentDetailPage extends Component<ExperimentDetailPageProps, ExperimentDetailPageState> {
    private readonly buttonHandlers: any;
    private readonly endExperimentModalHandlers: IButtonHandler;
    private readonly startExperimentModalHandlers: IButtonHandler;

    /** Experiment Service handler instance which provides api to get the experiment data from the backend */
    public experimentServiceAPI: LemsApiHandler;

    public constructor(props: any) {
        super(props);

        this.experimentServiceAPI = new LemsApiHandlerImpl(props.realm);

        this.state = {
            experimentId: '',
            experimentIntegerId: -1,
            showSpinner: false,
            experiment: FormUtils.createEmptyLimestoneExperiment(),
            approvers: [],
            offers: [],
            downloadButtonLoading: false,
            endButtonDisabled: true,
            startButtonDisabled: true,
            startButtonLoading: false,
            endButtonLoading: false,
            showEndExperimentModal: false,
            showStartExperimentModal: false,
            asinActionsButtonDisabled: true,
            experimentDashboardButtonDisabled: true,
            viewMetricsButtonDisabled: true,
            currentProgressStatusRank: 0,
            experimentProgressStatuses: []
        };

        this.buttonHandlers = {
            start: () => this.setState({ showStartExperimentModal: true }),
            end: () => this.setState({ showEndExperimentModal: true })
        };

        this.startExperimentModalHandlers = {
            dismiss: () => this.setState({ showStartExperimentModal: false }),
            submit: () => this.startExperiment()
        };

        this.endExperimentModalHandlers = {
            dismiss: () => this.setState({ showEndExperimentModal: false }),
            submit: () => this.endExperiment()
        };
    }

    /* istanbul ignore next */
    componentDidMount = async() => {
        const { experimentId, experimentIntegerId } = UrlUrils.parseQueryParametersFromUrl(new URL(window.location.href));
        this.setState({ showSpinner: true });

        if (experimentId && experimentIntegerId) {
            this.setState({ experimentId, experimentIntegerId });

            const experiment = await this.experimentServiceAPI.readExperiment(experimentId, experimentIntegerId)
                .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.readExperiment.FAIL!));

            if (experiment) {
                this.setState({ experiment });
                this.setButtonsDisabledState(experiment);

                await this.experimentServiceAPI.getExperimentProgressDisplay(experimentId)
                    .then((response: GetExperimentProgressDisplayResponse) => {
                        this.setState({
                            currentProgressStatusRank: response.currentStatusRank,
                            experimentProgressStatuses: response.progressStatusList.map((status) => {
                                return {
                                    label: status.displayName,
                                    executionTimeInMins: status.executionTimeInMins
                                };
                            })
                        });
                    })
                    .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.getExperimentProgressDisplay.FAIL!));

                await this.experimentServiceAPI.getAllExperimentApprovers(experimentId)
                    .then((response: Approver[]) => this.setState({ approvers: response }))
                    .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.getAllExperimentBoundaries.FAIL!))
                    .finally(() => this.setState({ showSpinner: false }));
            }
        }
    }

    setButtonsDisabledState = (experiment: LimestoneExperiment) => {
        const experimentStatus: ExperimentStatusType = experiment.currentStatus.currentStatus.payloadValue;
        if (experimentStatus === ExperimentStatusType.APPROVED && experiment.metadata.regionCreationStatus.payloadValue === RablRegionCreationStatusType.LIVE) {
            this.setState({ startButtonDisabled: false });
        }

        if (experimentStatus === ExperimentStatusType.RUNNING) {
            this.setState({ endButtonDisabled: false, asinActionsButtonDisabled: false, viewMetricsButtonDisabled: false });
        }

        if (experimentStatus === ExperimentStatusType.COMPLETE) {
            this.setState({ experimentDashboardButtonDisabled: false });
        }
    }

    startExperiment = async() => {
        this.setState({ startButtonLoading: true, showStartExperimentModal: false });

        await this.experimentServiceAPI.startExperiment(this.state.experimentId, this.state.experiment.metadata.experimentType.payloadValue)
            .then(() => this.props.setNotification!(NOTIFICATION_MESSAGES.startExperiment.SUCCESS))
            .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.startExperiment.FAIL!))
            .finally(() => this.setState({ startButtonLoading: false, startButtonDisabled: true }));
    }

    endExperiment = async() => {
        this.setState({ endButtonLoading: true, showEndExperimentModal: false });

        await this.experimentServiceAPI.endExperiment(this.state.experimentId)
            .then(() => this.props.setNotification!(NOTIFICATION_MESSAGES.endExperiment.SUCCESS))
            .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.endExperiment.FAIL!))
            .finally(() => this.setState({ endButtonLoading: false, endButtonDisabled: true }));
    }

    render() {
        const userAccessLevels = addUserAccessLevelsFromExperiment(this.state.experiment.metadata, this.props.userAttributes?.username!, this.props.userAccessLevels);

        const additionalExperimentNavigationButtons = [
            <PermissionControlledButton
                userAccessLevels={this.props.userAccessLevels}
                actionType={ActionType.ADMIN_ACTION}
                pagePermissionsMap={this.props.permissionsMap}
                testId={'start-experiment-button'}
                hideIfNotAuthorized={true}
                buttonProps={{
                    loading: this.state.startButtonLoading,
                    disabled: this.state.startButtonDisabled,
                    onClick: this.buttonHandlers.start
                }}
                key='start-experiment-button'
            >Start Experiment</PermissionControlledButton>,

            <PermissionControlledButton
                userAccessLevels={this.props.userAccessLevels}
                actionType={ActionType.ADMIN_ACTION}
                pagePermissionsMap={this.props.permissionsMap}
                testId={'end-experiment-button'}
                hideIfNotAuthorized={true}
                buttonProps={{
                    loading: this.state.endButtonLoading,
                    disabled: this.state.endButtonDisabled,
                    onClick: this.buttonHandlers.end
                }}
                key='end-experiment-button'
            >End Experiment</PermissionControlledButton>
        ];

        const actionStripe = <ExperimentNavigationBar
            experiment={this.state.experiment}
            additionalButtons={additionalExperimentNavigationButtons}
            realm={this.props.realm}
            userAccessLevels={this.props.userAccessLevels}
            currentPage={experimentDetailPage}
        />;

        const modals = (
            <>
                <UserInputModal
                    visible={this.state.showStartExperimentModal}
                    buttonHandlers={this.startExperimentModalHandlers}
                    {...StartExperimentModalAttributes}
                />

                <UserInputModal
                    visible={this.state.showStartExperimentModal}
                    buttonHandlers={this.startExperimentModalHandlers}
                    {...StartExperimentModalAttributes}
                />

                <UserInputModal
                    visible={this.state.showEndExperimentModal}
                    buttonHandlers={this.endExperimentModalHandlers}
                    {...EndExperimentModalAttributes}
                />
            </>
        );

        let content: JSX.Element;
        if (this.state.showSpinner) {
            content = (<Spinner size='large' />);
        } else if (this.state.experimentId === '') {
            content = (<PageNotFound />);
        } else {
            content = (<>
                {actionStripe}

                <ProgressStepper
                    testId='experiment-progress-display'
                    steps={this.state.experimentProgressStatuses}
                    activeStepIndex={this.state.currentProgressStatusRank}
                />

                <ExperimentDefinitionSection
                    testId={'experiment-definition-section'}
                    realm={this.props.realm}
                    experimentId={this.state.experimentId}
                    metadata={this.state.experiment.metadata}
                    userAccessLevels={this.props.userAccessLevels}
                    pagePermissionsMap={this.props.permissionsMap}
                    experimentStatus={this.state.experiment.currentStatus.currentStatus.payloadValue}
                />

                <ExperimentSelectionSection
                    testId={'experiment-selection-section'}
                    realm={this.props.realm}
                    experimentLifecycleType={this.state.experiment.metadata.experimentType.payloadValue}
                    experimentId={this.state.experimentId}
                    marketplaceId={this.state.experiment.metadata.marketplace.payloadValue}
                    experimentStatus={this.state.experiment.currentStatus.currentStatus.payloadValue}
                    setNotification={this.props.setNotification!}
                    userAccessLevels={this.props.userAccessLevels}
                    pagePermissionsMap={this.props.permissionsMap}
                    selectionFinalized={this.state.experiment.selectionFinalized}
                />

                <ExperimentRegionSection
                    testId={'experiment-region-section'}
                    realm={this.props.realm}
                    experiment={this.state.experiment}
                    experimentId={this.state.experimentId}
                    setNotification={this.props.setNotification!}
                    userAccessLevels={this.props.userAccessLevels}
                    pagePermissionsMap={this.props.permissionsMap}
                />

                {EXPERIMENT_TREATMENT_COMPLETE_STATUSES.map((status) => status.toString()).includes(this.state.experiment.currentStatus.currentStatus.payloadValue)
                    && <ExperimentDecisionSection
                        testId={'experiment-decision-section'}
                        realm={this.props.realm}
                        experiment={this.state.experiment}
                        experimentId={this.state.experimentId}
                        setNotification={this.props.setNotification!}
                        userAccessLevels={this.props.userAccessLevels}
                        pagePermissionsMap={this.props.permissionsMap}
                        isAdmin={this.props.userAttributes!.isAdmin}
                    />
                }

                <div style={{ padding: 20 }}>
                    <ExpandableSection header={<Header variant='h2'>Experiment Approvals</Header>} variant='container' data-testid={'experiment-approval-status-section'} defaultExpanded={true}>
                        <ColumnLayout columns={4} variant='text-grid'>
                            <Box variant='awsui-key-label'><Box variant='strong'><u>{'Approver Alias'}</u></Box></Box>
                            <Box variant='awsui-key-label'><Box variant='strong'><u>{'Approver Role'}</u></Box></Box>
                            <div><Box variant='strong'><u>{'Approval Status'}</u></Box></div>
                            <div><Box variant='strong'><u>{'Reason'}</u></Box></div>
                        </ColumnLayout>
                        {this.state.approvers.map((approver: Approver) => {
                            return (
                                <div key={approver.alias}>
                                    <ColumnLayout columns={4} variant='text-grid'>
                                        <Box variant='awsui-key-label'><Box variant='strong'>{approver.alias}</Box></Box>
                                        <Box variant='awsui-key-label'>{approver.role}</Box>
                                        <div>{approver.status}</div>
                                        <div>{approver.rejectionReason}</div>
                                    </ColumnLayout>
                                </div>
                            );
                        })}
                    </ExpandableSection>
                </div>

                <PermissionControlledView
                    hideIfNotAuthorized={true}
                    actionType={ActionType.ADMIN_ACTION}
                    userAccessLevels={this.props.userAccessLevels}
                    pagePermissionsMap={this.props.permissionsMap}
                >
                    <div style={{ padding: 20 }}>
                        <ExpandableSection header={<Header variant='h2'>Lifecycle Graph</Header>} variant='container' defaultExpanded={true}>
                            <ExperimentLifecycleGraph
                                testId={'lifecycle-graph'}
                                realm={this.props.realm}
                                experimentId={this.state.experimentId}
                                setNotification={this.props.setNotification!}
                            />
                        </ExpandableSection>
                    </div>
                </PermissionControlledView>

                {modals}
            </>);
        }

        return (
            <PermissionControlledView
                userAccessLevels={userAccessLevels}
                pagePermissionsMap={this.props.permissionsMap}
            >
                <div style={{ padding: 5 }}>
                    <Container>
                        {content}
                    </Container>
                </div>
            </PermissionControlledView>
        );
    }
}

export default withRouter(ExperimentDetailPage);
