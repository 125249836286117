import { DisplayAttribute } from '../../../interfaces/DisplayAttribute';
import { LimestoneExperimentBoundaries } from '../../../interfaces/LimestoneExperiment';
import * as LambdaModel from '../lambda-model-types';
import { BoundarySet } from '../../../interfaces/BoundarySet';

export const convertGetBoundariesResponseToDisplay = (response: LambdaModel.GetExperimentBoundariesResponse): LimestoneExperimentBoundaries => {
    const boundaries = response.boundaries.map((experimentBoundaryDto) => {
        if (experimentBoundaryDto.boundary.boundaryDescription) {
            return `${experimentBoundaryDto.boundary.boundaryId}: ${experimentBoundaryDto.boundary.boundaryDescription}`;
        }

        return experimentBoundaryDto.boundary.boundaryId;
    });

    return {
        treatmentBoundaries: new DisplayAttribute('Treatment Boundaries', true, boundaries, boundaries.join(', '))
    };
};

export const convertBoundarySetDtosToBoundarySet = (boundarySetDtos: LambdaModel.BoundarySetDto[]): BoundarySet[] => {
    return boundarySetDtos.map((boundarySetDto) => {
        return {
            boundaries: new Map(Object.entries(boundarySetDto.boundaries)),
            attributes: new Map(Object.entries(boundarySetDto.attributes)),
            isManuallyUploaded: boundarySetDto.isManuallyUploaded
        };
    });
};

/**
 * Convert boundaryName stored in LEMS (e.g. REGION_DE_DE129) to RMS boundaryName (e.g. DE129)
 * @param boundaryName
 */
export const removeLemsBoundaryPrefix = (boundaryName: string) => {
    return boundaryName.split('_').slice(2).join('_');
};
