import React from 'react';
import { Icon } from '@amzn/awsui-components-react-v3';
import { DisplayMode } from '../../../interfaces/FormAttribute';
import { FileValidationRules } from '../../../validation/validation-rules';
import { ExperimentAttributeProps } from '../../ExperimentAttribute';
import { FileField, FileFieldConfig } from '../../fields/FileField';

/**
 * File defining a region definition that includes a list of boundaries and their zip or postal codes.
 */
export class RegionDefinitionFile extends FileField<ExperimentAttributeProps> {
    protected displayConfig: FileFieldConfig;
    protected validationRules: FileValidationRules;

    constructor(props: ExperimentAttributeProps) {
        super(props);

        this.validationRules = {
            required: true,
            allowedOptions: ['text/csv'],
            maxSizeInBytes: 4000000000,
            duplicateCheck: false,
            emptyCheck: true,
        };

        this.displayConfig = {
            label: 'Region Definition File',
            editable: false,
            touched: false,
            hintText: 'Please upload the region definition files in csv. See https://w.amazon.com/bin/view/Limestone/RXRegionManagementService/RegionDefinitionFile/ for detailed format.',
            value: []
        };

        this.fileConfig = {
            id: 'custom-file',
            custom: true,
            label: props.initialFileName?.length ? props.initialFileName : 'Upload Region Definition File',
            onChange: (event: CustomEvent) => this.onChangeEvent(event, 'Region Definition File')
        };

        this.summaryDisplay = null;

        this.state = {
            displayValue: '',
            displayMode: props.displayMode ? props.displayMode : DisplayMode.CREATE,
            editInProgress: false,
            validity: false
        };
    }

    componentDidMount = async() => {
        if (this.props.initialValue) {
            this.setValueFromPayload(this.props.initialValue);
        }

        this.forceUpdate();
    }

    setValue = async(newValue: File) => {
        this.displayConfig.value = newValue;

        const { isValid, errorText } = this.validateFile(newValue, this.validationRules);

        const fileName = newValue.name;

        this.displayConfig = {
            ...this.displayConfig,
            touched: true,
            errorText
        };

        this.fileConfig = {
            ...this.fileConfig,
            label: fileName,
            isInvalid: !isValid
        };

        this.summaryDisplay = fileName && isValid ? (
            <div style={{ marginTop: '5px' }}>
                <span className="awsui-util-status-positive">
                    <Icon name="status-positive" /> {`File ${fileName} is now available`}
                    <div style={{ marginTop: '5px' }}>{`Size: ${newValue.size}B`}</div>
                </span>
            </div>
        ) : null;

        await new Promise((resolve) => this.setState({ displayValue: fileName, validity: isValid }, () => resolve(newValue)));
    }

    setValueFromPayload = async(newContent: string[] | File) => {
        if (newContent instanceof File) {
            this.setValue(newContent);
        } else {
            const fileName = this.getDisplayValue() ? this.getDisplayValue() : 'selection_file.csv';
            const file = new File(newContent.map((line) => line + '\n'), fileName, { type: 'text/csv' });
            this.setValue(file);
        }
    }

    validateFile = (value: File, validationRules: FileValidationRules) => {
        let isValid: boolean = true;
        const errors: string[] = [];

        if (validationRules) {
            if (validationRules.required && !value) {
                isValid = false;
                errors.push('File is required');
            }

            if (validationRules.allowedOptions && value && !validationRules.allowedOptions.includes(value.type)) {
                isValid = false;
                errors.push('This is not a valid file type');
            }

            if (validationRules.maxSizeInBytes && value && value.size > validationRules.maxSizeInBytes) {
                isValid = false;
                errors.push('Max File Upload Size is ' + validationRules.maxSizeInBytes / 1000000 + ' MB');
            }
        }

        const errorText = errors.length !== 0 ? errors.join(' and ') : undefined;

        return { isValid, errorText };
    }

    getPayloadValue = () => this.displayConfig.value;

    renderViewMode = () => <></>;
}
