import { InputField, InputFieldConfig } from '../../fields/InputField';
import { ExperimentAttributeProps } from '../../ExperimentAttribute';
import { DisplayMode } from '../../../interfaces/FormAttribute';

/**
 * Region definition name(e.g., ZIP3, NUTS3).
 */
export class RegionDefinitionTypeName extends InputField<ExperimentAttributeProps> {
    protected displayConfig: InputFieldConfig;

    constructor(props: ExperimentAttributeProps) {
        super(props);

        const { initialValue, displayMode } = props;

        this.validationRules = { required: true, maxLength: 128 };
        const { isValid, errorText } = this.validate(initialValue, this.validationRules);

        this.displayConfig = {
            label: 'Region Definition Type Name',
            editable: true,
            type: 'text',
            placeholder: '20 char limit',
            touched: false,
            hintText: 'Required *',
            errorText,
            onChange: (event) => this.onChangeEvent(event as CustomEvent, 'Region Definition Type Name'),
            value: props.initialValue,
        };

        this.state = {
            displayValue: '',
            displayMode: displayMode ? displayMode : DisplayMode.CREATE,
            validity: isValid
        };
    }

    componentDidMount() {
        if (this.props.initialValue) {
            this.setValueFromPayload(this.props.initialValue);
        }
    }
}
