import { ExperimentAttributeProps } from '../ExperimentAttribute';
import { MetadataAttribute } from '../../enums/CommonTypes';
import { DisplayMode } from '../../interfaces/FormAttribute';
import { AttributeLabels } from '../../constants/display/string-constants';
import { DateField, DateFieldConfig } from '../fields/DateField';
import { LemsApiHandler } from '../../api/experiment-service/handler/lems-api-handler';
import ApiHandler from '../../api/experiment-service/handler/lems-api-handler-impl';
import { Realm } from '../../api/api-constants';
import { constructDate } from '../../utils/date-utils';

export class StartDate extends DateField {
    protected displayConfig: DateFieldConfig;
    private experimentServiceAPI: LemsApiHandler;

    constructor(props: ExperimentAttributeProps) {
        super(props);

        const { displayMode } = props;

        const realm = props.realm ? props.realm : Realm.NA;
        this.experimentServiceAPI = new ApiHandler(realm);

        this.validationRules = { required: true };

        this.displayConfig = {
            label: AttributeLabels.START_DATE,
            editable: props.editable === true,
            touched: false,
            hintText: 'Required *',
            isDateEnabled: (date: Date) => this.isDateEnabled(date),
            onChange: (event) => this.onChangeEvent(event as CustomEvent, MetadataAttribute.START_DATE),
            value: props.initialValue,
        };

        this.state = {
            displayValue: '',
            displayMode: displayMode ? displayMode : DisplayMode.CREATE,
            validity: true
        };
    }

    componentDidMount() {
        if (this.state.displayMode === DisplayMode.CREATE) {
            this.setValueFromPayload('');
            this.props.updateFormState!(MetadataAttribute.START_DATE, this.getPayloadValue(), this.getDisplayValue(), false);
        } else if (this.props.initialValue) {
            this.setValueFromPayload(this.props.initialValue);
        }
    }

    isDateEnabled(date: Date): boolean {
        const earliestDate = new Date();
        earliestDate.setDate(earliestDate.getDate() + 7);
        const latestDate = new Date();
        latestDate.setDate(latestDate.getDate() + 365);
        if (date < earliestDate || date > latestDate) {
            return false;
        }

        if (date.getDay() === 6 || date.getDay() === 0) {
            return false;
        }

        if (this.props.unavailableDates) {
            for (const runDates of this.props.unavailableDates!) {
                if (date >= constructDate(runDates.startDate) && date <= constructDate(runDates.endDate)) {
                    return false;
                }
            }
        }

        return true;
    }

    editSubmitButtonClicked = async(): Promise<boolean> => {
        this.setState({ editInProgress: true });
        const response = await this.experimentServiceAPI.updateExperimentStartDate(this.props.experimentId!, this.getPayloadValue())
            .finally(() => {
                this.setState({ editInProgress: false, displayMode: DisplayMode.VIEW });
            });

        return response;
    }
}
