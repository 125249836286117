import React, { Component } from 'react';
import { GccpMetricRequest, GccpMetricRequestAttributes } from '../../interfaces/GccpMetricRequest';
import { Button, Checkbox, ColumnLayout } from '@amzn/awsui-components-react-v3';
import { CustomerTriggersFile } from './CustomerTriggersFile';
import { DisplayMode } from '../../interfaces/FormAttribute';
import { CustomerTriggersTypeField } from './CustomerTriggersTypeField';
import { GccpMetricRequestStartDate } from './GccpMetricRequestStartDate';
import { GccpMetricRequestEndDate } from './GccpMetricRequestEndDate';
import { LimestoneExperiment } from '../../interfaces/LimestoneExperiment';

export interface GccpMetricRequestFieldProps {
    index: number;
    gccpMetricRequest: GccpMetricRequest;
    updateFormState: (gccpMetricRequest: GccpMetricRequest, index: number) => void;
    deleteGccpMetricRequest: (index: number) => void;
    saveGccpMetricRequest?: (index: number) => void;
    experiment: LimestoneExperiment;
}

export interface GccpMetricRequestFieldState {
    gccpMetricRequest: GccpMetricRequest;
    saveAttempted: boolean;
    customerTriggersIsValid: boolean;
    useDefaultCustomerTriggersType: boolean;
    experiment: LimestoneExperiment;
    useResesDefaultScienceModel: boolean;
}

export class GccpMetricRequestField extends Component<GccpMetricRequestFieldProps, GccpMetricRequestFieldState> {
    constructor(props: GccpMetricRequestFieldProps) {
        super(props);

        this.state = {
            gccpMetricRequest: props.gccpMetricRequest,
            saveAttempted: false,
            customerTriggersIsValid: !props.gccpMetricRequest.editing,
            useDefaultCustomerTriggersType: true,
            experiment: props.experiment,
            useResesDefaultScienceModel: true,
        };
    }

    updateCustomerTriggersFile = (fieldId: string, payloadValue: any, displayValue: string, isValid: boolean) => {
        this.updateGccpMetricRequest(GccpMetricRequestAttributes.CUSTOMER_TRIGGERS_FILE, payloadValue);
        this.setState({ customerTriggersIsValid: isValid });
    }

    updateGccpMetricRequest = (fieldId: string, value: any) => {
        this.setState(({ gccpMetricRequest }) => ({
            gccpMetricRequest: {
                ...gccpMetricRequest,
                [fieldId]: value
            }
        }), () => {
            this.props.updateFormState(this.state.gccpMetricRequest, this.props.index);
        });
    }

    saveGccpMetricRequest = () => {
        this.setState({ saveAttempted: true });
        if (this.startEndDateValid() && this.state.customerTriggersIsValid) {
            this.props.saveGccpMetricRequest
                ? this.props.saveGccpMetricRequest(this.props.index)
                : this.updateGccpMetricRequest(GccpMetricRequestAttributes.EDITING, false);
        }
    }

    editGccpMetricRequest = () => {
        this.setState({ saveAttempted: false });
        this.updateGccpMetricRequest(GccpMetricRequestAttributes.EDITING, true);
    }

    startEndDateValid = () => {
        return this.state.gccpMetricRequest.startDate.length > 0 && this.state.gccpMetricRequest.endDate.length > 0;
    }

    updateCustomerTriggersType = async(_fieldId: string, payloadValue: string, _displayValue: string, _isValid: boolean) => {
        if (payloadValue === 'Use Your Own Customer Triggers') {
            this.setState({ useDefaultCustomerTriggersType: false });
        } else {
            this.setState({ useDefaultCustomerTriggersType: true });
            this.setState({ customerTriggersIsValid: _isValid });
        }
        this.state.gccpMetricRequest.useDefaultcustomerTriggers = this.state.useDefaultCustomerTriggersType;
    }

    /** Renders the JSX Content. */
    render = () =>
        <div className="awsui-util-container">
            <ColumnLayout columns={3}>
                <CustomerTriggersTypeField
                    data-testid='customer-triggers-type-dropdown'
                    displayMode={DisplayMode.CREATE}
                    updateFormState={this.updateCustomerTriggersType}
                />
                {!this.state.useDefaultCustomerTriggersType && 
                    <CustomerTriggersFile
                        disabled={!this.state.gccpMetricRequest.editing}
                        updateFormState={this.updateCustomerTriggersFile}
                        displayMode={DisplayMode.CREATE}
                        initialValue={this.props.gccpMetricRequest.customerTriggersFile}
                        initialFileName={'Upload Customer Triggers'}
                    />
                }
                <>
                    <GccpMetricRequestStartDate
                        data-testid='gccp-metric-start-date-input'
                        displayMode={DisplayMode.CREATE}
                        updateFormState={this.updateGccpMetricRequest}
                        startDate={this.state.experiment.metadata.startDate.payloadValue}
                        endDate={this.state.experiment.metadata.endDate.payloadValue}
                    />
                    <GccpMetricRequestEndDate
                        data-testid='gccp-metric-end-date-input'
                        displayMode={DisplayMode.CREATE}
                        updateFormState={this.updateGccpMetricRequest}
                        startDate={this.state.gccpMetricRequest.startDate}
                        endDate={this.state.experiment.metadata.endDate.payloadValue}
                    />
                </>
                <Checkbox
                    data-testid='use-reses-default-science-model'
                    checked={this.state.useResesDefaultScienceModel}
                    onChange={({ detail }) => {
                        this.setState({ useResesDefaultScienceModel: detail.checked });
                        this.updateGccpMetricRequest(GccpMetricRequestAttributes.SCIENCE_MODEL, detail.checked);
                    }}
                >
                    Use Synthetic Control to generate metrics
                </Checkbox>
                <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-evenly' }}>
                    <Button
                        data-testid='save-button'
                        iconName='file'
                        onClick={this.saveGccpMetricRequest}>Save</Button>
                    <Button
                        data-testid='delete-button'
                        iconName={'close'}
                        onClick={() => this.props.deleteGccpMetricRequest(this.props.index)}>Delete</Button>
                </div>
            </ColumnLayout>
        </div>
}
