import { InputField, InputFieldConfig } from '../fields/InputField';
import { ExperimentAttributeProps } from '../ExperimentAttribute';
import { DisplayMode } from '../../interfaces/FormAttribute';

/**
 * Props for {@link ExperimentComment} item.
 */
export interface ExperimentCommentProps extends ExperimentAttributeProps {
    setValue: any;
}

/**
 * Experiment comment as an input field.
 */
export class ExperimentComment extends InputField<ExperimentCommentProps> {
    protected displayConfig: InputFieldConfig;

    constructor(props: ExperimentCommentProps) {
        super(props);

        const { displayMode } = props;

        this.validationRules = { maxLength: 1024 };
        const { isValid, errorText } = this.validate(props.initialValue, this.validationRules);

        this.displayConfig = {
            editable: true,
            type: 'text',
            placeholder: 'Add a comment. 1024 characters maximum.',
            touched: false,
            errorText,
            onChange: (event) => {
                this.props.setValue(event.detail.value);
                this.setValueFromPayload(event.detail.value);
            },
            value: props.initialValue,
            autoFocus: true,
        };

        this.state = {
            displayValue: '',
            displayMode: displayMode ? displayMode : DisplayMode.CREATE,
            validity: isValid
        };
    }

    componentDidMount() {
        if (this.props.initialValue) {
            this.setValueFromPayload(this.props.initialValue);
        }
    }
}
