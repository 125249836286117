import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { PageSection } from '../../layout/PageSection';

const useStyles = makeStyles(({
    header: {
        margin: '5px 15px',
        padding: '10px 10px 0 10px'
    },
    container: {
        marginBottom: '30px'
    },
}));

interface RealmDisplaySectionProps {
    realm: string;
}

export const RealmDisplaySection: FunctionComponent<RealmDisplaySectionProps> = ({ realm }) => {
    const classes = useStyles();

    return (
        <PageSection testId={'realm-display-section'} className={classes.container}>
            <Typography variant='h6'>
                <div className={classes.header}>
                    Note: You are currently in the {realm.toUpperCase()} realm, use the top navigation bar to switch to a different realm
                </div>
            </Typography>
        </PageSection>
    );
};
