export enum Realm {
    NA = 'na',
    EU = 'eu',
    FE = 'fe'
}

export enum Portal {
    ADMIN = 'admin',
    BASIC = 'basic'
}

export enum Stage {
    BETA = 'beta',
    GAMMA = 'gamma',
    PROD = 'prod',
    DEV = 'dev'
}

export enum PreSignedUrlType {
    UPLOAD = 'upload',
    DOWNLOAD = 'download'
}

export enum HTTPMethod {
    POST = 'POST',
    PUT = 'PUT',
    GET = 'GET'
}

export const AwsRegionMap = new Map([
    [Stage.BETA.concat('-').concat(Realm.NA), 'us-west-2'],
    [Stage.BETA.concat('-').concat(Realm.EU), 'us-west-2'],
    [Stage.BETA.concat('-').concat(Realm.FE), 'us-west-2'],
    [Stage.GAMMA.concat('-').concat(Realm.NA), 'us-east-1'],
    [Stage.PROD.concat('-').concat(Realm.NA), 'us-east-1'],
    [Stage.PROD.concat('-').concat(Realm.EU), 'eu-west-1'],
    [Stage.PROD.concat('-').concat(Realm.FE), 'us-west-2'],
    [Stage.DEV.concat('-').concat(Realm.NA), 'us-east-1'],
    [Stage.DEV.concat('-').concat(Realm.FE), 'us-east-1'],
]);

export enum BaseEntityType {
    EXPERIMENT = 'EXPERIMENT',
    SELECTION = 'SELECTION',
    EXPERIMENT_USER_REQUEST = 'EXPERIMENT_USER_REQUEST'
}

export enum LifecycleType {
    BLOCK_LIST = 'BLOCK_LIST',
    BLOCK_LIST_BOUNDARIES_OVERRIDE = 'BLOCK_LIST_BOUNDARIES_OVERRIDE',
    BLOCK_LIST_REGIONALIZATION = 'BLOCK_LIST_REGIONALIZATION',
    REGIONALIZE_OFFERS = 'REGIONALIZE_OFFERS',
    UNREGIONALIZE_OFFERS = 'UNREGIONALIZE_OFFERS',
    OUTPUT_METRIC_ONLY = 'OUTPUT_METRIC_ONLY',
    RESULT_ANALYSIS_ONLY = 'RESULT_ANALYSIS_ONLY',
}

export const CDC_DATA_NOT_READY_CODE = 550;

export const CDC_AWS_ACCOUNTS = new Map([
    [Stage.BETA.concat('-').concat(Realm.NA), ['252898521588']],
    [Stage.BETA.concat('-').concat(Realm.EU), ['252898521588']],
    [Stage.BETA.concat('-').concat(Realm.FE), ['252898521588']],
    [Stage.GAMMA.concat('-').concat(Realm.NA), ['709886061330']],
    [Stage.PROD.concat('-').concat(Realm.NA), ['709886061330']],
    [Stage.PROD.concat('-').concat(Realm.EU), ['987595413191']],
    [Stage.PROD.concat('-').concat(Realm.FE), ['474813973746']],
    [Stage.DEV.concat('-').concat(Realm.NA), ['202004952930']],
    [Stage.DEV.concat('-').concat(Realm.FE), ['202004952930']],
]);
