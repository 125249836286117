import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-tagsinput/react-tagsinput.css';
import React, { FunctionComponent, useMemo, useState } from 'react';
import { Spinner } from '@amzn/awsui-components-react-v3';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import Amplify, { Auth, Hub, Cache } from 'aws-amplify';
import App from './layout/App';
import { ThemeProvider } from './layout/ThemeProvider';
import UnauthorizedPage from './pages/ErrorPages/UnauthorizedPage';
import { Realm } from './api/api-constants';
import { Portal } from './api/api-constants';
import initConfig from './config/init-config';
import { parseQueryParametersFromUrl } from './utils/url-utils';

Amplify.configure(initConfig());

const AppWrapper: FunctionComponent = () => {
    const initialRealm = parseQueryParametersFromUrl(new URL(window.location.href)).realm;
    const initialPortal = localStorage.getItem('portal');
    const [realm, setRealm] = useState(initialRealm ? (Realm as any)[initialRealm.toUpperCase()] : Realm.NA);
    const [portal, setPortal] = useState(initialPortal ? (Portal as any)[initialPortal.toUpperCase()] : Portal.BASIC);

    const toggleRealm = async(newRealm: Realm) => {
        setRealm(newRealm);
        const url = new URL(window.location.href);
        url.searchParams.set('realm', newRealm);
        window.location.replace(url.toString());
    };

    const togglePortal = async(newPortal: Portal) => {
        localStorage.setItem('portal', newPortal);
        setPortal(newPortal);
    };

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, updateUser] = useState('Unidentified');

    useMemo(() => {
        (async () => {
            try {
                const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();
                const username = currentAuthenticatedUser.getUsername().split('_')![1];
                updateUser(username);
                setIsAuthenticated(true);
                Hub.dispatch('auth', { event: 'signedIn' });
            } catch {
                Cache.setItem('location', window.location.toString());
                await Auth.federatedSignIn({ customProvider: 'AmazonFederate' });
            }
        })();
    }, []);

    let content;

    /**
     * Use Portal in UserContext to keep track of which App to show. By default, everybody is on Portal.BASIC
     * If someone clicks on the "Admin Portal" Button, then the portal state will change to Portal.ADMIN
     * If that user is not an admin, they will be redirected to an unauthorized page
     */
    if (user === 'Unidentified') {
        content = <Spinner />;
    } else if (isAuthenticated) {
        content = <div key='app'>
            <App username={user} portal={portal} togglePortal={togglePortal} realm={realm} toggleRealm={toggleRealm}/>
        </div>;
    } else {
        content = <UnauthorizedPage />;
    }

    return content;
};

ReactDOM.render(
    <BrowserRouter>
        <ThemeProvider>
            <AppWrapper />
        </ThemeProvider>
    </BrowserRouter>,
    document.getElementById('root')
);
