import { SelectProps } from '@amzn/awsui-components-react-v3';
import { MetadataAttribute } from '../../enums/CommonTypes';
import { DisplayMode } from '../../interfaces/FormAttribute';
import { ExperimentAttributeProps, ExperimentAttributeState } from '../ExperimentAttribute';
import { MultiSelectField, MultiSelectFieldConfig } from '../fields/MultiSelectField';
import { AttributeLabels } from '../../constants/display/string-constants';
import RMSApiHandler from '../../api/region-service/handler/rms-api-handler';
import { decryptCustomerId } from '@amzn/amazon-id';
import { INVERTED_MARKETPLACE_MAP } from '../../constants/experiment/marketplace-map';
import LemsApiHandlerImpl from '../../api/experiment-service/handler/lems-api-handler-impl';

export interface BoundariesProps extends ExperimentAttributeProps {
    definitionType: string;
    allowOverlappingBoundaries: boolean;
}

export class Boundaries extends MultiSelectField<BoundariesProps> {
    private regionServiceAPIHandler: RMSApiHandler;
    private experimentServiceAPIHandler: LemsApiHandlerImpl;
    protected displayConfig: MultiSelectFieldConfig;
    protected staticConfig: string;
    protected isLoading: boolean;

    constructor(props: BoundariesProps) {
        super(props);

        this.staticConfig = '';
        this.isLoading = true;

        this.validationRules = { required: true };
        const { isValid, errorText } = this.validate(this.props.initialValue, this.validationRules);

        this.displayConfig = {
            label: AttributeLabels.BOUNDARIES,
            editable: false,
            touched: false,
            placeholder: 'Please select from the drop-down menu',
            filteringType: 'auto',
            disabled: false,
            hintText: 'Select boundaries for manual override',
            errorText,
            onChange: (event) => this.onChangeEvent(event as CustomEvent, MetadataAttribute.BOUNDARIES),
            statusType: 'loading',
            selectedOptions: [],
        };

        this.state = {
            displayValue: '',
            displayMode: props.displayMode ? props.displayMode : DisplayMode.CREATE,
            validity: isValid
        };
        this.regionServiceAPIHandler = new RMSApiHandler(this.props.realm!);
        this.experimentServiceAPIHandler = new LemsApiHandlerImpl(this.props.realm!);
    }

    componentDidMount = async() => {
        await this.getBoundaryOptions();
        if (this.props.initialValue && this.props.initialValue.length !== 0) {
            await this.setValueFromPayload(this.props.initialValue);
        }
    }

    componentDidUpdate = async(prevProps: Readonly<BoundariesProps>, prevState: Readonly<ExperimentAttributeState>, snapshot?: any) => {
        if (this.props.definitionType !== prevProps.definitionType || this.props.allowOverlappingBoundaries !== prevProps.allowOverlappingBoundaries) {
            await this.getBoundaryOptions();
            await this.setValue([]);
        }
    }

    getBoundaryOptions = async() => {
        this.displayConfig.statusType = 'loading';
        const decryptedMarketplaceId = decryptCustomerId(this.props.marketplaceId!);
        const marketplace = INVERTED_MARKETPLACE_MAP[Number(decryptedMarketplaceId)];

        /* This code block is to get usable boundaries as displayed options for customers to choose. It:
        * 1. Calls RMS to get all boundaries in the experiment's region definition type(e.g., ZIP3). If overlap is allowed, skip remaining steps.
        * 2. Calls LEMS to get all treatment boundaries used in other active experiments.
        * 3. Calls RMS to convert these active treatment boundaries to the boundaries in experiment's region definition type(ZIP3).
        * 4. Removes the boundaries above from the boundaries retrieved in the 1st call.
        */
        // Step 1
        let eligibleBoundaries = await this.regionServiceAPIHandler.getBoundariesInDefinitionType(marketplace, this.props.definitionType);
        if (!this.props.allowOverlappingBoundaries) {
            // Step 2
            const treatmentBoundariesInUse = await this.experimentServiceAPIHandler.getActiveTreatmentBoundaries(this.props.marketplaceId!, this.props.startDate!, this.props.endDate!);
            const activeTreatmentBoundaries = treatmentBoundariesInUse.map((experimentBoundary) => {
                return {
                    boundaryName: experimentBoundary.boundary.boundaryId!,
                    definitionType: experimentBoundary.boundaryDefinitionType
                };
            });
            // Step 3
            const activeBoundariesInGivenDefinition = await this.regionServiceAPIHandler.getOverlappingBoundariesInDefinitionType(marketplace, this.props.definitionType, activeTreatmentBoundaries);
            // Step 4
            eligibleBoundaries = eligibleBoundaries.filter((e) => !activeBoundariesInGivenDefinition.includes(e.boundaryName));
        }
        let allItems: SelectProps.Option[] = [];
        eligibleBoundaries.forEach((boundary) => {
            const boundaryDisplay: string = `${boundary.boundaryName}`;
            allItems.push({
                label: boundaryDisplay,
                value: boundary.boundaryName!,
            });
        });
        this.displayConfig.options = allItems;
        this.displayConfig.statusType = 'finished';
        this.forceUpdate();
    }

    getPayloadValue = (): string[] => {
        const boundaries: string[] = [];
        this.getSelectedOptions().forEach((selectedOption) => {
            boundaries.push(selectedOption.value!);
        });

        return boundaries;
    };

    setValueFromPayload = async(boundaries: string[]) => {
        const selectedOptions: SelectProps.Option[] = [];
        boundaries.forEach((boundary) => {
            selectedOptions.push({
                value: boundary,
                label: boundary
            });
        });

        await this.setValue(selectedOptions);
    }
}
