import { EndpointMap } from '../endpoint-map';

export const LEMS_API_ENDPOINTS = new EndpointMap({
    beta_na: 'https://rhon0ktg88.execute-api.us-west-2.amazonaws.com/beta-us-west-2',
    gamma_na: 'https://p57hahk7nc.execute-api.us-east-1.amazonaws.com/gamma-us-east-1',
    prod_na: 'https://kwqmn0hu78.execute-api.us-east-1.amazonaws.com/prod-us-east-1',
    beta_eu: 'https://znaomgb0ff.execute-api.eu-west-1.amazonaws.com/beta-eu-west-1',
    prod_eu: 'https://o3rd8ig799.execute-api.eu-west-1.amazonaws.com/prod-eu-west-1',
    prod_fe: 'https://zeg7nzk3hh.execute-api.us-west-2.amazonaws.com/prod-us-west-2',
    dev_na: 'https://mgan7nsfk3.execute-api.us-east-1.amazonaws.com/dev-us-east-1',
    dev_eu: 'https://mgan7nsfk3.execute-api.us-east-1.amazonaws.com/dev-us-east-1',
    dev_fe: 'https://mgan7nsfk3.execute-api.us-east-1.amazonaws.com/dev-us-east-1',
});

export const LemsApiPaths = {
    EXPERIMENT: '/experiment',
    EXPERIMENTS: '/experiments',
    UPLOAD_ARTIFACT: '/experiment/uploadLocation',
    DOWNLOAD_ARTIFACT: '/experiment/downloadLocation',
    GET_EXPERIMENTS_BY_STATUS: '/experiments/status',
    GET_EXPERIMENTS_IN_GIVEN_STATUSES: '/experiments/statuses',
    GET_EXPERIMENT_BY_ID: '/experiment',
    GET_STATIC_CONFIGURATION_DATA: '/staticconfig',
    UPDATE_STATIC_CONFIGURATION_DATA: '/staticconfig',
    EXPERIMENT_USER_REQUEST: '/experiment-user-request',
    LIFECYCLE: '/lifecycle',
    BOUNDARIES: '/boundaries',
    UNAVAILABLE_RUN_DATES: '/unavailableRunDates'
};

export const PROD_LIMESTONE_PORTAL_URL = 'limestone.strx.amazon.dev';
