import { MetadataAttribute } from '../../enums/CommonTypes';
import { ExperimentAttributeProps } from '../ExperimentAttribute';
import { TagField, TagFieldConfig } from '../fields/TagField';
import * as LambdaModel from '../../api/experiment-service/lambda-model-types';
import { TokenGroupProps } from '@amzn/awsui-components-react-v3';
import { DisplayMode } from '../../interfaces/FormAttribute';
import { LemsApiHandler } from '../../api/experiment-service/handler/lems-api-handler';
import ApiHandler from '../../api/experiment-service/handler/lems-api-handler-impl';
import { Realm } from '../../api/api-constants';
import { AttributeLabels } from '../../constants/display/string-constants';

export class SecondaryOwners extends TagField {
    protected displayConfig: TagFieldConfig;
    private experimentServiceAPI: LemsApiHandler;

    constructor(props: ExperimentAttributeProps) {
        super(props);

        const realm = props.realm ? props.realm : Realm.NA;
        this.experimentServiceAPI = new ApiHandler(realm);

        this.displayConfig = {
            label: AttributeLabels.SECONDARY_OWNERS,
            editable: false,
            value: [],
            inputProps: {
                className: 'react-tagsinput-input',
                placeholder: 'Ex: jeff',
                'data-testid': this.props['data-testid']
            },
            hintText: 'Press Tab or Enter to add multiple owners',
            touched: false,
            onChange: (event: any) => this.onChangeEvent(event, MetadataAttribute.SECONDARY_OWNERS),
        };

        this.addItemFieldConfig = {
            editable: false,
            type: 'text',
            placeholder: 'Add Owner',
            touched: false,
            onChange: (event) => this.onAddItemChangeEvent(event as CustomEvent),
            onKeyDown: (event) => this.onAddToken(event),
            value: props.initialValue,
        };

        this.state = {
            displayValue: '',
            displayMode: props.displayMode ? props.displayMode : DisplayMode.CREATE,
            validity: true,
            displayTokens: []
        };
    }

    componentDidMount() {
        if (this.props.initialValue) {
            this.setValueFromPayload(this.props.initialValue);
            const displayTokens: TokenGroupProps.Item[] = this.props.initialValue.map((ownerDto: LambdaModel.OwnerDto) => {
                return {
                    label: ownerDto.alias,
                    dismissLabel: `Remove ${ownerDto.alias}`
                };
            });

            this.setState({ displayTokens });
        }
    }

    getPayloadValue = (): LambdaModel.OwnerDto[] => {
        const secondaryOwners: LambdaModel.OwnerDto[] = [];
        this.getTags().forEach((owner) => {
            if (owner.trim()) {
                secondaryOwners.push({
                    alias: owner.trim(),
                    primary: false,
                });
            }
        });

        return secondaryOwners;
    };

    setValueFromPayload = async(secondaryOwners: LambdaModel.OwnerDto[]) => {
        const selectedTags: string[] = [];
        secondaryOwners.forEach((ownerDto) => {
            selectedTags.push(ownerDto.alias);
        });

        this.setValue(selectedTags);
    }

    addItemToBackend = async(newValue: string): Promise<boolean> => {
        return await this.experimentServiceAPI.addSecondaryOwner(this.props.experimentId!, {
            alias: newValue,
            primary: false
        });
    }

    removeItemFromBackend = async(value: string) => {
        return await this.experimentServiceAPI.removeSecondaryOwner(this.props.experimentId!, {
            alias: value,
            primary: false
        });
    }
}
